.govuk-fieldset__legend {
  overflow: visible;

  &.govuk-fieldset__legend--xl {
    @include govuk-responsive-margin(8, 'bottom');
  }

  &.govuk-fieldset__legend--l {
    @include govuk-responsive-margin(6, 'bottom');
  }
}
