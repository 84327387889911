.govuk-progress {
  &[value] {
    appearance: none;

    border: none;
    height: 30px;


    &::-webkit-progress-bar {
      background: transparent;
      border: 2px solid govuk-colour("dark-grey");
    }

    /* IE10 */
    color: govuk-colour("green");

    &::-webkit-progress-value,
    &::-moz-progress-bar {
      background: govuk-colour("green");
    }
  }
}
