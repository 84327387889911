.js-enabled .govuk-tabs__panel:not(.has-border) {
  padding-right: 0;
  padding-left: 0;
  border: 0;
}

.govuk-tabs__list-item .govuk-tabs__tab--generic-alert {
  background-repeat: no-repeat;
  background-position: calc(100%);
  background-size: 22px;
  padding-left: 30px;
  padding-top: 10px;
}

.govuk-standalone-tabs__list-item {
  @extend .govuk-tabs__list-item;
  padding: 12px 31px !important;
}

.govuk-subsidiary-tabs__list-item {
  @extend .govuk-tabs__list-item;
  padding: 12px 13px !important;
}

.govuk-tabs__tab--alert {
  @extend .govuk-tabs__tab--generic-alert;
  background-image: url('/assets/images/flag-orange.svg');
}

.govuk-tabs__tab--red-alert {
  @extend .govuk-tabs__tab--generic-alert;
  background-image: url('/assets/images/flag-red.svg');
}

.govuk-tabs__tab--red-cross {
  @extend .govuk-tabs__tab--generic-alert;
  background-image: url('/assets/images/cross-icon.svg');
}

.govuk-tabs__tab--green-tick {
  @extend .govuk-tabs__tab--generic-alert;
  background-image: url('/assets/images/tick-icon.svg');
}
