.asc-summarylist-border-top {
  border-top: 1px solid $govuk-border-colour;
}
.asc-summarylist-border-bottom {
  border-bottom: 1px solid $govuk-border-colour;
}

//NOTE: Non-offical govuk css:

.govuk-summary-list--wrap-border {
  border: 1px solid $govuk-border-colour;
  border-width: 1px 0;
}
.govuk-summary-list--bottom-border {
  .govuk-summary-list__key,
  .govuk-summary-list__value,
  .govuk-summary-list__actions,
  .govuk-summary-list__row {
    border: 1px solid $govuk-border-colour;
    border-width: 0 0 1px 0;
  }
}
.govuk-summary-list--top-border {
  .govuk-summary-list__key,
  .govuk-summary-list__value,
  .govuk-summary-list__actions {
    vertical-align: top;
  }

  .govuk-summary-list__row:first-child {
    .govuk-summary-list__key,
    .govuk-summary-list__value,
    .govuk-summary-list__actions,
    .govuk-summary-list__row {
      border-top: 1px solid $govuk-border-colour;
    }
  }
}

.govuk-summary-list--no-top-border {
  .govuk-summary-list__key,
  .govuk-summary-list__value,
  .govuk-summary-list__actions {
    vertical-align: top;
  }
}

.govuk-summary-list__row.govuk-summary-list__row--no-bottom-border {
  .govuk-summary-list__key,
  .govuk-summary-list__value,
  .govuk-summary-list__actions {
    border-bottom: none;
  }
  border-bottom: none;
}

.govuk-summary-list__row--no-bottom-padding {
  .govuk-summary-list__key,
  .govuk-summary-list__value,
  .govuk-summary-list__actions {
    padding-bottom: 0;
  }
}

.govuk-summary-list__row--error-warning-message {
  .govuk-summary-list__key,
  .govuk-summary-list__value,
  .govuk-summary-list__actions {
    padding-top: 5px;
  }
}

.govuk-summary-list__value--confirm {
  font-weight: bold;
}

.govuk-summary-list--medium {
  .govuk-summary-list__key {
    width: 45%;
  }
  .govuk-summary-list__value {
    width: 55%;
  }
}

.govuk-summary-list--wide {
  .govuk-summary-list__key {
    width: 70%;
  }
  .govuk-summary-list__value {
    width: 30%;
  }
}

.asc-summary-list--wide-value {
  .govuk-summary-list__key {
    width: 35%;
  }

  .govuk-summary-list__value {
    width: 55%;
    padding-left: 15px;
  }

  .govuk-summary-list__actions {
    width: 10%;
  }
}

.asc-password-wrap {
  display: inline-block;
  max-width: 40%;
}

.govuk-summary-list__warning {
  padding-left: 15px;
  border-left: 10px solid #f47738;

  &-message {
    color: #f47738;
  }
}

.govuk-summary-list__error {
  padding-left: 15px;
  border-left: 10px solid #d4351c;
}

.govuk-summary-list--contains-warnings {
  .govuk-summary-list__key {
    width: 35%;
    vertical-align: top;
  }
  .govuk-summary-list__value {
    width: 40%;
    vertical-align: top;
  }
  .govuk-summary-list__actions {
    width: 25%;
    vertical-align: top;
  }

  .govuk-summary-list__row--warning {
    dt,
    dd {
      background: rgba(govuk-colour('orange'), 0.12);
    }
    @include govuk-media-query($from: desktop) {
      .govuk-summary-list__value {
        white-space: nowrap;
        p {
          left: -75%;
          width: 100%;
          position: relative;
        }
      }
    }
    .govuk-summary-list__actions {
      padding-right: govuk-spacing(2);
    }
  }
}

.govuk-summary-list--wide-key {
  .govuk-summary-list__key {
    width: 45%;
  }

  .govuk-summary-list__value {
    width: 45%;
    padding-left: 15px;
  }

  .govuk-summary-list__actions {
    width: 10%;
  }
}

.asc-no-border {
  border: none;
}

.asc-warning-banner {
  background-color: rgba(govuk-colour('orange'), 0.12);

  @include govuk-media-query($from: desktop) {
    .govuk-summary-list__value {
      white-space: nowrap;
      p {
        left: -85%;
        width: 100%;
        position: relative;
      }
    }
  }
  .govuk-summary-list__actions {
    padding-right: govuk-spacing(2);
  }

  /* &::before, */
  &::after {
    content: '\0a0';
    line-height: 3em;
  }

  .govuk-summary-list__key {
    border-left: 10px solid govuk-colour('orange');
  }

  .govuk-summary-list__key,
  .govuk-summary-list__value,
  .govuk-summary-list__actions {
    padding-top: 15px;
    padding-bottom: 15px;
    vertical-align: middle;
    background: none;
    border-bottom: none;
  }
}

.spacer {
  height: 15px;
  div {
    border-bottom: 1px solid govuk-colour('mid-grey');
    height: 100%;
    display: table-cell;
  }
}
