@use 'sass:math';

$error_row_indent: 8px;
$error_row_padding: $govuk-gutter-half;

.govuk-table__row {
  .govuk-table__cell:first-of-type {
    padding-left: 0;
  }

  .govuk-table__cell:last-of-type {
    padding-right: math.div($error-row-padding, 2);
  }

  .asc-table__cell-no-border {
    &__top-row {
      border: none;
      padding-bottom: 0;
    }

    &__middle-row {
      border: none;
      padding: 0;
    }

    &__bottom-row {
      padding-top: 0;
    }
  }
}

.govuk-table__with-action {
  .govuk-table__row td:last-child,
  .govuk-table__row th:last-child {
    width: 1%;
    white-space: nowrap;
  }
}

.govuk-error-table__row {
  border: solid $govuk-error-colour;

  &--no-border {
    border: none;
  }
}

.govuk-error-table__row--highlight {
  background: rgba(govuk-colour('light-blue'), 0.2);
}

.govuk-error-table__cell {
  position: relative;
  padding-top: $error_row_padding;
  padding-bottom: $error_row_padding;

  &:first-of-type:before,
  &:first-of-type:after,
  &:last-of-type:before {
    content: '';
    position: absolute;
    height: 100%;
    padding-bottom: $error_row_indent;
  }

  &:first-of-type {
    &:before {
      width: $error_row_indent;
      background: #fff;
      left: 0;
    }
    &:after {
      background: $govuk-error-colour;
      left: $error_row_indent;
    }
  }
}

.govuk-error-table__message-cell {
  padding-top: $error_row_padding * 1.5;
  padding-bottom: 0;
}

.govuk-error-table__message {
  margin-bottom: 0;
  padding-bottom: $error_row_padding;
  padding-left: $error_row_indent;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    background: $govuk-error-colour;
    height: 100%;
  }
}
.govuk-table-custom-width {
  width: 20% !important;
}

.asc-training-and-quals-table {
  td.govuk-table__cell:has(button) {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}
