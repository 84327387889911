.govuk-inset-text {
  &.todo {
    border-color: govuk-colour('orange');
    background-color: rgba(govuk-colour('orange'), 0.12);
  }

  &.success {
    border-color: govuk-colour('green');
    background-color: rgba(govuk-colour('green'), 0.25);
  }

  &.warning {
    border-color: govuk-colour('orange');
    background-color: rgba(govuk-colour('orange'), 0.12);
  }

  &.pending {
    border-color: govuk-colour('blue');
    background-color: rgba(govuk-colour('blue'), 0.15);
  }

  &.thin-border {
    border-width: 5px;
    padding-left: 20px;
  }
}
