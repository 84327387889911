/* autoprefixer grid: on */

$govuk-global-styles: true;
$govuk-font-family: 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;

@import 'govuk-frontend/govuk/all';

body {
  font-family: $govuk-font-family;
}

html {
  overflow-x: scroll;
}

@import 'modules/overlay';
@import 'modules/sticky-footer';
@import 'modules/utils';
@import 'modules/responsive';

// Custom Partials
@import 'partials/lists';
@import 'partials/typography';
@import 'partials/links';
@import 'partials/radios';

// Custom Components
@import 'components/accordions';
@import 'components/auto-suggest';
@import 'components/breadcrumbs';
@import 'components/buttons';
@import 'components/checkboxes';
@import 'components/doh-logo';
@import 'components/fieldset';
@import 'components/footer';
@import 'components/form-group';
@import 'components/grid';
@import 'components/header';
@import 'components/input';
@import 'components/inset-text';
@import 'components/panel';
@import 'components/progress';
@import 'components/select';
@import 'components/summary-list';
@import 'components/tables';
@import 'components/tabs';
@import 'components/tag';
@import 'components/notifications';
@import 'components/drag-and-drop';

// CMS
@import 'cms/cms';
