.govuk-list--inline {
  > li {
    display: inline-block;

    margin-right: govuk-spacing(4);

    &:last-child {
      margin-right: 0;
    }
  }
}

.govuk-list--tab {
  display: flex;
  background-color: govuk-colour('light-grey');
  margin: 0;
  @include govuk-responsive-margin(4, 'bottom');
  @include govuk-responsive-padding(3);

  &__column {
    flex: 1;
    display: flex;
    align-items: center;
    @include govuk-responsive-padding(1);
    @include govuk-responsive-padding(3, 'right');

    dt {
      margin: 0;
      display: flex;
      align-items: center;
      margin-right: 5px;
      @include govuk-font(19, 'bold');
    }

    dd {
      margin: 0;
      display: flex;
      @include govuk-font(19);
    }

    dd:nth-of-type(2) {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  }

  @for $i from 1 through 3 {
    div:nth-of-type(#{$i}) {
      @if $i > 1 {
        border-top: 1px solid $govuk-border-colour;
      }

      @include govuk-media-query($from: tablet) {
        border-top: 0;
        @if $i > 1 {
          border-left: 1px solid $govuk-border-colour;
          @include govuk-responsive-padding(3, 'left');
        }
      }
    }
  }
}

.govuk-list--definition {
  display: grid;

  margin: 0;
  @include govuk-responsive-margin(4, 'bottom');

  grid-template-columns: 1fr;
  @include govuk-media-query($from: tablet) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  dt {
    @include govuk-responsive-padding(3, 'left');
    @include govuk-responsive-padding(3, 'right');
    @include govuk-responsive-padding(3, 'bottom');
    margin: 0;
    position: relative;

    @include govuk-font(19);
    background-color: govuk-colour('light-grey');
  }

  dd {
    @include govuk-responsive-padding(3, 'top');
    @include govuk-responsive-padding(3, 'left');
    @include govuk-responsive-padding(3, 'right');
    margin: 0;
    position: relative;

    @include govuk-font(24, 'bold');
    background-color: govuk-colour('light-grey');
  }

  &.govuk-list--definition-xl {
    dt {
      @include govuk-font(24, 'bold');
    }
    dd {
      @include govuk-font(80, 'bold');
    }
  }

  @for $i from 1 through 3 {
    dt:nth-of-type(#{$i}) {
      grid-row-start: 2 + (2 * ($i - 1));
      grid-row-end: 3 + (2 * ($i - 1));
      grid-column-start: 1;
      grid-column-end: 2;

      @include govuk-media-query($from: tablet) {
        grid-row-start: 2;
        grid-row-end: 3;
        grid-column-start: $i;
        grid-column-end: $i + 1;

        @if $i > 1 {
          &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 15px;

            border-left: 1px solid govuk-colour('dark-grey');
          }
        }
      }
    }

    dd:nth-of-type(#{$i}) {
      grid-row-start: 1 + (2 * ($i - 1));
      grid-row-end: 2 + (2 * ($i - 1));
      grid-column-start: 1;
      grid-column-end: 2;
      @if $i > 1 {
        border-top: 1px solid govuk-colour('dark-grey');
      }

      @include govuk-media-query($from: tablet) {
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: $i;
        grid-column-end: $i + 1;
        border-top: 0;

        @if $i > 1 {
          &:before {
            content: '';
            display: block;
            position: absolute;
            top: 15px;
            left: 0;
            bottom: 0;
            border-left: 1px solid govuk-colour('dark-grey');
          }
        }
      }
    }
  }
}

.govuk-list--definition-heading-grid {
  @include govuk-media-query($from: tablet) {
    display: table;
    width: 100%;
    margin: 0;
    @include govuk-responsive-margin(4, 'bottom');

    .govuk-list--definition-heading-grid__row {
      display: table-row;
    }

    .govuk-list--definition-heading-grid__col {
      display: table-cell;
    }
  }

  dt {
    @include govuk-responsive-padding(3);
    margin: 0;
    position: relative;
    @include govuk-font(19, $weight: bold);
    background-color: govuk-colour('light-grey');
  }

  dd {
    @include govuk-responsive-padding(3, 'bottom');
    @include govuk-responsive-padding(3, 'left');
    @include govuk-responsive-padding(3, 'right');
    margin: 0;
    position: relative;
    background-color: govuk-colour('light-grey');
  }
}

.govuk-list--download {
  > li {
    min-height: 26px;
    padding-left: 35px;
    @include govuk-responsive-margin(3, 'bottom');

    background: url('/assets/images/icon-download.svg') no-repeat 0 0;
  }
}
