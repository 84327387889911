.govuk-auto-suggest {
  position: relative;
  ul {
    border: 2px solid $govuk-input-border-colour;
    margin: 0;
    border-top: 0;
    padding: 0;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;
    background: govuk-colour('white');
    max-height: 232px;
    overflow-y: auto;
    li {
      list-style-type: none;
      padding: 8px;
      border-bottom: 1px solid $govuk-input-border-colour;
      cursor: pointer;

      &:last-child {
        border: 0;
      }
    }
  }
}
.govuk-auto-suggest > ul::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  background-color: govuk-colour('light-grey');
}

.govuk-auto-suggest > ul::-webkit-scrollbar {
  width: 9px;
  background-color: govuk-colour('mid-grey');
}

.govuk-auto-suggest > ul::-webkit-scrollbar-thumb {
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: govuk-colour('dark-grey');
}
