@include govuk-media-query($from: tablet) {
  .govuk__grid {
    display: grid;
    column-gap: $govuk-gutter-half;
  }

  .govuk__grid-container {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto;
    border-bottom: solid 1px govuk-colour('mid-grey');
    margin-bottom: 0;
    padding-top: govuk-spacing(2);
    padding-bottom: govuk-spacing(2);
    align-items: center;
  }

  .asc__references-grid-container {
    grid-template-columns: 1fr 1fr 0.2fr;
    grid-template-rows: auto auto;
    border-bottom: solid 1px govuk-colour('mid-grey');
    margin-bottom: 0;
    padding-top: govuk-spacing(2);
    padding-bottom: govuk-spacing(2);
    align-items: center;
  }

  .govuk__grid-row-start-1 {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 4;
  }

  .govuk__align-items-center {
    align-items: center;
  }

  .govuk__align-items-end {
    align-items: end;
  }

  .govuk__justify-self-end {
    justify-self: end;
  }

  .govuk__grid-column-1,
  .govuk__grid-column-2,
  .govuk__grid-column-3 {
    grid-row-start: 1;
    grid-row-end: 2;
  }

  .govuk__grid-column-1 {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .govuk__grid-column-2 {
    grid-column-start: 2;
    grid-column-end: 3;
  }

  .govuk__grid-column-3 {
    grid-column-start: 3;
    grid-column-end: 4;
  }

  .govuk-form-group--error {
    .govuk__grid-column-1 {
      grid-row-start: 2;
      grid-row-end: 3;
    }

    .govuk__grid-column-2 {
      grid-row-start: 2;
      grid-row-end: 3;
    }

    .govuk__grid-column-3 {
      grid-row-start: 2;
      grid-row-end: 3;
    }
  }
}

.asc-width-container-admin-section {
  @extend .govuk-width-container;
  max-width: 1500px;
  padding: 0 15px;
}
