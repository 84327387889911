.govuk-panel--interruption {
  background-color: govuk-colour('blue');
  text-align: left;

  * {
    color: govuk-colour('white');
  }

  a,
  a:link,
  a:visited {
    color: govuk-colour('white');
  }
  a:hover {
    color: govuk-colour('light-grey');
  }

  .govuk-list--definition {
    dt,
    dd {
      color: govuk-colour('blue');
    }
  }

  .govuk-button:not(.govuk-button--link) {
    background-color: govuk-colour('white');
    box-shadow: 0 2px 0 govuk-colour('black');

    &:link,
    &:visited {
      color: govuk-colour('blue');
    }

    &:hover,
    &:focus {
      background-color: govuk-colour('light-grey');
    }
  }
}

.govuk-panel--light-blue {
  background-color: rgba(govuk-colour('light-blue'), 0.2);
}
.govuk-panel--warning-prompt {
  @include govuk-font($size: 24, $weight: bold);
  padding: govuk-spacing(3);
  background-color: $govuk-error-colour;
  color: #fff;
}
.govuk-panel--gray {
  background-color: govuk-colour('light-grey');
}

.govuk-panel--with-icon {
  background-color: govuk-colour('light-grey');
  text-align: left;
  display: flex;
  align-items: baseline;

  padding-left: 18px;
  column-gap: 5px;
  margin-bottom: govuk-spacing(6);

  > img {
    height: 20px;
    width: 20px;

    @media only screen and (min-width: 641px) {
      height: 28px;
      width: 28px;
    }
  }

  > h1 {
    margin-bottom: 0;
  }
}
