@charset "UTF-8";
/* autoprefixer grid: on */































 








 












:root {
  --govuk-frontend-version: "4.8.0";
}


a, .govuk-link {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: underline;
}
@media print {
  a, .govuk-link {
    font-family: sans-serif;
  }
}
a:focus, .govuk-link:focus {
  outline: 3px solid transparent;
  color: #0b0c0c;
  background-color: #ffdd00;
  box-shadow: 0 -2px #ffdd00, 0 4px #0b0c0c;
  text-decoration: none;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}
a:link, .govuk-link:link {
  color: #1d70b8;
}
a:visited, .govuk-link:visited {
  color: #4c2c92;
}
a:hover, .govuk-link:hover {
  color: #003078;
}
a:active, .govuk-link:active {
  color: #0b0c0c;
}
a:focus, .govuk-link:focus {
  color: #0b0c0c;
}
@media print {
  a[href^="/"]:after, [href^="/"].govuk-link:after, a[href^="http://"]:after, [href^="http://"].govuk-link:after, a[href^="https://"]:after, [href^="https://"].govuk-link:after {
    content: " (" attr(href) ")";
    font-size: 90%;
    word-wrap: break-word;
  }
}

.govuk-link--muted:link, .govuk-link--muted:visited {
  color: #505a5f;
}
.govuk-link--muted:hover, .govuk-link--muted:active {
  color: #0b0c0c;
}
.govuk-link--muted:focus {
  color: #0b0c0c;
}

.govuk-link--text-colour:link, .govuk-link--text-colour:visited {
  color: #0b0c0c;
}
@media print {
  .govuk-link--text-colour:link, .govuk-link--text-colour:visited {
    color: #000000;
  }
}
.govuk-link--text-colour:hover {
  color: rgba(11, 12, 12, 0.99);
}
.govuk-link--text-colour:active, .govuk-link--text-colour:focus {
  color: #0b0c0c;
}
@media print {
  .govuk-link--text-colour:active, .govuk-link--text-colour:focus {
    color: #000000;
  }
}

.govuk-link--inverse:link, .govuk-link--inverse:visited {
  color: #ffffff;
}
.govuk-link--inverse:hover, .govuk-link--inverse:active {
  color: rgba(255, 255, 255, 0.99);
}
.govuk-link--inverse:focus {
  color: #0b0c0c;
}

.govuk-link--no-underline:not(:hover):not(:active) {
  text-decoration: none;
}

.govuk-link--no-visited-state:link {
  color: #1d70b8;
}
.govuk-link--no-visited-state:visited {
  color: #1d70b8;
}
.govuk-link--no-visited-state:hover {
  color: #003078;
}
.govuk-link--no-visited-state:active {
  color: #0b0c0c;
}
.govuk-link--no-visited-state:focus {
  color: #0b0c0c;
}


.govuk-list, .asc-cms-content ol, .asc-cms-content ul {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.25;
  color: #0b0c0c;
  margin-top: 0;
  margin-bottom: 15px;
  padding-left: 0;
  list-style-type: none;
}
@media print {
  .govuk-list, .asc-cms-content ol, .asc-cms-content ul {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-list, .asc-cms-content ol, .asc-cms-content ul {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.3157894737;
  }
}
@media print {
  .govuk-list, .asc-cms-content ol, .asc-cms-content ul {
    font-size: 14pt;
    line-height: 1.15;
  }
}
@media print {
  .govuk-list, .asc-cms-content ol, .asc-cms-content ul {
    color: #000000;
  }
}
@media (min-width: 40.0625em) {
  .govuk-list, .asc-cms-content ol, .asc-cms-content ul {
    margin-bottom: 20px;
  }
}
.govuk-list .govuk-list, .asc-cms-content ol .govuk-list, .govuk-list .asc-cms-content ol, .asc-cms-content .govuk-list ol, .asc-cms-content ol ol, .asc-cms-content ul .govuk-list, .asc-cms-content ul ol, .govuk-list .asc-cms-content ul, .asc-cms-content .govuk-list ul, .asc-cms-content ol ul, .asc-cms-content ul ul {
  margin-top: 10px;
}

.govuk-list > li, .asc-cms-content ol > li, .asc-cms-content ul > li {
  margin-bottom: 5px;
}

.govuk-list--bullet, .asc-cms-content ul {
  padding-left: 20px;
  list-style-type: disc;
}

.govuk-list--number, .asc-cms-content ol {
  padding-left: 20px;
  list-style-type: decimal;
}

.govuk-list--bullet > li, .asc-cms-content ul > li,
.govuk-list--number > li,
.asc-cms-content ol > li {
  margin-bottom: 0;
}
@media (min-width: 40.0625em) {
  .govuk-list--bullet > li, .asc-cms-content ul > li,
  .govuk-list--number > li,
  .asc-cms-content ol > li {
    margin-bottom: 5px;
  }
}

.govuk-list--spaced > li {
  margin-bottom: 10px;
}
@media (min-width: 40.0625em) {
  .govuk-list--spaced > li {
    margin-bottom: 15px;
  }
}


.govuk-heading-xl {
  color: #0b0c0c;
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  font-size: 32px;
  font-size: 2rem;
  line-height: 1.09375;
  display: block;
  margin-top: 0;
  margin-bottom: 30px;
}
@media print {
  .govuk-heading-xl {
    color: #000000;
  }
}
@media print {
  .govuk-heading-xl {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-heading-xl {
    font-size: 48px;
    font-size: 3rem;
    line-height: 1.0416666667;
  }
}
@media print {
  .govuk-heading-xl {
    font-size: 32pt;
    line-height: 1.15;
  }
}
@media (min-width: 40.0625em) {
  .govuk-heading-xl {
    margin-bottom: 50px;
  }
}

.govuk-heading-l {
  color: #0b0c0c;
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.0416666667;
  display: block;
  margin-top: 0;
  margin-bottom: 20px;
}
@media print {
  .govuk-heading-l {
    color: #000000;
  }
}
@media print {
  .govuk-heading-l {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-heading-l {
    font-size: 36px;
    font-size: 2.25rem;
    line-height: 1.1111111111;
  }
}
@media print {
  .govuk-heading-l {
    font-size: 24pt;
    line-height: 1.05;
  }
}
@media (min-width: 40.0625em) {
  .govuk-heading-l {
    margin-bottom: 30px;
  }
}

.govuk-heading-m {
  color: #0b0c0c;
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.1111111111;
  display: block;
  margin-top: 0;
  margin-bottom: 15px;
}
@media print {
  .govuk-heading-m {
    color: #000000;
  }
}
@media print {
  .govuk-heading-m {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-heading-m {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.25;
  }
}
@media print {
  .govuk-heading-m {
    font-size: 18pt;
    line-height: 1.15;
  }
}
@media (min-width: 40.0625em) {
  .govuk-heading-m {
    margin-bottom: 20px;
  }
}

.govuk-heading-s {
  color: #0b0c0c;
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.25;
  display: block;
  margin-top: 0;
  margin-bottom: 15px;
}
@media print {
  .govuk-heading-s {
    color: #000000;
  }
}
@media print {
  .govuk-heading-s {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-heading-s {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.3157894737;
  }
}
@media print {
  .govuk-heading-s {
    font-size: 14pt;
    line-height: 1.15;
  }
}
@media (min-width: 40.0625em) {
  .govuk-heading-s {
    margin-bottom: 20px;
  }
}

.govuk-caption-xl {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.1111111111;
  display: block;
  margin-bottom: 5px;
  color: #505a5f;
}
@media print {
  .govuk-caption-xl {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-caption-xl {
    font-size: 27px;
    font-size: 1.6875rem;
    line-height: 1.1111111111;
  }
}
@media print {
  .govuk-caption-xl {
    font-size: 18pt;
    line-height: 1.15;
  }
}

.govuk-caption-l {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.1111111111;
  display: block;
  margin-bottom: 5px;
  color: #505a5f;
}
@media print {
  .govuk-caption-l {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-caption-l {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.25;
  }
}
@media print {
  .govuk-caption-l {
    font-size: 18pt;
    line-height: 1.15;
  }
}
@media (min-width: 40.0625em) {
  .govuk-caption-l {
    margin-bottom: 0;
  }
}

.govuk-caption-m {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.25;
  display: block;
  color: #505a5f;
}
@media print {
  .govuk-caption-m {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-caption-m {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.3157894737;
  }
}
@media print {
  .govuk-caption-m {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.govuk-body-lead, .govuk-body-l {
  color: #0b0c0c;
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.1111111111;
  margin-top: 0;
  margin-bottom: 20px;
}
@media print {
  .govuk-body-lead, .govuk-body-l {
    color: #000000;
  }
}
@media print {
  .govuk-body-lead, .govuk-body-l {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-body-lead, .govuk-body-l {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.25;
  }
}
@media print {
  .govuk-body-lead, .govuk-body-l {
    font-size: 18pt;
    line-height: 1.15;
  }
}
@media (min-width: 40.0625em) {
  .govuk-body-lead, .govuk-body-l {
    margin-bottom: 30px;
  }
}

p, .govuk-body, .govuk-body-m {
  color: #0b0c0c;
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.25;
  margin-top: 0;
  margin-bottom: 15px;
}
@media print {
  p, .govuk-body, .govuk-body-m {
    color: #000000;
  }
}
@media print {
  p, .govuk-body, .govuk-body-m {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  p, .govuk-body, .govuk-body-m {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.3157894737;
  }
}
@media print {
  p, .govuk-body, .govuk-body-m {
    font-size: 14pt;
    line-height: 1.15;
  }
}
@media (min-width: 40.0625em) {
  p, .govuk-body, .govuk-body-m {
    margin-bottom: 20px;
  }
}

.govuk-body-s {
  color: #0b0c0c;
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.1428571429;
  margin-top: 0;
  margin-bottom: 15px;
}
@media print {
  .govuk-body-s {
    color: #000000;
  }
}
@media print {
  .govuk-body-s {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-body-s {
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.25;
  }
}
@media print {
  .govuk-body-s {
    font-size: 14pt;
    line-height: 1.2;
  }
}
@media (min-width: 40.0625em) {
  .govuk-body-s {
    margin-bottom: 20px;
  }
}

.govuk-body-xs {
  color: #0b0c0c;
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.25;
  margin-top: 0;
  margin-bottom: 15px;
}
@media print {
  .govuk-body-xs {
    color: #000000;
  }
}
@media print {
  .govuk-body-xs {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-body-xs {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.4285714286;
  }
}
@media print {
  .govuk-body-xs {
    font-size: 12pt;
    line-height: 1.2;
  }
}
@media (min-width: 40.0625em) {
  .govuk-body-xs {
    margin-bottom: 20px;
  }
}

.govuk-body-l + .govuk-heading-l, .govuk-body-lead + .govuk-heading-l {
  padding-top: 5px;
}
@media (min-width: 40.0625em) {
  .govuk-body-l + .govuk-heading-l, .govuk-body-lead + .govuk-heading-l {
    padding-top: 10px;
  }
}

p + .govuk-heading-l, .govuk-body-m + .govuk-heading-l, .govuk-body + .govuk-heading-l,
.govuk-body-s + .govuk-heading-l,
.govuk-list + .govuk-heading-l,
.asc-cms-content ol + .govuk-heading-l,
.asc-cms-content ul + .govuk-heading-l {
  padding-top: 15px;
}
@media (min-width: 40.0625em) {
  p + .govuk-heading-l, .govuk-body-m + .govuk-heading-l, .govuk-body + .govuk-heading-l,
  .govuk-body-s + .govuk-heading-l,
  .govuk-list + .govuk-heading-l,
  .asc-cms-content ol + .govuk-heading-l,
  .asc-cms-content ul + .govuk-heading-l {
    padding-top: 20px;
  }
}

p + .govuk-heading-m, .govuk-body-m + .govuk-heading-m, .govuk-body + .govuk-heading-m,
.govuk-body-s + .govuk-heading-m,
.govuk-list + .govuk-heading-m,
.asc-cms-content ol + .govuk-heading-m,
.asc-cms-content ul + .govuk-heading-m,
p + .govuk-heading-s,
.govuk-body-m + .govuk-heading-s,
.govuk-body + .govuk-heading-s,
.govuk-body-s + .govuk-heading-s,
.govuk-list + .govuk-heading-s,
.asc-cms-content ol + .govuk-heading-s,
.asc-cms-content ul + .govuk-heading-s {
  padding-top: 5px;
}
@media (min-width: 40.0625em) {
  p + .govuk-heading-m, .govuk-body-m + .govuk-heading-m, .govuk-body + .govuk-heading-m,
  .govuk-body-s + .govuk-heading-m,
  .govuk-list + .govuk-heading-m,
  .asc-cms-content ol + .govuk-heading-m,
  .asc-cms-content ul + .govuk-heading-m,
  p + .govuk-heading-s,
  .govuk-body-m + .govuk-heading-s,
  .govuk-body + .govuk-heading-s,
  .govuk-body-s + .govuk-heading-s,
  .govuk-list + .govuk-heading-s,
  .asc-cms-content ol + .govuk-heading-s,
  .asc-cms-content ul + .govuk-heading-s {
    padding-top: 10px;
  }
}


.govuk-section-break {
  margin: 0;
  border: 0;
}

.govuk-section-break--xl {
  margin-top: 30px;
  margin-bottom: 30px;
}
@media (min-width: 40.0625em) {
  .govuk-section-break--xl {
    margin-top: 50px;
  }
}
@media (min-width: 40.0625em) {
  .govuk-section-break--xl {
    margin-bottom: 50px;
  }
}

.govuk-section-break--l {
  margin-top: 20px;
  margin-bottom: 20px;
}
@media (min-width: 40.0625em) {
  .govuk-section-break--l {
    margin-top: 30px;
  }
}
@media (min-width: 40.0625em) {
  .govuk-section-break--l {
    margin-bottom: 30px;
  }
}

.govuk-section-break--m {
  margin-top: 15px;
  margin-bottom: 15px;
}
@media (min-width: 40.0625em) {
  .govuk-section-break--m {
    margin-top: 20px;
  }
}
@media (min-width: 40.0625em) {
  .govuk-section-break--m {
    margin-bottom: 20px;
  }
}

.govuk-section-break--visible {
  border-bottom: 1px solid #b1b4b6;
}





































 








 












.govuk-button-group, .govuk-button-group--gap-between {
  margin-bottom: 5px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
}
@media (min-width: 40.0625em) {
  .govuk-button-group, .govuk-button-group--gap-between {
    margin-bottom: 15px;
  }
}
.govuk-button-group .govuk-link, .govuk-button-group--gap-between .govuk-link {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.1875;
  display: inline-block;
  max-width: 100%;
  margin-top: 5px;
  margin-bottom: 20px;
  text-align: center;
}
@media print {
  .govuk-button-group .govuk-link, .govuk-button-group--gap-between .govuk-link {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-button-group .govuk-link, .govuk-button-group--gap-between .govuk-link {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1;
  }
}
@media print {
  .govuk-button-group .govuk-link, .govuk-button-group--gap-between .govuk-link {
    font-size: 14pt;
    line-height: 19px;
  }
}
.govuk-button-group .govuk-button, .govuk-button-group--gap-between .govuk-button {
  margin-bottom: 17px;
}
@media (min-width: 40.0625em) {
  .govuk-button-group, .govuk-button-group--gap-between {
    margin-right: -15px;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: baseline;
    align-items: baseline;
  }
  .govuk-button-group .govuk-button, .govuk-button-group--gap-between .govuk-button,
  .govuk-button-group .govuk-link,
  .govuk-button-group--gap-between .govuk-link {
    margin-right: 15px;
  }
  .govuk-button-group .govuk-link, .govuk-button-group--gap-between .govuk-link {
    text-align: left;
  }
}

































 








 












.govuk-form-group {
  margin-bottom: 20px;
}
.govuk-form-group:after {
  content: "";
  display: block;
  clear: both;
}
@media (min-width: 40.0625em) {
  .govuk-form-group {
    margin-bottom: 30px;
  }
}
.govuk-form-group .govuk-form-group:last-of-type {
  margin-bottom: 0;
}

.govuk-form-group--error {
  padding-left: 15px;
  border-left: 5px solid #d4351c;
}
.govuk-form-group--error .govuk-form-group {
  padding: 0;
  border: 0;
}

































 








 












.govuk-grid-row {
  margin-right: -15px;
  margin-left: -15px;
}
.govuk-grid-row:after {
  content: "";
  display: block;
  clear: both;
}

.govuk-grid-column-one-quarter {
  box-sizing: border-box;
  width: 100%;
  padding: 0 15px;
}
@media (min-width: 40.0625em) {
  .govuk-grid-column-one-quarter {
    width: 25%;
    float: left;
  }
}

.govuk-grid-column-one-third {
  box-sizing: border-box;
  width: 100%;
  padding: 0 15px;
}
@media (min-width: 40.0625em) {
  .govuk-grid-column-one-third {
    width: 33.3333333333%;
    float: left;
  }
}

.govuk-grid-column-one-half {
  box-sizing: border-box;
  width: 100%;
  padding: 0 15px;
}
@media (min-width: 40.0625em) {
  .govuk-grid-column-one-half {
    width: 50%;
    float: left;
  }
}

.govuk-grid-column-two-thirds {
  box-sizing: border-box;
  width: 100%;
  padding: 0 15px;
}
@media (min-width: 40.0625em) {
  .govuk-grid-column-two-thirds {
    width: 66.6666666667%;
    float: left;
  }
}

.govuk-grid-column-three-quarters {
  box-sizing: border-box;
  width: 100%;
  padding: 0 15px;
}
@media (min-width: 40.0625em) {
  .govuk-grid-column-three-quarters {
    width: 75%;
    float: left;
  }
}

.govuk-grid-column-full {
  box-sizing: border-box;
  width: 100%;
  padding: 0 15px;
}
@media (min-width: 40.0625em) {
  .govuk-grid-column-full {
    width: 100%;
    float: left;
  }
}

.govuk-grid-column-one-quarter-from-desktop {
  box-sizing: border-box;
  padding: 0 15px;
}
@media (min-width: 48.0625em) {
  .govuk-grid-column-one-quarter-from-desktop {
    width: 25%;
    float: left;
  }
}

.govuk-grid-column-one-third-from-desktop {
  box-sizing: border-box;
  padding: 0 15px;
}
@media (min-width: 48.0625em) {
  .govuk-grid-column-one-third-from-desktop {
    width: 33.3333333333%;
    float: left;
  }
}

.govuk-grid-column-one-half-from-desktop {
  box-sizing: border-box;
  padding: 0 15px;
}
@media (min-width: 48.0625em) {
  .govuk-grid-column-one-half-from-desktop {
    width: 50%;
    float: left;
  }
}

.govuk-grid-column-two-thirds-from-desktop {
  box-sizing: border-box;
  padding: 0 15px;
}
@media (min-width: 48.0625em) {
  .govuk-grid-column-two-thirds-from-desktop {
    width: 66.6666666667%;
    float: left;
  }
}

.govuk-grid-column-three-quarters-from-desktop {
  box-sizing: border-box;
  padding: 0 15px;
}
@media (min-width: 48.0625em) {
  .govuk-grid-column-three-quarters-from-desktop {
    width: 75%;
    float: left;
  }
}

.govuk-grid-column-full-from-desktop {
  box-sizing: border-box;
  padding: 0 15px;
}
@media (min-width: 48.0625em) {
  .govuk-grid-column-full-from-desktop {
    width: 100%;
    float: left;
  }
}

































 








 












.govuk-main-wrapper {
  display: block;
  padding-top: 20px;
  padding-bottom: 20px;
}
@media (min-width: 40.0625em) {
  .govuk-main-wrapper {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.govuk-main-wrapper--auto-spacing:first-child,
.govuk-main-wrapper--l {
  padding-top: 30px;
}
@media (min-width: 40.0625em) {
  .govuk-main-wrapper--auto-spacing:first-child,
  .govuk-main-wrapper--l {
    padding-top: 50px;
  }
}

































 








 












.govuk-template {
  background-color: #f3f2f1;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
}
@supports (position: -webkit-sticky) or (position: sticky) {
  .govuk-template {
    scroll-padding-top: 60px;
  }
  .govuk-template:not(:has(.govuk-exit-this-page)) {
    scroll-padding-top: 0;
  }
}
@media screen {
  .govuk-template {
    overflow-y: scroll;
  }
}

.govuk-template__body {
  margin: 0;
  background-color: #ffffff;
}

































 








 












.govuk-width-container, .asc-width-container-admin-section {
  max-width: 960px;
  margin-right: 15px;
  margin-left: 15px;
}
@supports (margin: max(calc(0px))) {
  .govuk-width-container, .asc-width-container-admin-section {
    margin-right: max(15px, calc(15px + env(safe-area-inset-right)));
    margin-left: max(15px, calc(15px + env(safe-area-inset-left)));
  }
}
@media (min-width: 40.0625em) {
  .govuk-width-container, .asc-width-container-admin-section {
    margin-right: 30px;
    margin-left: 30px;
  }
  @supports (margin: max(calc(0px))) {
    .govuk-width-container, .asc-width-container-admin-section {
      margin-right: max(30px, calc(15px + env(safe-area-inset-right)));
      margin-left: max(30px, calc(15px + env(safe-area-inset-left)));
    }
  }
}
@media (min-width: 1020px) {
  .govuk-width-container, .asc-width-container-admin-section {
    margin-right: auto;
    margin-left: auto;
  }
  @supports (margin: max(calc(0px))) {
    .govuk-width-container, .asc-width-container-admin-section {
      margin-right: auto;
      margin-left: auto;
    }
  }
}


































 








 












.govuk-accordion {
  margin-bottom: 20px;
}
@media (min-width: 40.0625em) {
  .govuk-accordion {
    margin-bottom: 30px;
  }
}

.govuk-accordion__section {
  padding-top: 15px;
}

.govuk-accordion__section-heading {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 15px;
  padding-bottom: 15px;
}

.govuk-accordion__section-button, .asc-small-accordion-heading {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.1111111111;
  color: #0b0c0c;
  display: block;
  margin-bottom: 0;
  padding-top: 15px;
}
@media print {
  .govuk-accordion__section-button, .asc-small-accordion-heading {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-accordion__section-button, .asc-small-accordion-heading {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.25;
  }
}
@media print {
  .govuk-accordion__section-button, .asc-small-accordion-heading {
    font-size: 18pt;
    line-height: 1.15;
  }
}
@media print {
  .govuk-accordion__section-button, .asc-small-accordion-heading {
    color: #000000;
  }
}

.govuk-accordion__section-content > :last-child {
  margin-bottom: 0;
}

.js-enabled .govuk-accordion {
  border-bottom: 1px solid #b1b4b6;
}
.js-enabled .govuk-accordion__section {
  padding-top: 0;
}
.js-enabled .govuk-accordion__section-content {
  display: none;
  padding-top: 15px;
  padding-bottom: 30px;
}
@media (min-width: 40.0625em) {
  .js-enabled .govuk-accordion__section-content {
    padding-bottom: 50px;
  }
}
.js-enabled .govuk-accordion__section-content[hidden] {
  padding-top: 0;
  padding-bottom: 0;
}
@supports (content-visibility: hidden) {
  .js-enabled .govuk-accordion__section-content[hidden] {
    content-visibility: hidden;
    display: inherit;
  }
}
.js-enabled .govuk-accordion__section--expanded .govuk-accordion__section-content {
  display: block;
}
.js-enabled .govuk-accordion__show-all {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.25;
  position: relative;
  z-index: 1;
  margin-bottom: 9px;
  padding: 5px 2px 5px 0;
  border-width: 0;
  color: #1d70b8;
  background: none;
  cursor: pointer;
  -webkit-appearance: none;
}
@media print {
  .js-enabled .govuk-accordion__show-all {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .js-enabled .govuk-accordion__show-all {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.3157894737;
  }
}
@media print {
  .js-enabled .govuk-accordion__show-all {
    font-size: 14pt;
    line-height: 1.15;
  }
}
@media (min-width: 40.0625em) {
  .js-enabled .govuk-accordion__show-all {
    margin-bottom: 14px;
  }
}
.js-enabled .govuk-accordion__show-all::-moz-focus-inner {
  padding: 0;
  border: 0;
}
.js-enabled .govuk-accordion__show-all:hover {
  color: #0b0c0c;
  background: #f3f2f1;
  box-shadow: 0 -2px #f3f2f1, 0 4px #f3f2f1;
}
.js-enabled .govuk-accordion__show-all:hover .govuk-accordion__section-toggle-text {
  color: #0b0c0c;
}
.js-enabled .govuk-accordion__show-all:hover .govuk-accordion-nav__chevron {
  color: #0b0c0c;
  background: #0b0c0c;
}
.js-enabled .govuk-accordion__show-all:hover .govuk-accordion-nav__chevron:after {
  color: #f3f2f1;
}
.js-enabled .govuk-accordion__show-all:focus {
  outline: 3px solid transparent;
  color: #0b0c0c;
  background-color: #ffdd00;
  box-shadow: 0 -2px #ffdd00, 0 4px #0b0c0c;
  text-decoration: none;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}
.js-enabled .govuk-accordion__show-all:focus .govuk-accordion-nav__chevron {
  background: #0b0c0c;
}
.js-enabled .govuk-accordion__show-all:focus .govuk-accordion-nav__chevron:after {
  color: #ffdd00;
}
.js-enabled .govuk-accordion__section-heading {
  padding: 0;
}
.js-enabled .govuk-accordion-nav__chevron {
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  width: 1.25rem;
  height: 1.25rem;
  border: 0.0625rem solid;
  border-radius: 50%;
  vertical-align: middle;
}
.js-enabled .govuk-accordion-nav__chevron:after {
  content: "";
  box-sizing: border-box;
  display: block;
  position: absolute;
  bottom: 0.3125rem;
  left: 0.375rem;
  width: 0.375rem;
  height: 0.375rem;
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  border-top: 0.125rem solid;
  border-right: 0.125rem solid;
}
.js-enabled .govuk-accordion-nav__chevron--down {
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.js-enabled .govuk-accordion__section-button, .js-enabled .asc-small-accordion-heading {
  width: 100%;
  padding: 10px 0 0 0;
  border: 0;
  border-top: 1px solid #b1b4b6;
  border-bottom: 10px solid transparent;
  color: #0b0c0c;
  background: none;
  text-align: left;
  cursor: pointer;
  -webkit-appearance: none;
}
@media (min-width: 40.0625em) {
  .js-enabled .govuk-accordion__section-button, .js-enabled .asc-small-accordion-heading {
    padding-bottom: 10px;
  }
}
.js-enabled .govuk-accordion__section-button:active, .js-enabled .asc-small-accordion-heading:active {
  color: #0b0c0c;
  background: none;
}
.js-enabled .govuk-accordion__section-button:hover, .js-enabled .asc-small-accordion-heading:hover {
  color: #0b0c0c;
  background: #f3f2f1;
}
.js-enabled .govuk-accordion__section-button:hover .govuk-accordion__section-toggle-text, .js-enabled .asc-small-accordion-heading:hover .govuk-accordion__section-toggle-text {
  color: #0b0c0c;
}
.js-enabled .govuk-accordion__section-button:hover .govuk-accordion-nav__chevron, .js-enabled .asc-small-accordion-heading:hover .govuk-accordion-nav__chevron {
  color: #0b0c0c;
  background: #0b0c0c;
}
.js-enabled .govuk-accordion__section-button:hover .govuk-accordion-nav__chevron:after, .js-enabled .asc-small-accordion-heading:hover .govuk-accordion-nav__chevron:after {
  color: #f3f2f1;
}
.js-enabled .govuk-accordion__section-button:focus, .js-enabled .asc-small-accordion-heading:focus {
  outline: 0;
}
.js-enabled .govuk-accordion__section-button:focus .govuk-accordion__section-heading-text-focus, .js-enabled .asc-small-accordion-heading:focus .govuk-accordion__section-heading-text-focus,
.js-enabled .govuk-accordion__section-button:focus .govuk-accordion__section-summary-focus,
.js-enabled .asc-small-accordion-heading:focus .govuk-accordion__section-summary-focus,
.js-enabled .govuk-accordion__section-button:focus .govuk-accordion__section-toggle-focus,
.js-enabled .asc-small-accordion-heading:focus .govuk-accordion__section-toggle-focus {
  outline: 3px solid transparent;
  color: #0b0c0c;
  background-color: #ffdd00;
  box-shadow: 0 -2px #ffdd00, 0 4px #0b0c0c;
  text-decoration: none;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}
.js-enabled .govuk-accordion__section-button:focus .govuk-accordion-nav__chevron, .js-enabled .asc-small-accordion-heading:focus .govuk-accordion-nav__chevron {
  color: #0b0c0c;
  background: #0b0c0c;
}
.js-enabled .govuk-accordion__section-button:focus .govuk-accordion-nav__chevron:after, .js-enabled .asc-small-accordion-heading:focus .govuk-accordion-nav__chevron:after {
  color: #ffdd00;
}
.js-enabled .govuk-accordion__section-button::-moz-focus-inner, .js-enabled .asc-small-accordion-heading::-moz-focus-inner {
  padding: 0;
  border: 0;
}
.js-enabled .govuk-accordion__section--expanded .govuk-accordion__section-button, .js-enabled .govuk-accordion__section--expanded .asc-small-accordion-heading {
  padding-bottom: 15px;
  border-bottom: 0;
}
@media (min-width: 40.0625em) {
  .js-enabled .govuk-accordion__section--expanded .govuk-accordion__section-button, .js-enabled .govuk-accordion__section--expanded .asc-small-accordion-heading {
    padding-bottom: 20px;
  }
}
.js-enabled .govuk-accordion__section-button:focus .govuk-accordion__section-toggle-focus, .js-enabled .asc-small-accordion-heading:focus .govuk-accordion__section-toggle-focus {
  padding-bottom: 3px;
}
@media (min-width: 48.0625em) {
  .js-enabled .govuk-accordion__section-button:focus .govuk-accordion__section-toggle-focus, .js-enabled .asc-small-accordion-heading:focus .govuk-accordion__section-toggle-focus {
    padding-bottom: 2px;
  }
}
.js-enabled .govuk-accordion__section-toggle,
.js-enabled .govuk-accordion__section-heading-text,
.js-enabled .govuk-accordion__section-summary {
  display: block;
  margin-bottom: 13px;
}
.js-enabled .govuk-accordion__section-toggle .govuk-accordion__section-heading-text-focus,
.js-enabled .govuk-accordion__section-toggle .govuk-accordion__section-summary-focus,
.js-enabled .govuk-accordion__section-toggle .govuk-accordion__section-toggle-focus,
.js-enabled .govuk-accordion__section-heading-text .govuk-accordion__section-heading-text-focus,
.js-enabled .govuk-accordion__section-heading-text .govuk-accordion__section-summary-focus,
.js-enabled .govuk-accordion__section-heading-text .govuk-accordion__section-toggle-focus,
.js-enabled .govuk-accordion__section-summary .govuk-accordion__section-heading-text-focus,
.js-enabled .govuk-accordion__section-summary .govuk-accordion__section-summary-focus,
.js-enabled .govuk-accordion__section-summary .govuk-accordion__section-toggle-focus {
  display: inline;
}
.js-enabled .govuk-accordion__section-toggle {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.25;
  font-weight: 400;
  color: #1d70b8;
}
@media (min-width: 40.0625em) {
  .js-enabled .govuk-accordion__section-toggle {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.3157894737;
  }
}
@media print {
  .js-enabled .govuk-accordion__section-toggle {
    font-size: 14pt;
    line-height: 1.15;
  }
}
.js-enabled .govuk-accordion__show-all-text,
.js-enabled .govuk-accordion__section-toggle-text {
  margin-left: 5px;
  vertical-align: middle;
}
@media screen and (forced-colors: active) {
  .js-enabled .govuk-accordion__show-all:hover .govuk-accordion-nav__chevron,
  .js-enabled .govuk-accordion__section-button:hover .govuk-accordion-nav__chevron,
  .js-enabled .asc-small-accordion-heading:hover .govuk-accordion-nav__chevron {
    background-color: transparent;
  }
  .js-enabled .govuk-accordion__show-all:focus .govuk-accordion__section-heading-text-focus,
  .js-enabled .govuk-accordion__show-all:focus .govuk-accordion__section-summary-focus,
  .js-enabled .govuk-accordion__show-all:focus .govuk-accordion__section-toggle-focus,
  .js-enabled .govuk-accordion__show-all:focus .govuk-accordion-nav__chevron,
  .js-enabled .govuk-accordion__section-button:focus .govuk-accordion__section-heading-text-focus,
  .js-enabled .asc-small-accordion-heading:focus .govuk-accordion__section-heading-text-focus,
  .js-enabled .govuk-accordion__section-button:focus .govuk-accordion__section-summary-focus,
  .js-enabled .asc-small-accordion-heading:focus .govuk-accordion__section-summary-focus,
  .js-enabled .govuk-accordion__section-button:focus .govuk-accordion__section-toggle-focus,
  .js-enabled .asc-small-accordion-heading:focus .govuk-accordion__section-toggle-focus,
  .js-enabled .govuk-accordion__section-button:focus .govuk-accordion-nav__chevron,
  .js-enabled .asc-small-accordion-heading:focus .govuk-accordion-nav__chevron {
    background: transparent;
    background-color: transparent;
  }
}
@media (hover: none) {
  .js-enabled .govuk-accordion__section-header:hover {
    border-top-color: #b1b4b6;
    box-shadow: inset 0 3px 0 0 #1d70b8;
  }
  .js-enabled .govuk-accordion__section-header:hover .govuk-accordion__section-button, .js-enabled .govuk-accordion__section-header:hover .asc-small-accordion-heading {
    border-top-color: #b1b4b6;
  }
}


.govuk-back-link {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.1428571429;
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: underline;
  display: inline-block;
  position: relative;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-left: 0.875em;
}
@media (min-width: 40.0625em) {
  .govuk-back-link {
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.25;
  }
}
@media print {
  .govuk-back-link {
    font-size: 14pt;
    line-height: 1.2;
  }
}
@media print {
  .govuk-back-link {
    font-family: sans-serif;
  }
}
.govuk-back-link:focus {
  outline: 3px solid transparent;
  color: #0b0c0c;
  background-color: #ffdd00;
  box-shadow: 0 -2px #ffdd00, 0 4px #0b0c0c;
  text-decoration: none;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}
.govuk-back-link:link, .govuk-back-link:visited {
  color: #0b0c0c;
}
@media print {
  .govuk-back-link:link, .govuk-back-link:visited {
    color: #000000;
  }
}
.govuk-back-link:hover {
  color: rgba(11, 12, 12, 0.99);
}
.govuk-back-link:active, .govuk-back-link:focus {
  color: #0b0c0c;
}
@media print {
  .govuk-back-link:active, .govuk-back-link:focus {
    color: #000000;
  }
}

.govuk-back-link:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0.1875em;
  width: 0.4375em;
  height: 0.4375em;
  margin: auto 0;
  -ms-transform: rotate(225deg);
  transform: rotate(225deg);
  border: solid;
  border-width: 1px 1px 0 0;
  border-color: #505a5f;
}
@supports (border-width: max(0px)) {
  .govuk-back-link:before {
    border-width: max(1px, 0.0625em) max(1px, 0.0625em) 0 0;
    font-size: max(16px, 1em);
  }
}

.govuk-back-link:focus:before {
  border-color: #0b0c0c;
}

.govuk-back-link:after {
  content: "";
  position: absolute;
  top: -14px;
  right: 0;
  bottom: -14px;
  left: 0;
}

.govuk-back-link--inverse:link, .govuk-back-link--inverse:visited {
  color: #ffffff;
}
.govuk-back-link--inverse:hover, .govuk-back-link--inverse:active {
  color: rgba(255, 255, 255, 0.99);
}
.govuk-back-link--inverse:focus {
  color: #0b0c0c;
}
.govuk-back-link--inverse:before {
  border-color: currentcolor;
}


.govuk-breadcrumbs {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.1428571429;
  color: #0b0c0c;
  margin-top: 15px;
  margin-bottom: 10px;
}
@media print {
  .govuk-breadcrumbs {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-breadcrumbs {
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.25;
  }
}
@media print {
  .govuk-breadcrumbs {
    font-size: 14pt;
    line-height: 1.2;
  }
}
@media print {
  .govuk-breadcrumbs {
    color: #000000;
  }
}

.govuk-breadcrumbs__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.govuk-breadcrumbs__list:after {
  content: "";
  display: block;
  clear: both;
}

.govuk-breadcrumbs__list-item {
  display: inline-block;
  position: relative;
  margin-bottom: 5px;
  margin-left: 0.625em;
  padding-left: 0.9784375em;
  float: left;
}
.govuk-breadcrumbs__list-item:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -0.206875em;
  width: 0.4375em;
  height: 0.4375em;
  margin: auto 0;
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  border: solid;
  border-width: 1px 1px 0 0;
  border-color: #505a5f;
}
@supports (border-width: max(0px)) {
  .govuk-breadcrumbs__list-item:before {
    border-width: max(1px, 0.0625em) max(1px, 0.0625em) 0 0;
    font-size: max(16px, 1em);
  }
}
.govuk-breadcrumbs__list-item:first-child {
  margin-left: 0;
  padding-left: 0;
}
.govuk-breadcrumbs__list-item:first-child:before {
  content: none;
  display: none;
}

.govuk-breadcrumbs__link {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: underline;
}
@media print {
  .govuk-breadcrumbs__link {
    font-family: sans-serif;
  }
}
.govuk-breadcrumbs__link:focus {
  outline: 3px solid transparent;
  color: #0b0c0c;
  background-color: #ffdd00;
  box-shadow: 0 -2px #ffdd00, 0 4px #0b0c0c;
  text-decoration: none;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}
.govuk-breadcrumbs__link:link, .govuk-breadcrumbs__link:visited {
  color: #0b0c0c;
}
@media print {
  .govuk-breadcrumbs__link:link, .govuk-breadcrumbs__link:visited {
    color: #000000;
  }
}
.govuk-breadcrumbs__link:hover {
  color: rgba(11, 12, 12, 0.99);
}
.govuk-breadcrumbs__link:active, .govuk-breadcrumbs__link:focus {
  color: #0b0c0c;
}
@media print {
  .govuk-breadcrumbs__link:active, .govuk-breadcrumbs__link:focus {
    color: #000000;
  }
}

@media (max-width: 40.0525em) {
  .govuk-breadcrumbs--collapse-on-mobile .govuk-breadcrumbs__list-item {
    display: none;
  }
  .govuk-breadcrumbs--collapse-on-mobile .govuk-breadcrumbs__list-item:first-child, .govuk-breadcrumbs--collapse-on-mobile .govuk-breadcrumbs__list-item:last-child {
    display: inline-block;
  }
  .govuk-breadcrumbs--collapse-on-mobile .govuk-breadcrumbs__list-item:before {
    top: 0.375em;
    margin: 0;
  }
  .govuk-breadcrumbs--collapse-on-mobile .govuk-breadcrumbs__list {
    display: -ms-flexbox;
    display: flex;
  }
}

.govuk-breadcrumbs--inverse {
  color: #ffffff;
}
.govuk-breadcrumbs--inverse .govuk-breadcrumbs__link:link, .govuk-breadcrumbs--inverse .govuk-breadcrumbs__link:visited {
  color: #ffffff;
}
.govuk-breadcrumbs--inverse .govuk-breadcrumbs__link:hover, .govuk-breadcrumbs--inverse .govuk-breadcrumbs__link:active {
  color: rgba(255, 255, 255, 0.99);
}
.govuk-breadcrumbs--inverse .govuk-breadcrumbs__link:focus {
  color: #0b0c0c;
}
.govuk-breadcrumbs--inverse .govuk-breadcrumbs__list-item:before {
  border-color: currentcolor;
}


.govuk-button {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.1875;
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  width: 100%;
  margin-top: 0;
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 22px;
  padding: 8px 10px 7px;
  border: 2px solid transparent;
  border-radius: 0;
  color: #ffffff;
  background-color: #00703c;
  box-shadow: 0 2px 0 #002d18;
  text-align: center;
  vertical-align: top;
  cursor: pointer;
  -webkit-appearance: none;
}
@media print {
  .govuk-button {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-button {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1;
  }
}
@media print {
  .govuk-button {
    font-size: 14pt;
    line-height: 19px;
  }
}
@media (min-width: 40.0625em) {
  .govuk-button {
    margin-bottom: 32px;
  }
}
@media (min-width: 40.0625em) {
  .govuk-button {
    width: auto;
  }
}
.govuk-button:link, .govuk-button:visited, .govuk-button:active, .govuk-button:hover {
  color: #ffffff;
  text-decoration: none;
}
.govuk-button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
.govuk-button:hover {
  background-color: #005a30;
}
.govuk-button:active {
  top: 2px;
}
.govuk-button:focus {
  border-color: #ffdd00;
  outline: 3px solid transparent;
  box-shadow: inset 0 0 0 1px #ffdd00;
}
.govuk-button:focus:not(:active):not(:hover) {
  border-color: #ffdd00;
  color: #0b0c0c;
  background-color: #ffdd00;
  box-shadow: 0 2px 0 #0b0c0c;
}
.govuk-button:before {
  content: "";
  display: block;
  position: absolute;
  top: -2px;
  right: -2px;
  bottom: -4px;
  left: -2px;
  background: transparent;
}
.govuk-button:active:before {
  top: -4px;
}

.govuk-button--disabled,
.govuk-button[disabled=disabled],
.govuk-button[disabled] {
  opacity: 0.5;
}
.govuk-button--disabled:hover,
.govuk-button[disabled=disabled]:hover,
.govuk-button[disabled]:hover {
  background-color: #00703c;
  cursor: not-allowed;
}
.govuk-button--disabled:active,
.govuk-button[disabled=disabled]:active,
.govuk-button[disabled]:active {
  top: 0;
  box-shadow: 0 2px 0 #002d18;
}

.govuk-button--secondary {
  background-color: #f3f2f1;
  box-shadow: 0 2px 0 #929191;
}
.govuk-button--secondary, .govuk-button--secondary:link, .govuk-button--secondary:visited, .govuk-button--secondary:active, .govuk-button--secondary:hover {
  color: #0b0c0c;
}
.govuk-button--secondary:hover {
  background-color: #dbdad9;
}
.govuk-button--secondary:hover[disabled] {
  background-color: #f3f2f1;
}

.govuk-button--warning {
  background-color: #d4351c;
  box-shadow: 0 2px 0 #55150b;
}
.govuk-button--warning, .govuk-button--warning:link, .govuk-button--warning:visited, .govuk-button--warning:active, .govuk-button--warning:hover {
  color: #ffffff;
}
.govuk-button--warning:hover {
  background-color: #aa2a16;
}
.govuk-button--warning:hover[disabled] {
  background-color: #d4351c;
}

.govuk-button--inverse {
  background-color: #ffffff;
  box-shadow: 0 2px 0 #144e81;
}
.govuk-button--inverse, .govuk-button--inverse:link, .govuk-button--inverse:visited, .govuk-button--inverse:active, .govuk-button--inverse:hover {
  color: #1d70b8;
}
.govuk-button--inverse:hover {
  background-color: #e8f1f8;
}
.govuk-button--inverse:hover[disabled] {
  background-color: #ffffff;
}

.govuk-button--start {
  font-weight: 700;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1;
  display: -ms-inline-flexbox;
  display: inline-flex;
  min-height: auto;
  -ms-flex-pack: center;
  justify-content: center;
}
@media (min-width: 40.0625em) {
  .govuk-button--start {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1;
  }
}
@media print {
  .govuk-button--start {
    font-size: 18pt;
    line-height: 1;
  }
}

.govuk-button__start-icon {
  margin-left: 5px;
  vertical-align: middle;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-item-align: center;
  align-self: center;
  forced-color-adjust: auto;
}
@media (min-width: 48.0625em) {
  .govuk-button__start-icon {
    margin-left: 10px;
  }
}


.govuk-error-message {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.25;
  display: block;
  margin-top: 0;
  margin-bottom: 15px;
  clear: both;
  color: #d4351c;
}
@media print {
  .govuk-error-message {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-error-message {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.3157894737;
  }
}
@media print {
  .govuk-error-message {
    font-size: 14pt;
    line-height: 1.15;
  }
}


.govuk-fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
  border: 0;
}
.govuk-fieldset:after {
  content: "";
  display: block;
  clear: both;
}

@supports not (caret-color: auto) {
  .govuk-fieldset,
  x:-moz-any-link {
    display: table-cell;
  }
}
.govuk-fieldset__legend {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.25;
  color: #0b0c0c;
  box-sizing: border-box;
  display: table;
  max-width: 100%;
  margin-bottom: 10px;
  padding: 0;
  white-space: normal;
}
@media print {
  .govuk-fieldset__legend {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-fieldset__legend {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.3157894737;
  }
}
@media print {
  .govuk-fieldset__legend {
    font-size: 14pt;
    line-height: 1.15;
  }
}
@media print {
  .govuk-fieldset__legend {
    color: #000000;
  }
}

.govuk-fieldset__legend--xl {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  font-size: 32px;
  font-size: 2rem;
  line-height: 1.09375;
  margin-bottom: 15px;
}
@media print {
  .govuk-fieldset__legend--xl {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-fieldset__legend--xl {
    font-size: 48px;
    font-size: 3rem;
    line-height: 1.0416666667;
  }
}
@media print {
  .govuk-fieldset__legend--xl {
    font-size: 32pt;
    line-height: 1.15;
  }
}

.govuk-fieldset__legend--l {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.0416666667;
  margin-bottom: 15px;
}
@media print {
  .govuk-fieldset__legend--l {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-fieldset__legend--l {
    font-size: 36px;
    font-size: 2.25rem;
    line-height: 1.1111111111;
  }
}
@media print {
  .govuk-fieldset__legend--l {
    font-size: 24pt;
    line-height: 1.05;
  }
}

.govuk-fieldset__legend--m {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.1111111111;
  margin-bottom: 15px;
}
@media print {
  .govuk-fieldset__legend--m {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-fieldset__legend--m {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.25;
  }
}
@media print {
  .govuk-fieldset__legend--m {
    font-size: 18pt;
    line-height: 1.15;
  }
}

.govuk-fieldset__legend--s {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.25;
}
@media print {
  .govuk-fieldset__legend--s {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-fieldset__legend--s {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.3157894737;
  }
}
@media print {
  .govuk-fieldset__legend--s {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.govuk-fieldset__heading {
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
}


.govuk-hint {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.25;
  margin-bottom: 15px;
  color: #505a5f;
}
@media print {
  .govuk-hint {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-hint {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.3157894737;
  }
}
@media print {
  .govuk-hint {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.govuk-label:not(.govuk-label--m):not(.govuk-label--l):not(.govuk-label--xl) + .govuk-hint {
  margin-bottom: 10px;
}

.govuk-fieldset__legend:not(.govuk-fieldset__legend--m):not(.govuk-fieldset__legend--l):not(.govuk-fieldset__legend--xl) + .govuk-hint {
  margin-bottom: 10px;
}

.govuk-fieldset__legend + .govuk-hint {
  margin-top: -5px;
}


.govuk-label {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.25;
  color: #0b0c0c;
  display: block;
  margin-bottom: 5px;
}
@media print {
  .govuk-label {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-label {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.3157894737;
  }
}
@media print {
  .govuk-label {
    font-size: 14pt;
    line-height: 1.15;
  }
}
@media print {
  .govuk-label {
    color: #000000;
  }
}

.govuk-label--xl {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  font-size: 32px;
  font-size: 2rem;
  line-height: 1.09375;
  margin-bottom: 15px;
}
@media print {
  .govuk-label--xl {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-label--xl {
    font-size: 48px;
    font-size: 3rem;
    line-height: 1.0416666667;
  }
}
@media print {
  .govuk-label--xl {
    font-size: 32pt;
    line-height: 1.15;
  }
}

.govuk-label--l {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.0416666667;
  margin-bottom: 15px;
}
@media print {
  .govuk-label--l {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-label--l {
    font-size: 36px;
    font-size: 2.25rem;
    line-height: 1.1111111111;
  }
}
@media print {
  .govuk-label--l {
    font-size: 24pt;
    line-height: 1.05;
  }
}

.govuk-label--m {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.1111111111;
  margin-bottom: 10px;
}
@media print {
  .govuk-label--m {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-label--m {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.25;
  }
}
@media print {
  .govuk-label--m {
    font-size: 18pt;
    line-height: 1.15;
  }
}

.govuk-label--s {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.25;
}
@media print {
  .govuk-label--s {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-label--s {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.3157894737;
  }
}
@media print {
  .govuk-label--s {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.govuk-label-wrapper {
  margin: 0;
}


.govuk-checkboxes__item {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.25;
  display: block;
  position: relative;
  min-height: 40px;
  margin-bottom: 10px;
  padding-left: 40px;
  clear: left;
}
@media print {
  .govuk-checkboxes__item {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-checkboxes__item {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.3157894737;
  }
}
@media print {
  .govuk-checkboxes__item {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.govuk-checkboxes__item:last-child,
.govuk-checkboxes__item:last-of-type {
  margin-bottom: 0;
}

.govuk-checkboxes__input {
  cursor: pointer;
  position: absolute;
  z-index: 1;
  top: -2px;
  left: -2px;
  width: 44px;
  height: 44px;
  margin: 0;
  opacity: 0;
}

.govuk-checkboxes__label {
  display: inline-block;
  margin-bottom: 0;
  padding: 8px 15px 5px;
  cursor: pointer;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

.govuk-checkboxes__label:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border: 2px solid currentcolor;
  background: transparent;
}

.govuk-checkboxes__label:after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 11px;
  left: 9px;
  width: 23px;
  height: 12px;
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  border: solid;
  border-width: 0 0 5px 5px;
  border-top-color: transparent;
  opacity: 0;
  background: transparent;
}

.govuk-checkboxes__hint {
  display: block;
  padding-right: 15px;
  padding-left: 15px;
}

.govuk-checkboxes__input:focus + .govuk-checkboxes__label:before {
  border-width: 4px;
  outline: 3px solid transparent;
  outline-offset: 1px;
  box-shadow: 0 0 0 3px #ffdd00;
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .govuk-checkboxes__input:focus + .govuk-checkboxes__label:before {
    outline-color: Highlight;
  }
}

.govuk-checkboxes__input:checked + .govuk-checkboxes__label:after {
  opacity: 1;
}

.govuk-checkboxes__input:disabled,
.govuk-checkboxes__input:disabled + .govuk-checkboxes__label {
  cursor: not-allowed;
}

.govuk-checkboxes__input:disabled + .govuk-checkboxes__label,
.govuk-checkboxes__input:disabled ~ .govuk-hint {
  opacity: 0.5;
}

.govuk-checkboxes__divider {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.25;
  color: #0b0c0c;
  width: 40px;
  margin-bottom: 10px;
  text-align: center;
}
@media print {
  .govuk-checkboxes__divider {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-checkboxes__divider {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.3157894737;
  }
}
@media print {
  .govuk-checkboxes__divider {
    font-size: 14pt;
    line-height: 1.15;
  }
}
@media print {
  .govuk-checkboxes__divider {
    color: #000000;
  }
}

.govuk-checkboxes__conditional {
  margin-bottom: 15px;
  margin-left: 18px;
  padding-left: 33px;
  border-left: 4px solid #b1b4b6;
}
@media (min-width: 40.0625em) {
  .govuk-checkboxes__conditional {
    margin-bottom: 20px;
  }
}
.js-enabled .govuk-checkboxes__conditional--hidden {
  display: none;
}
.govuk-checkboxes__conditional > :last-child {
  margin-bottom: 0;
}

.govuk-checkboxes--small .govuk-checkboxes__item {
  min-height: 0;
  margin-bottom: 0;
  padding-left: 34px;
  float: left;
}
.govuk-checkboxes--small .govuk-checkboxes__item:after {
  content: "";
  display: block;
  clear: both;
}
.govuk-checkboxes--small .govuk-checkboxes__input {
  left: -10px;
}
.govuk-checkboxes--small .govuk-checkboxes__label {
  margin-top: -2px;
  padding: 13px 15px 13px 1px;
  float: left;
}
@media (min-width: 40.0625em) {
  .govuk-checkboxes--small .govuk-checkboxes__label {
    padding: 11px 15px 10px 1px;
  }
}
.govuk-checkboxes--small .govuk-checkboxes__label:before {
  top: 8px;
  width: 24px;
  height: 24px;
}
.govuk-checkboxes--small .govuk-checkboxes__label:after {
  top: 15px;
  left: 6px;
  width: 12px;
  height: 6.5px;
  border-width: 0 0 3px 3px;
}
.govuk-checkboxes--small .govuk-checkboxes__hint {
  padding: 0;
  clear: both;
}
.govuk-checkboxes--small .govuk-checkboxes__conditional {
  margin-left: 10px;
  padding-left: 20px;
  clear: both;
}
.govuk-checkboxes--small .govuk-checkboxes__item:hover .govuk-checkboxes__input:not(:disabled) + .govuk-checkboxes__label:before {
  box-shadow: 0 0 0 10px #b1b4b6;
}
.govuk-checkboxes--small .govuk-checkboxes__item:hover .govuk-checkboxes__input:focus + .govuk-checkboxes__label:before {
  box-shadow: 0 0 0 3px #ffdd00, 0 0 0 10px #b1b4b6;
}
@media (hover: none), (pointer: coarse) {
  .govuk-checkboxes--small .govuk-checkboxes__item:hover .govuk-checkboxes__input:not(:disabled) + .govuk-checkboxes__label:before {
    box-shadow: initial;
  }
  .govuk-checkboxes--small .govuk-checkboxes__item:hover .govuk-checkboxes__input:focus + .govuk-checkboxes__label:before {
    box-shadow: 0 0 0 3px #ffdd00;
  }
}








.govuk-textarea {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.25;
  box-sizing: border-box;
  display: block;
  width: 100%;
  min-height: 40px;
  margin-bottom: 20px;
  padding: 5px;
  resize: vertical;
  border: 2px solid #0b0c0c;
  border-radius: 0;
  -webkit-appearance: none;
}
@media print {
  .govuk-textarea {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-textarea {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.25;
  }
}
@media print {
  .govuk-textarea {
    font-size: 14pt;
    line-height: 1.25;
  }
}
@media (min-width: 40.0625em) {
  .govuk-textarea {
    margin-bottom: 30px;
  }
}
.govuk-textarea:focus {
  outline: 3px solid #ffdd00;
  outline-offset: 0;
  box-shadow: inset 0 0 0 2px;
}
.govuk-textarea:disabled {
  opacity: 0.5;
  color: inherit;
  background-color: transparent;
  cursor: not-allowed;
}

.govuk-textarea--error {
  border-color: #d4351c;
}
.govuk-textarea--error:focus {
  border-color: #0b0c0c;
}


.govuk-character-count {
  margin-bottom: 20px;
}
@media (min-width: 40.0625em) {
  .govuk-character-count {
    margin-bottom: 30px;
  }
}
.govuk-character-count .govuk-form-group,
.govuk-character-count .govuk-textarea {
  margin-bottom: 5px;
}

.govuk-character-count__message {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-feature-settings: "tnum" 1;
  font-feature-settings: "tnum" 1;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
}
@media print {
  .govuk-character-count__message {
    font-family: sans-serif;
  }
}
@supports (font-variant-numeric: tabular-nums) {
  .govuk-character-count__message {
    -webkit-font-feature-settings: normal;
    font-feature-settings: normal;
    font-variant-numeric: tabular-nums;
  }
}
.govuk-character-count__message:after {
  content: "​";
}

.govuk-character-count__message--disabled {
  visibility: hidden;
}


.govuk-cookie-banner {
  padding-top: 20px;
  border-bottom: 10px solid transparent;
  background-color: #f3f2f1;
}

.govuk-cookie-banner[hidden] {
  display: none;
}

.govuk-cookie-banner__message {
  margin-bottom: -10px;
}
.govuk-cookie-banner__message[hidden] {
  display: none;
}
.govuk-cookie-banner__message:focus {
  outline: none;
}






.govuk-input {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.25;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  height: 2.5rem;
  margin-top: 0;
  padding: 5px;
  border: 2px solid #0b0c0c;
  border-radius: 0;
  -webkit-appearance: none;
  appearance: none;
}
@media print {
  .govuk-input {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-input {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.3157894737;
  }
}
@media print {
  .govuk-input {
    font-size: 14pt;
    line-height: 1.15;
  }
}
.govuk-input:focus {
  outline: 3px solid #ffdd00;
  outline-offset: 0;
  box-shadow: inset 0 0 0 2px;
}
.govuk-input:disabled {
  opacity: 0.5;
  color: inherit;
  background-color: transparent;
  cursor: not-allowed;
}

.govuk-input::-webkit-outer-spin-button,
.govuk-input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

.govuk-input[type=number] {
  -moz-appearance: textfield;
}

.govuk-input--error {
  border-color: #d4351c;
}
.govuk-input--error:focus {
  border-color: #0b0c0c;
}

.govuk-input--extra-letter-spacing {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-feature-settings: "tnum" 1;
  font-feature-settings: "tnum" 1;
  font-weight: 400;
  letter-spacing: 0.05em;
}
@media print {
  .govuk-input--extra-letter-spacing {
    font-family: sans-serif;
  }
}
@supports (font-variant-numeric: tabular-nums) {
  .govuk-input--extra-letter-spacing {
    -webkit-font-feature-settings: normal;
    font-feature-settings: normal;
    font-variant-numeric: tabular-nums;
  }
}

.govuk-input--width-30 {
  max-width: 29.5em;
}

.govuk-input--width-20 {
  max-width: 20.5em;
}

.govuk-input--width-10 {
  max-width: 11.5em;
}

.govuk-input--width-5 {
  max-width: 5.5em;
}

.govuk-input--width-4 {
  max-width: 4.5em;
}

.govuk-input--width-3 {
  max-width: 3.75em;
}

.govuk-input--width-2 {
  max-width: 2.75em;
}

.govuk-input__wrapper {
  display: -ms-flexbox;
  display: flex;
}
.govuk-input__wrapper .govuk-input {
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
}
.govuk-input__wrapper .govuk-input:focus {
  z-index: 1;
}
@media (max-width: 19.99em) {
  .govuk-input__wrapper {
    display: block;
  }
  .govuk-input__wrapper .govuk-input {
    max-width: 100%;
  }
}

.govuk-input__prefix,
.govuk-input__suffix {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.25;
  box-sizing: border-box;
  display: inline-block;
  min-width: 40px;
  min-width: 2.5rem;
  height: 40px;
  height: 2.5rem;
  padding: 5px;
  border: 2px solid #0b0c0c;
  background-color: #f3f2f1;
  text-align: center;
  white-space: nowrap;
  cursor: default;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
@media print {
  .govuk-input__prefix,
  .govuk-input__suffix {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-input__prefix,
  .govuk-input__suffix {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.3157894737;
  }
}
@media print {
  .govuk-input__prefix,
  .govuk-input__suffix {
    font-size: 14pt;
    line-height: 1.15;
  }
}
@media (max-width: 40.0525em) {
  .govuk-input__prefix,
  .govuk-input__suffix {
    line-height: 1.6;
  }
}
@media (max-width: 19.99em) {
  .govuk-input__prefix,
  .govuk-input__suffix {
    display: block;
    height: 100%;
    white-space: normal;
  }
}

@media (max-width: 19.99em) {
  .govuk-input__prefix {
    border-bottom: 0;
  }
}
@media (min-width: 20em) {
  .govuk-input__prefix {
    border-right: 0;
  }
}

@media (max-width: 19.99em) {
  .govuk-input__suffix {
    border-top: 0;
  }
}
@media (min-width: 20em) {
  .govuk-input__suffix {
    border-left: 0;
  }
}




.govuk-date-input {
  font-size: 0;
}
.govuk-date-input:after {
  content: "";
  display: block;
  clear: both;
}

.govuk-date-input__item {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 0;
}

.govuk-date-input__label {
  display: block;
}

.govuk-date-input__input {
  margin-bottom: 0;
}


.govuk-details {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.25;
  color: #0b0c0c;
  margin-bottom: 20px;
  display: block;
}
@media print {
  .govuk-details {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-details {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.3157894737;
  }
}
@media print {
  .govuk-details {
    font-size: 14pt;
    line-height: 1.15;
  }
}
@media print {
  .govuk-details {
    color: #000000;
  }
}
@media (min-width: 40.0625em) {
  .govuk-details {
    margin-bottom: 30px;
  }
}

.govuk-details__summary {
  display: inline-block;
  position: relative;
  margin-bottom: 5px;
  padding-left: 25px;
  color: #1d70b8;
  cursor: pointer;
}
.govuk-details__summary:hover {
  color: #003078;
}
.govuk-details__summary:focus {
  outline: 3px solid transparent;
  color: #0b0c0c;
  background-color: #ffdd00;
  box-shadow: 0 -2px #ffdd00, 0 4px #0b0c0c;
  text-decoration: none;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}

.govuk-details__summary-text {
  text-decoration: underline;
}

.govuk-details__summary:focus .govuk-details__summary-text {
  text-decoration: none;
}

.govuk-details__summary::-webkit-details-marker {
  display: none;
}

.govuk-details__summary:before {
  content: "";
  position: absolute;
  top: -1px;
  bottom: 0;
  left: 0;
  margin: auto;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent;
  -webkit-clip-path: polygon(0% 0%, 100% 50%, 0% 100%);
  clip-path: polygon(0% 0%, 100% 50%, 0% 100%);
  border-width: 7px 0 7px 12.124px;
  border-left-color: inherit;
}
.govuk-details[open] > .govuk-details__summary:before {
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent;
  -webkit-clip-path: polygon(0% 0%, 50% 100%, 100% 0%);
  clip-path: polygon(0% 0%, 50% 100%, 100% 0%);
  border-width: 12.124px 7px 0 7px;
  border-top-color: inherit;
}

.govuk-details__text {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
  border-left: 5px solid #b1b4b6;
}

.govuk-details__text p {
  margin-top: 0;
  margin-bottom: 20px;
}

.govuk-details__text > :last-child {
  margin-bottom: 0;
}




.govuk-error-summary {
  color: #0b0c0c;
  padding: 15px;
  margin-bottom: 30px;
  border: 5px solid #d4351c;
}
@media print {
  .govuk-error-summary {
    color: #000000;
  }
}
@media (min-width: 40.0625em) {
  .govuk-error-summary {
    padding: 20px;
  }
}
@media (min-width: 40.0625em) {
  .govuk-error-summary {
    margin-bottom: 50px;
  }
}
.govuk-error-summary:focus {
  outline: 3px solid #ffdd00;
}

.govuk-error-summary__title {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.1111111111;
  margin-top: 0;
  margin-bottom: 15px;
}
@media print {
  .govuk-error-summary__title {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-error-summary__title {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.25;
  }
}
@media print {
  .govuk-error-summary__title {
    font-size: 18pt;
    line-height: 1.15;
  }
}
@media (min-width: 40.0625em) {
  .govuk-error-summary__title {
    margin-bottom: 20px;
  }
}

.govuk-error-summary__body {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.25;
}
@media print {
  .govuk-error-summary__body {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-error-summary__body {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.3157894737;
  }
}
@media print {
  .govuk-error-summary__body {
    font-size: 14pt;
    line-height: 1.15;
  }
}
.govuk-error-summary__body p {
  margin-top: 0;
  margin-bottom: 15px;
}
@media (min-width: 40.0625em) {
  .govuk-error-summary__body p {
    margin-bottom: 20px;
  }
}

.govuk-error-summary__list {
  margin-top: 0;
  margin-bottom: 0;
}

.govuk-error-summary__list a {
  font-weight: 700;
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: underline;
}
@media print {
  .govuk-error-summary__list a {
    font-family: sans-serif;
  }
}
.govuk-error-summary__list a:focus {
  outline: 3px solid transparent;
  color: #0b0c0c;
  background-color: #ffdd00;
  box-shadow: 0 -2px #ffdd00, 0 4px #0b0c0c;
  text-decoration: none;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}
.govuk-error-summary__list a:link, .govuk-error-summary__list a:visited {
  color: #d4351c;
}
.govuk-error-summary__list a:hover {
  color: #942514;
}
.govuk-error-summary__list a:active {
  color: #d4351c;
}
.govuk-error-summary__list a:focus {
  color: #0b0c0c;
}



.govuk-exit-this-page {
  margin-bottom: 30px;
  position: -webkit-sticky;
  position: sticky;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
}
@media (min-width: 40.0625em) {
  .govuk-exit-this-page {
    margin-bottom: 50px;
  }
}
@media (min-width: 40.0625em) {
  .govuk-exit-this-page {
    display: inline-block;
    right: 0;
    left: auto;
    width: auto;
    float: right;
  }
}

.govuk-exit-this-page__button {
  margin-bottom: 0;
}

.govuk-exit-this-page__indicator {
  padding: 10px;
  display: none;
  padding-bottom: 0;
  color: inherit;
  line-height: 0;
  text-align: center;
  pointer-events: none;
}

.govuk-exit-this-page__indicator--visible {
  display: block;
}

.govuk-exit-this-page__indicator-light {
  box-sizing: border-box;
  display: inline-block;
  width: 0.75em;
  height: 0.75em;
  margin: 0 0.125em;
  border-width: 2px;
  border-style: solid;
  border-radius: 50%;
  border-color: currentcolor;
}

.govuk-exit-this-page__indicator-light--on {
  border-width: 0.375em;
}

@media only print {
  .govuk-exit-this-page {
    display: none;
  }
}
.govuk-exit-this-page-overlay {
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #ffffff;
}

.govuk-exit-this-page-hide-content * {
  display: none !important;
}
.govuk-exit-this-page-hide-content .govuk-exit-this-page-overlay {
  display: block !important;
}






.govuk-file-upload {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.25;
  color: #0b0c0c;
  max-width: 100%;
  margin-left: -5px;
  padding: 5px;
}
@media print {
  .govuk-file-upload {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-file-upload {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.3157894737;
  }
}
@media print {
  .govuk-file-upload {
    font-size: 14pt;
    line-height: 1.15;
  }
}
@media print {
  .govuk-file-upload {
    color: #000000;
  }
}
.govuk-file-upload::-webkit-file-upload-button {
  -webkit-appearance: button;
  color: inherit;
  font: inherit;
}
.govuk-file-upload:focus {
  outline: 3px solid #ffdd00;
  box-shadow: inset 0 0 0 4px #0b0c0c;
}
.govuk-file-upload:focus-within {
  outline: 3px solid #ffdd00;
  box-shadow: inset 0 0 0 4px #0b0c0c;
}
.govuk-file-upload:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}


.govuk-footer {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.1428571429;
  padding-top: 25px;
  padding-bottom: 15px;
  border-top: 1px solid #b1b4b6;
  color: #0b0c0c;
  background: #f3f2f1;
}
@media print {
  .govuk-footer {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-footer {
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.25;
  }
}
@media print {
  .govuk-footer {
    font-size: 14pt;
    line-height: 1.2;
  }
}
@media (min-width: 40.0625em) {
  .govuk-footer {
    padding-top: 40px;
  }
}
@media (min-width: 40.0625em) {
  .govuk-footer {
    padding-bottom: 25px;
  }
}

.govuk-footer__link {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: underline;
}
@media print {
  .govuk-footer__link {
    font-family: sans-serif;
  }
}
.govuk-footer__link:focus {
  outline: 3px solid transparent;
  color: #0b0c0c;
  background-color: #ffdd00;
  box-shadow: 0 -2px #ffdd00, 0 4px #0b0c0c;
  text-decoration: none;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}
.govuk-footer__link:link, .govuk-footer__link:visited {
  color: #0b0c0c;
}
@media print {
  .govuk-footer__link:link, .govuk-footer__link:visited {
    color: #000000;
  }
}
.govuk-footer__link:hover {
  color: rgba(11, 12, 12, 0.99);
}
.govuk-footer__link:active, .govuk-footer__link:focus {
  color: #0b0c0c;
}
@media print {
  .govuk-footer__link:active, .govuk-footer__link:focus {
    color: #000000;
  }
}

.govuk-footer__section-break {
  margin: 0;
  margin-bottom: 30px;
  border: 0;
  border-bottom: 1px solid #b1b4b6;
}
@media (min-width: 40.0625em) {
  .govuk-footer__section-break {
    margin-bottom: 50px;
  }
}

.govuk-footer__meta {
  display: -ms-flexbox;
  display: flex;
  margin-right: -15px;
  margin-left: -15px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: end;
  align-items: flex-end;
  -ms-flex-pack: center;
  justify-content: center;
}

.govuk-footer__meta-item {
  margin-right: 15px;
  margin-bottom: 25px;
  margin-left: 15px;
}

.govuk-footer__meta-item--grow {
  -ms-flex: 1;
  flex: 1;
}
@media (max-width: 40.0525em) {
  .govuk-footer__meta-item--grow {
    -ms-flex-preferred-size: 320px;
    flex-basis: 320px;
  }
}

.govuk-footer__licence-logo {
  display: inline-block;
  margin-right: 10px;
  vertical-align: top;
  forced-color-adjust: auto;
}
@media (max-width: 48.0525em) {
  .govuk-footer__licence-logo {
    margin-bottom: 15px;
  }
}

.govuk-footer__licence-description {
  display: inline-block;
}

.govuk-footer__copyright-logo {
  display: inline-block;
  min-width: 125px;
  padding-top: 112px;
  background-image: url("/assets/images/govuk-crest.png");
  background-repeat: no-repeat;
  background-position: 50% 0%;
  background-size: 125px 102px;
  text-align: center;
  white-space: nowrap;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .govuk-footer__copyright-logo {
    background-image: url("/assets/images/govuk-crest-2x.png");
  }
}

.govuk-footer__inline-list {
  margin-top: 0;
  margin-bottom: 15px;
  padding: 0;
}

.govuk-footer__meta-custom {
  margin-bottom: 20px;
}

.govuk-footer__inline-list-item {
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 5px;
}

.govuk-footer__heading {
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #b1b4b6;
}
@media (max-width: 40.0525em) {
  .govuk-footer__heading {
    padding-bottom: 10px;
  }
}

.govuk-footer__navigation {
  margin-right: -15px;
  margin-left: -15px;
}
.govuk-footer__navigation:after {
  content: "";
  display: block;
  clear: both;
}

.govuk-footer__section {
  display: inline-block;
  margin-bottom: 30px;
  vertical-align: top;
}

.govuk-footer__list {
  margin: 0;
  padding: 0;
  list-style: none;
  -webkit-column-gap: 30px;
  column-gap: 30px;
}

@media (min-width: 48.0625em) {
  .govuk-footer__list--columns-2 {
    -webkit-column-count: 2;
    column-count: 2;
  }
  .govuk-footer__list--columns-3 {
    -webkit-column-count: 3;
    column-count: 3;
  }
}
.govuk-footer__list-item {
  margin-bottom: 15px;
}
@media (min-width: 40.0625em) {
  .govuk-footer__list-item {
    margin-bottom: 20px;
  }
}

.govuk-footer__list-item:last-child {
  margin-bottom: 0;
}


.govuk-header {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.1428571429;
  border-bottom: 10px solid #ffffff;
  color: #ffffff;
  background: #0b0c0c;
}
@media print {
  .govuk-header {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-header {
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.25;
  }
}
@media print {
  .govuk-header {
    font-size: 14pt;
    line-height: 1.2;
  }
}

.govuk-header__container--full-width {
  padding: 0 15px;
  border-color: #1d70b8;
}
.govuk-header__container--full-width .govuk-header__menu-button {
  right: 15px;
}

.govuk-header__container {
  position: relative;
  margin-bottom: -10px;
  padding-top: 10px;
  border-bottom: 10px solid #1d70b8;
}
.govuk-header__container:after {
  content: "";
  display: block;
  clear: both;
}

.govuk-header__logotype {
  display: inline-block;
  margin-right: 5px;
}
@media (forced-colors: active) {
  .govuk-header__logotype {
    forced-color-adjust: none;
    color: linktext;
  }
}
.govuk-header__logotype:last-child {
  margin-right: 0;
}

.govuk-header__logotype-crown {
  position: relative;
  top: -1px;
  margin-right: 1px;
  fill: currentcolor;
  vertical-align: top;
}

.govuk-header__logotype-crown[width="32"] {
  top: -3px;
  margin-right: 2px;
}

.govuk-header__logotype-crown-fallback-image {
  width: 36px;
  height: 32px;
  border: 0;
  vertical-align: bottom;
}

.govuk-header__logotype-crown-fallback-image[width="32"] {
  width: 32px;
  height: 30px;
}

.govuk-header__product-name {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1;
  display: inline-table;
}
@media print {
  .govuk-header__product-name {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-header__product-name {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1;
  }
}
@media print {
  .govuk-header__product-name {
    font-size: 18pt;
    line-height: 1;
  }
}

.govuk-header__link {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: none;
}
@media print {
  .govuk-header__link {
    font-family: sans-serif;
  }
}
.govuk-header__link:link, .govuk-header__link:visited {
  color: #ffffff;
}
.govuk-header__link:hover, .govuk-header__link:active {
  color: rgba(255, 255, 255, 0.99);
}
.govuk-header__link:focus {
  color: #0b0c0c;
}
.govuk-header__link:hover {
  text-decoration: underline;
  text-decoration-thickness: 3px;
  text-underline-offset: 0.1578em;
}
.govuk-header__link:focus {
  outline: 3px solid transparent;
  color: #0b0c0c;
  background-color: #ffdd00;
  box-shadow: 0 -2px #ffdd00, 0 4px #0b0c0c;
  text-decoration: none;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}

.govuk-header__link--homepage {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  display: inline-block;
  margin-right: 10px;
  font-size: 30px;
  line-height: 1;
}
@media print {
  .govuk-header__link--homepage {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-header__link--homepage {
    display: inline;
  }
  .govuk-header__link--homepage:focus {
    box-shadow: 0 0 #ffdd00;
  }
}
.govuk-header__link--homepage:link, .govuk-header__link--homepage:visited {
  text-decoration: none;
}
.govuk-header__link--homepage:hover, .govuk-header__link--homepage:active {
  margin-bottom: -3px;
  border-bottom: 3px solid;
}
.govuk-header__link--homepage:focus {
  margin-bottom: 0;
  border-bottom: 0;
}

.govuk-header__service-name,
.govuk-header__link--service-name {
  display: inline-block;
  margin-bottom: 10px;
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.1111111111;
}
@media print {
  .govuk-header__service-name,
  .govuk-header__link--service-name {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-header__service-name,
  .govuk-header__link--service-name {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.25;
  }
}
@media print {
  .govuk-header__service-name,
  .govuk-header__link--service-name {
    font-size: 18pt;
    line-height: 1.15;
  }
}

.govuk-header__logo,
.govuk-header__content {
  box-sizing: border-box;
}

.govuk-header__logo {
  margin-bottom: 10px;
  padding-right: 50px;
}
@media (min-width: 48.0625em) {
  .govuk-header__logo {
    width: 33.33%;
    padding-right: 15px;
    float: left;
    vertical-align: top;
  }
}

@media (min-width: 48.0625em) {
  .govuk-header__content {
    width: 66.66%;
    padding-left: 15px;
    float: left;
  }
}

.govuk-header__menu-button {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.1428571429;
  display: none;
  position: absolute;
  top: 20px;
  right: 0;
  margin: 0;
  padding: 0;
  border: 0;
  color: #ffffff;
  background: none;
  cursor: pointer;
}
@media print {
  .govuk-header__menu-button {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-header__menu-button {
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.25;
  }
}
@media print {
  .govuk-header__menu-button {
    font-size: 14pt;
    line-height: 1.2;
  }
}
.govuk-header__menu-button:hover {
  -webkit-text-decoration: solid underline 3px;
  text-decoration: solid underline 3px;
  text-underline-offset: 0.1578em;
}
.govuk-header__menu-button:focus {
  outline: 3px solid transparent;
  color: #0b0c0c;
  background-color: #ffdd00;
  box-shadow: 0 -2px #ffdd00, 0 4px #0b0c0c;
  text-decoration: none;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}
.govuk-header__menu-button:after {
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent;
  -webkit-clip-path: polygon(0% 0%, 50% 100%, 100% 0%);
  clip-path: polygon(0% 0%, 50% 100%, 100% 0%);
  border-width: 8.66px 5px 0 5px;
  border-top-color: inherit;
  content: "";
  margin-left: 5px;
}
.govuk-header__menu-button[aria-expanded=true]:after {
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent;
  -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  border-width: 0 5px 8.66px 5px;
  border-bottom-color: inherit;
}
@media (min-width: 40.0625em) {
  .govuk-header__menu-button {
    top: 15px;
  }
}
.js-enabled .govuk-header__menu-button {
  display: block;
}
.govuk-header__menu-button[hidden], .js-enabled .govuk-header__menu-button[hidden] {
  display: none;
}

@media (min-width: 48.0625em) {
  .govuk-header__navigation {
    margin-bottom: 10px;
  }
}

.govuk-header__navigation-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.govuk-header__navigation-list[hidden] {
  display: none;
}

@media (min-width: 48.0625em) {
  .govuk-header__navigation--end {
    margin: 0;
    padding: 5px 0;
    text-align: right;
  }
}

.govuk-header__navigation--no-service-name {
  padding-top: 40px;
}

.govuk-header__navigation-item {
  padding: 10px 0;
  border-bottom: 1px solid #2e3133;
}
@media (min-width: 48.0625em) {
  .govuk-header__navigation-item {
    display: inline-block;
    margin-right: 15px;
    padding: 5px 0;
    border: 0;
  }
}
.govuk-header__navigation-item a {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.1428571429;
  white-space: nowrap;
}
@media print {
  .govuk-header__navigation-item a {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-header__navigation-item a {
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.25;
  }
}
@media print {
  .govuk-header__navigation-item a {
    font-size: 14pt;
    line-height: 1.2;
  }
}

.govuk-header__navigation-item--active a:link, .govuk-header__navigation-item--active a:hover, .govuk-header__navigation-item--active a:visited {
  color: #1d8feb;
}
@media print {
  .govuk-header__navigation-item--active a {
    color: #1d70b8;
  }
}
.govuk-header__navigation-item--active a:focus {
  color: #0b0c0c;
}

.govuk-header__navigation-item:last-child {
  margin-right: 0;
  border-bottom: 0;
}

@media print {
  .govuk-header {
    border-bottom-width: 0;
    color: #0b0c0c;
    background: transparent;
  }
  .govuk-header__logotype-crown-fallback-image {
    display: none;
  }
  .govuk-header__link:link, .govuk-header__link:visited {
    color: #0b0c0c;
  }
  .govuk-header__link:after {
    display: none;
  }
}






.govuk-inset-text {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.25;
  color: #0b0c0c;
  padding: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
  clear: both;
  border-left: 10px solid #b1b4b6;
}
@media print {
  .govuk-inset-text {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-inset-text {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.3157894737;
  }
}
@media print {
  .govuk-inset-text {
    font-size: 14pt;
    line-height: 1.15;
  }
}
@media print {
  .govuk-inset-text {
    color: #000000;
  }
}
@media (min-width: 40.0625em) {
  .govuk-inset-text {
    margin-top: 30px;
  }
}
@media (min-width: 40.0625em) {
  .govuk-inset-text {
    margin-bottom: 30px;
  }
}
.govuk-inset-text > :first-child {
  margin-top: 0;
}
.govuk-inset-text > :only-child,
.govuk-inset-text > :last-child {
  margin-bottom: 0;
}



.govuk-notification-banner {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.25;
  margin-bottom: 30px;
  border: 5px solid #1d70b8;
  background-color: #1d70b8;
}
@media print {
  .govuk-notification-banner {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-notification-banner {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.3157894737;
  }
}
@media print {
  .govuk-notification-banner {
    font-size: 14pt;
    line-height: 1.15;
  }
}
@media (min-width: 40.0625em) {
  .govuk-notification-banner {
    margin-bottom: 50px;
  }
}
.govuk-notification-banner:focus {
  outline: 3px solid #ffdd00;
}

.govuk-notification-banner__header {
  padding: 2px 15px 5px;
  border-bottom: 1px solid transparent;
}
@media (min-width: 40.0625em) {
  .govuk-notification-banner__header {
    padding: 2px 20px 5px;
  }
}

.govuk-notification-banner__title {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.25;
  margin: 0;
  padding: 0;
  color: #ffffff;
}
@media print {
  .govuk-notification-banner__title {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-notification-banner__title {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.3157894737;
  }
}
@media print {
  .govuk-notification-banner__title {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.govuk-notification-banner__content {
  color: #0b0c0c;
  padding: 15px;
  background-color: #ffffff;
}
@media print {
  .govuk-notification-banner__content {
    color: #000000;
  }
}
@media (min-width: 40.0625em) {
  .govuk-notification-banner__content {
    padding: 20px;
  }
}
.govuk-notification-banner__content > * {
  box-sizing: border-box;
  max-width: 605px;
}
.govuk-notification-banner__content > :last-child {
  margin-bottom: 0;
}

.govuk-notification-banner__heading {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.1111111111;
  margin: 0 0 15px 0;
  padding: 0;
}
@media print {
  .govuk-notification-banner__heading {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-notification-banner__heading {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.25;
  }
}
@media print {
  .govuk-notification-banner__heading {
    font-size: 18pt;
    line-height: 1.15;
  }
}

.govuk-notification-banner__link {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: underline;
}
@media print {
  .govuk-notification-banner__link {
    font-family: sans-serif;
  }
}
.govuk-notification-banner__link:focus {
  outline: 3px solid transparent;
  color: #0b0c0c;
  background-color: #ffdd00;
  box-shadow: 0 -2px #ffdd00, 0 4px #0b0c0c;
  text-decoration: none;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}
.govuk-notification-banner__link:link {
  color: #1d70b8;
}
.govuk-notification-banner__link:visited {
  color: #1d70b8;
}
.govuk-notification-banner__link:hover {
  color: #003078;
}
.govuk-notification-banner__link:active {
  color: #0b0c0c;
}
.govuk-notification-banner__link:focus {
  color: #0b0c0c;
}

.govuk-notification-banner--success {
  border-color: #00703c;
  background-color: #00703c;
}
.govuk-notification-banner--success .govuk-notification-banner__link:link, .govuk-notification-banner--success .govuk-notification-banner__link:visited {
  color: #00703c;
}
.govuk-notification-banner--success .govuk-notification-banner__link:hover {
  color: #004e2a;
}
.govuk-notification-banner--success .govuk-notification-banner__link:active {
  color: #00703c;
}
.govuk-notification-banner--success .govuk-notification-banner__link:focus {
  color: #0b0c0c;
}


.govuk-pagination {
  margin-bottom: 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
@media (min-width: 40.0625em) {
  .govuk-pagination {
    margin-bottom: 30px;
  }
}
@media (min-width: 40.0625em) {
  .govuk-pagination {
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}

.govuk-pagination__list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.govuk-pagination__item,
.govuk-pagination__next,
.govuk-pagination__prev {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.25;
  box-sizing: border-box;
  position: relative;
  min-width: 45px;
  min-height: 45px;
  padding: 10px 15px;
  float: left;
}
@media print {
  .govuk-pagination__item,
  .govuk-pagination__next,
  .govuk-pagination__prev {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-pagination__item,
  .govuk-pagination__next,
  .govuk-pagination__prev {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.3157894737;
  }
}
@media print {
  .govuk-pagination__item,
  .govuk-pagination__next,
  .govuk-pagination__prev {
    font-size: 14pt;
    line-height: 1.15;
  }
}
.govuk-pagination__item:hover,
.govuk-pagination__next:hover,
.govuk-pagination__prev:hover {
  background-color: #f3f2f1;
}

.govuk-pagination__item {
  display: none;
  text-align: center;
}
@media (min-width: 40.0625em) {
  .govuk-pagination__item {
    display: block;
  }
}

.govuk-pagination__prev,
.govuk-pagination__next {
  font-weight: 700;
}
.govuk-pagination__prev .govuk-pagination__link,
.govuk-pagination__next .govuk-pagination__link {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.govuk-pagination__prev {
  padding-left: 0;
}

.govuk-pagination__next {
  padding-right: 0;
}

.govuk-pagination__item--current,
.govuk-pagination__item--ellipses,
.govuk-pagination__item:first-child,
.govuk-pagination__item:last-child {
  display: block;
}

.govuk-pagination__item--current {
  font-weight: 700;
  outline: 1px solid transparent;
  background-color: #1d70b8;
}
.govuk-pagination__item--current:hover {
  background-color: #1d70b8;
}
.govuk-pagination__item--current .govuk-pagination__link:link, .govuk-pagination__item--current .govuk-pagination__link:visited {
  color: #ffffff;
}
.govuk-pagination__item--current .govuk-pagination__link:hover, .govuk-pagination__item--current .govuk-pagination__link:active {
  color: rgba(255, 255, 255, 0.99);
}
.govuk-pagination__item--current .govuk-pagination__link:focus {
  color: #0b0c0c;
}

.govuk-pagination__item--ellipses {
  font-weight: 700;
  color: #505a5f;
}
.govuk-pagination__item--ellipses:hover {
  background-color: transparent;
}

.govuk-pagination__link {
  display: block;
  min-width: 15px;
}
@media screen {
  .govuk-pagination__link:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
.govuk-pagination__link:hover .govuk-pagination__link-title--decorated, .govuk-pagination__link:active .govuk-pagination__link-title--decorated {
  text-decoration: underline;
}
.govuk-pagination__link:focus .govuk-pagination__icon {
  color: #0b0c0c;
}
.govuk-pagination__link:focus .govuk-pagination__link-label {
  text-decoration: none;
}
.govuk-pagination__link:focus .govuk-pagination__link-title--decorated {
  text-decoration: none;
}

.govuk-pagination__link-label {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.25;
  text-decoration: underline;
  display: inline-block;
  padding-left: 30px;
}
@media print {
  .govuk-pagination__link-label {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-pagination__link-label {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.3157894737;
  }
}
@media print {
  .govuk-pagination__link-label {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.govuk-pagination__icon {
  width: 0.9375rem;
  height: 0.8125rem;
  color: #505a5f;
  fill: currentcolor;
  forced-color-adjust: auto;
}

.govuk-pagination__icon--prev {
  margin-right: 15px;
}

.govuk-pagination__icon--next {
  margin-left: 15px;
}

.govuk-pagination--block {
  display: block;
}
.govuk-pagination--block .govuk-pagination__item {
  padding: 15px;
  float: none;
}
.govuk-pagination--block .govuk-pagination__next,
.govuk-pagination--block .govuk-pagination__prev {
  padding-left: 0;
  float: none;
}
.govuk-pagination--block .govuk-pagination__next {
  padding-right: 15px;
}
.govuk-pagination--block .govuk-pagination__next .govuk-pagination__icon {
  margin-left: 0;
}
.govuk-pagination--block .govuk-pagination__prev + .govuk-pagination__next {
  border-top: 1px solid #b1b4b6;
}
.govuk-pagination--block .govuk-pagination__link,
.govuk-pagination--block .govuk-pagination__link-title {
  display: inline;
}
.govuk-pagination--block .govuk-pagination__link-title:after {
  content: "";
  display: block;
}
.govuk-pagination--block .govuk-pagination__link {
  text-align: left;
}
.govuk-pagination--block .govuk-pagination__link:focus .govuk-pagination__link-label {
  outline: 3px solid transparent;
  color: #0b0c0c;
  background-color: #ffdd00;
  box-shadow: 0 -2px #ffdd00, 0 4px #0b0c0c;
  text-decoration: none;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}
.govuk-pagination--block .govuk-pagination__link:not(:focus) {
  text-decoration: none;
}
.govuk-pagination--block .govuk-pagination__icon {
  margin-right: 10px;
}


.govuk-panel {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.25;
  box-sizing: border-box;
  margin-bottom: 15px;
  padding: 35px;
  border: 5px solid transparent;
  text-align: center;
}
@media print {
  .govuk-panel {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-panel {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.3157894737;
  }
}
@media print {
  .govuk-panel {
    font-size: 14pt;
    line-height: 1.15;
  }
}
@media (max-width: 40.0525em) {
  .govuk-panel {
    padding: 10px;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }
}

.govuk-panel--confirmation {
  color: #ffffff;
  background: #00703c;
}
@media print {
  .govuk-panel--confirmation {
    border-color: currentcolor;
    color: #000000;
    background: none;
  }
}

.govuk-panel__title {
  margin-top: 0;
  margin-bottom: 30px;
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  font-size: 32px;
  font-size: 2rem;
  line-height: 1.09375;
}
@media print {
  .govuk-panel__title {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-panel__title {
    font-size: 48px;
    font-size: 3rem;
    line-height: 1.0416666667;
  }
}
@media print {
  .govuk-panel__title {
    font-size: 32pt;
    line-height: 1.15;
  }
}

.govuk-panel__title:last-child {
  margin-bottom: 0;
}

.govuk-panel__body {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.0416666667;
}
@media print {
  .govuk-panel__body {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-panel__body {
    font-size: 36px;
    font-size: 2.25rem;
    line-height: 1.1111111111;
  }
}
@media print {
  .govuk-panel__body {
    font-size: 24pt;
    line-height: 1.05;
  }
}


.govuk-tag {
  display: inline-block;
  outline: 2px solid transparent;
  outline-offset: -2px;
  color: #ffffff;
  background-color: #1d70b8;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1;
  padding-top: 5px;
  padding-right: 8px;
  padding-bottom: 4px;
  padding-left: 8px;
}
@media print {
  .govuk-tag {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-tag {
    font-size: 16px;
    font-size: 1rem;
    line-height: 1;
  }
}
@media print {
  .govuk-tag {
    font-size: 14pt;
    line-height: 1;
  }
}

.govuk-tag--grey {
  color: #383f43;
  background: #eeefef;
}

.govuk-tag--purple {
  color: #3d2375;
  background: #dbd5e9;
}

.govuk-tag--turquoise {
  color: #10403c;
  background: #bfe3e0;
}

.govuk-tag--blue {
  color: #144e81;
  background: #d2e2f1;
}

.govuk-tag--yellow {
  color: #594d00;
  background: #fff7bf;
}

.govuk-tag--orange {
  color: #6e3619;
  background: #fcd6c3;
}

.govuk-tag--red {
  color: #942514;
  background: #f6d7d2;
}

.govuk-tag--pink {
  color: #80224d;
  background: #f7d7e6;
}

.govuk-tag--green {
  color: #005a30;
  background: #cce2d8;
}


.govuk-phase-banner {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #b1b4b6;
}

.govuk-phase-banner__content {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.1428571429;
  color: #0b0c0c;
  display: table;
  margin: 0;
}
@media print {
  .govuk-phase-banner__content {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-phase-banner__content {
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.25;
  }
}
@media print {
  .govuk-phase-banner__content {
    font-size: 14pt;
    line-height: 1.2;
  }
}
@media print {
  .govuk-phase-banner__content {
    color: #000000;
  }
}

.govuk-phase-banner__content__tag {
  margin-right: 10px;
}

.govuk-phase-banner__text {
  display: table-cell;
  vertical-align: middle;
}


.govuk-tabs {
  margin-top: 5px;
  margin-bottom: 20px;
}
@media (min-width: 40.0625em) {
  .govuk-tabs {
    margin-bottom: 30px;
  }
}

.govuk-tabs__title {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.25;
  color: #0b0c0c;
  margin-bottom: 10px;
}
@media print {
  .govuk-tabs__title {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-tabs__title {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.3157894737;
  }
}
@media print {
  .govuk-tabs__title {
    font-size: 14pt;
    line-height: 1.15;
  }
}
@media print {
  .govuk-tabs__title {
    color: #000000;
  }
}

.govuk-tabs__list {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 20px;
}
@media (min-width: 40.0625em) {
  .govuk-tabs__list {
    margin-bottom: 30px;
  }
}

.govuk-tabs__list-item, .govuk-subsidiary-tabs__list-item, .govuk-standalone-tabs__list-item {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.25;
  margin-left: 25px;
}
@media print {
  .govuk-tabs__list-item, .govuk-subsidiary-tabs__list-item, .govuk-standalone-tabs__list-item {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-tabs__list-item, .govuk-subsidiary-tabs__list-item, .govuk-standalone-tabs__list-item {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.3157894737;
  }
}
@media print {
  .govuk-tabs__list-item, .govuk-subsidiary-tabs__list-item, .govuk-standalone-tabs__list-item {
    font-size: 14pt;
    line-height: 1.15;
  }
}
.govuk-tabs__list-item:before, .govuk-subsidiary-tabs__list-item:before, .govuk-standalone-tabs__list-item:before {
  color: #0b0c0c;
  content: "—";
  margin-left: -25px;
  padding-right: 5px;
}
@media print {
  .govuk-tabs__list-item:before, .govuk-subsidiary-tabs__list-item:before, .govuk-standalone-tabs__list-item:before {
    color: #000000;
  }
}

.govuk-tabs__tab {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: underline;
  display: inline-block;
  margin-bottom: 10px;
}
@media print {
  .govuk-tabs__tab {
    font-family: sans-serif;
  }
}
.govuk-tabs__tab:focus {
  outline: 3px solid transparent;
  color: #0b0c0c;
  background-color: #ffdd00;
  box-shadow: 0 -2px #ffdd00, 0 4px #0b0c0c;
  text-decoration: none;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}
.govuk-tabs__tab:link {
  color: #1d70b8;
}
.govuk-tabs__tab:visited {
  color: #4c2c92;
}
.govuk-tabs__tab:hover {
  color: #003078;
}
.govuk-tabs__tab:active {
  color: #0b0c0c;
}
.govuk-tabs__tab:focus {
  color: #0b0c0c;
}

.govuk-tabs__panel {
  margin-bottom: 30px;
}
@media (min-width: 40.0625em) {
  .govuk-tabs__panel {
    margin-bottom: 50px;
  }
}

@media (min-width: 40.0625em) {
  .js-enabled .govuk-tabs__list {
    margin-bottom: 0;
    border-bottom: 1px solid #b1b4b6;
  }
  .js-enabled .govuk-tabs__list:after {
    content: "";
    display: block;
    clear: both;
  }
  .js-enabled .govuk-tabs__title {
    display: none;
  }
  .js-enabled .govuk-tabs__list-item, .js-enabled .govuk-subsidiary-tabs__list-item, .js-enabled .govuk-standalone-tabs__list-item {
    position: relative;
    margin-right: 5px;
    margin-bottom: 0;
    margin-left: 0;
    padding: 10px 20px;
    float: left;
    background-color: #f3f2f1;
    text-align: center;
  }
  .js-enabled .govuk-tabs__list-item:before, .js-enabled .govuk-subsidiary-tabs__list-item:before, .js-enabled .govuk-standalone-tabs__list-item:before {
    content: none;
  }
  .js-enabled .govuk-tabs__list-item--selected {
    position: relative;
    margin-top: -5px;
    margin-bottom: -1px;
    padding-top: 14px;
    padding-right: 19px;
    padding-bottom: 16px;
    padding-left: 19px;
    border: 1px solid #b1b4b6;
    border-bottom: 0;
    background-color: #ffffff;
  }
  .js-enabled .govuk-tabs__list-item--selected .govuk-tabs__tab {
    text-decoration: none;
  }
  .js-enabled .govuk-tabs__tab {
    margin-bottom: 0;
  }
  .js-enabled .govuk-tabs__tab:link, .js-enabled .govuk-tabs__tab:visited {
    color: #0b0c0c;
  }
}
@media print and (min-width: 40.0625em) {
  .js-enabled .govuk-tabs__tab:link, .js-enabled .govuk-tabs__tab:visited {
    color: #000000;
  }
}
@media (min-width: 40.0625em) {
  .js-enabled .govuk-tabs__tab:hover {
    color: rgba(11, 12, 12, 0.99);
  }
}
@media (min-width: 40.0625em) {
  .js-enabled .govuk-tabs__tab:active, .js-enabled .govuk-tabs__tab:focus {
    color: #0b0c0c;
  }
}
@media print and (min-width: 40.0625em) {
  .js-enabled .govuk-tabs__tab:active, .js-enabled .govuk-tabs__tab:focus {
    color: #000000;
  }
}
@media (min-width: 40.0625em) {
  .js-enabled .govuk-tabs__tab:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
@media (min-width: 40.0625em) {
  .js-enabled .govuk-tabs__panel {
    margin-bottom: 0;
    padding: 30px 20px;
    border: 1px solid #b1b4b6;
    border-top: 0;
  }
  .js-enabled .govuk-tabs__panel > :last-child {
    margin-bottom: 0;
  }
}
@media (min-width: 40.0625em) {
  .js-enabled .govuk-tabs__panel--hidden {
    display: none;
  }
}







.govuk-radios__item {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.25;
  display: block;
  position: relative;
  min-height: 40px;
  margin-bottom: 10px;
  padding-left: 40px;
  clear: left;
}
@media print {
  .govuk-radios__item {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-radios__item {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.3157894737;
  }
}
@media print {
  .govuk-radios__item {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.govuk-radios__item:last-child,
.govuk-radios__item:last-of-type {
  margin-bottom: 0;
}

.govuk-radios__input {
  cursor: pointer;
  position: absolute;
  z-index: 1;
  top: -2px;
  left: -2px;
  width: 44px;
  height: 44px;
  margin: 0;
  opacity: 0;
}

.govuk-radios__label {
  display: inline-block;
  margin-bottom: 0;
  padding: 8px 15px 5px;
  cursor: pointer;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

.govuk-radios__label:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border: 2px solid currentcolor;
  border-radius: 50%;
  background: transparent;
}

.govuk-radios__label:after {
  content: "";
  position: absolute;
  top: 10px;
  left: 10px;
  width: 0;
  height: 0;
  border: 10px solid currentcolor;
  border-radius: 50%;
  opacity: 0;
  background: currentcolor;
}

.govuk-radios__hint {
  display: block;
  padding-right: 15px;
  padding-left: 15px;
}

.govuk-radios__input:focus + .govuk-radios__label:before {
  border-width: 4px;
  outline: 3px solid transparent;
  outline-offset: 1px;
  box-shadow: 0 0 0 4px #ffdd00;
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .govuk-radios__input:focus + .govuk-radios__label:before {
    outline-color: Highlight;
  }
}

.govuk-radios__input:checked + .govuk-radios__label:after {
  opacity: 1;
}

.govuk-radios__input:disabled,
.govuk-radios__input:disabled + .govuk-radios__label {
  cursor: not-allowed;
}

.govuk-radios__input:disabled + .govuk-radios__label,
.govuk-radios__input:disabled ~ .govuk-hint {
  opacity: 0.5;
}

@media (min-width: 40.0625em) {
  .govuk-radios--inline:after {
    content: "";
    display: block;
    clear: both;
  }
  .govuk-radios--inline .govuk-radios__item {
    margin-right: 20px;
    float: left;
    clear: none;
  }
}

.govuk-radios__divider {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.25;
  color: #0b0c0c;
  width: 40px;
  margin-bottom: 10px;
  text-align: center;
}
@media print {
  .govuk-radios__divider {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-radios__divider {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.3157894737;
  }
}
@media print {
  .govuk-radios__divider {
    font-size: 14pt;
    line-height: 1.15;
  }
}
@media print {
  .govuk-radios__divider {
    color: #000000;
  }
}

.govuk-radios__conditional {
  margin-bottom: 15px;
  margin-left: 18px;
  padding-left: 33px;
  border-left: 4px solid #b1b4b6;
}
@media (min-width: 40.0625em) {
  .govuk-radios__conditional {
    margin-bottom: 20px;
  }
}
.js-enabled .govuk-radios__conditional--hidden {
  display: none;
}
.govuk-radios__conditional > :last-child {
  margin-bottom: 0;
}

.govuk-radios--small .govuk-radios__item {
  min-height: 0;
  margin-bottom: 0;
  padding-left: 34px;
  float: left;
}
.govuk-radios--small .govuk-radios__item:after {
  content: "";
  display: block;
  clear: both;
}
.govuk-radios--small .govuk-radios__input {
  left: -10px;
}
.govuk-radios--small .govuk-radios__label {
  margin-top: -2px;
  padding: 13px 15px 13px 1px;
  float: left;
}
@media (min-width: 40.0625em) {
  .govuk-radios--small .govuk-radios__label {
    padding: 11px 15px 10px 1px;
  }
}
.govuk-radios--small .govuk-radios__label:before {
  top: 8px;
  width: 24px;
  height: 24px;
}
.govuk-radios--small .govuk-radios__label:after {
  top: 15px;
  left: 7px;
  border-width: 5px;
}
.govuk-radios--small .govuk-radios__hint {
  padding: 0;
  clear: both;
  pointer-events: none;
}
.govuk-radios--small .govuk-radios__conditional {
  margin-left: 10px;
  padding-left: 20px;
  clear: both;
}
.govuk-radios--small .govuk-radios__divider {
  width: 24px;
  margin-bottom: 5px;
}
.govuk-radios--small .govuk-radios__item:hover .govuk-radios__input:not(:disabled) + .govuk-radios__label:before {
  box-shadow: 0 0 0 10px #b1b4b6;
}
.govuk-radios--small .govuk-radios__item:hover .govuk-radios__input:focus + .govuk-radios__label:before {
  box-shadow: 0 0 0 4px #ffdd00, 0 0 0 10px #b1b4b6;
}
@media (hover: none), (pointer: coarse) {
  .govuk-radios--small .govuk-radios__item:hover .govuk-radios__input:not(:disabled) + .govuk-radios__label:before {
    box-shadow: initial;
  }
  .govuk-radios--small .govuk-radios__item:hover .govuk-radios__input:focus + .govuk-radios__label:before {
    box-shadow: 0 0 0 4px #ffdd00;
  }
}





.govuk-select {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.25;
  box-sizing: border-box;
  min-width: 11.5em;
  max-width: 100%;
  height: 40px;
  height: 2.5rem;
  padding: 5px;
  border: 2px solid #0b0c0c;
  color: #0b0c0c;
  background-color: #ffffff;
}
@media print {
  .govuk-select {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-select {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.25;
  }
}
@media print {
  .govuk-select {
    font-size: 14pt;
    line-height: 1.25;
  }
}
.govuk-select:focus {
  outline: 3px solid #ffdd00;
  outline-offset: 0;
  box-shadow: inset 0 0 0 2px;
}
.govuk-select:disabled {
  opacity: 0.5;
  color: inherit;
  cursor: not-allowed;
}

.govuk-select option:active,
.govuk-select option:checked,
.govuk-select:focus::-ms-value {
  color: #ffffff;
  background-color: #1d70b8;
}

.govuk-select--error {
  border-color: #d4351c;
}
.govuk-select--error:focus {
  border-color: #0b0c0c;
}


.govuk-skip-link {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  margin: 0 !important;
  overflow: hidden !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: underline;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.1428571429;
  display: block;
  padding: 10px 15px;
}
.govuk-skip-link:active, .govuk-skip-link:focus {
  position: static !important;
  width: auto !important;
  height: auto !important;
  margin: inherit !important;
  overflow: visible !important;
  clip: auto !important;
  -webkit-clip-path: none !important;
  clip-path: none !important;
  white-space: inherit !important;
}
@media print {
  .govuk-skip-link {
    font-family: sans-serif;
  }
}
.govuk-skip-link:link, .govuk-skip-link:visited {
  color: #0b0c0c;
}
@media print {
  .govuk-skip-link:link, .govuk-skip-link:visited {
    color: #000000;
  }
}
.govuk-skip-link:hover {
  color: rgba(11, 12, 12, 0.99);
}
.govuk-skip-link:active, .govuk-skip-link:focus {
  color: #0b0c0c;
}
@media print {
  .govuk-skip-link:active, .govuk-skip-link:focus {
    color: #000000;
  }
}
@media (min-width: 40.0625em) {
  .govuk-skip-link {
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.25;
  }
}
@media print {
  .govuk-skip-link {
    font-size: 14pt;
    line-height: 1.2;
  }
}
@supports (padding: max(calc(0px))) {
  .govuk-skip-link {
    padding-right: max(15px, calc(15px + env(safe-area-inset-right)));
    padding-left: max(15px, calc(15px + env(safe-area-inset-left)));
  }
}
.govuk-skip-link:focus {
  outline: 3px solid #ffdd00;
  outline-offset: 0;
  background-color: #ffdd00;
  box-shadow: none;
}

.govuk-skip-link-focused-element:focus {
  outline: none;
}


.govuk-summary-list {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.25;
  color: #0b0c0c;
  margin: 0;
  margin-bottom: 20px;
}
@media print {
  .govuk-summary-list {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-summary-list {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.3157894737;
  }
}
@media print {
  .govuk-summary-list {
    font-size: 14pt;
    line-height: 1.15;
  }
}
@media print {
  .govuk-summary-list {
    color: #000000;
  }
}
@media (min-width: 40.0625em) {
  .govuk-summary-list {
    display: table;
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
  }
}
@media (min-width: 40.0625em) {
  .govuk-summary-list {
    margin-bottom: 30px;
  }
}

.govuk-summary-list__row {
  border-bottom: 1px solid #b1b4b6;
}
@media (max-width: 40.0525em) {
  .govuk-summary-list__row {
    margin-bottom: 15px;
  }
}
@media (min-width: 40.0625em) {
  .govuk-summary-list__row {
    display: table-row;
  }
}

.govuk-summary-list__row:not(.govuk-summary-list__row--no-actions) > :last-child {
  padding-right: 0;
}

@media (min-width: 40.0625em) {
  .govuk-summary-list__row--no-actions:after {
    content: "";
    display: table-cell;
    width: 20%;
  }
}

.govuk-summary-list__key,
.govuk-summary-list__value,
.govuk-summary-list__actions {
  margin: 0;
}
@media (min-width: 40.0625em) {
  .govuk-summary-list__key,
  .govuk-summary-list__value,
  .govuk-summary-list__actions {
    display: table-cell;
    padding-top: 10px;
    padding-right: 20px;
    padding-bottom: 10px;
  }
}

.govuk-summary-list__actions {
  margin-bottom: 15px;
}
@media (min-width: 40.0625em) {
  .govuk-summary-list__actions {
    width: 20%;
    padding-right: 0;
    text-align: right;
  }
}

.govuk-summary-list__key,
.govuk-summary-list__value {
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.govuk-summary-list__key {
  margin-bottom: 5px;
  font-weight: 700;
}
@media (min-width: 40.0625em) {
  .govuk-summary-list__key {
    width: 30%;
  }
}

@media (max-width: 40.0525em) {
  .govuk-summary-list__value {
    margin-bottom: 15px;
  }
}

.govuk-summary-list__value > p {
  margin-bottom: 10px;
}

.govuk-summary-list__value > :last-child {
  margin-bottom: 0;
}

.govuk-summary-list__actions-list {
  width: 100%;
  margin: 0;
  padding: 0;
}

.govuk-summary-list__actions-list-item {
  display: inline-block;
}

@media (max-width: 40.0525em) {
  .govuk-summary-list__actions-list-item {
    margin-right: 10px;
    padding-right: 10px;
  }
  .govuk-summary-list__actions-list-item:not(:last-child) {
    border-right: 1px solid #b1b4b6;
  }
  .govuk-summary-list__actions-list-item:last-child {
    margin-right: 0;
    padding-right: 0;
    border: 0;
  }
}
@media (min-width: 40.0625em) {
  .govuk-summary-list__actions-list-item {
    margin-left: 10px;
    padding-left: 10px;
  }
  .govuk-summary-list__actions-list-item:not(:first-child) {
    border-left: 1px solid #b1b4b6;
  }
  .govuk-summary-list__actions-list-item:first-child {
    margin-left: 0;
    padding-left: 0;
    border: 0;
  }
}
.govuk-summary-list--no-border .govuk-summary-list__row {
  border: 0;
}
@media (min-width: 40.0625em) {
  .govuk-summary-list--no-border .govuk-summary-list__key,
  .govuk-summary-list--no-border .govuk-summary-list__value,
  .govuk-summary-list--no-border .govuk-summary-list__actions {
    padding-bottom: 11px;
  }
}

.govuk-summary-list__row--no-border {
  border: 0;
}
@media (min-width: 40.0625em) {
  .govuk-summary-list__row--no-border .govuk-summary-list__key,
  .govuk-summary-list__row--no-border .govuk-summary-list__value,
  .govuk-summary-list__row--no-border .govuk-summary-list__actions {
    padding-bottom: 11px;
  }
}

.govuk-summary-card {
  margin-bottom: 20px;
  border: 1px solid #b1b4b6;
}
@media (min-width: 40.0625em) {
  .govuk-summary-card {
    margin-bottom: 30px;
  }
}

.govuk-summary-card__title-wrapper {
  padding: 15px;
  border-bottom: 1px solid transparent;
  background-color: #f3f2f1;
}
@media (min-width: 40.0625em) {
  .govuk-summary-card__title-wrapper {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    padding: 15px 20px;
  }
}

.govuk-summary-card__title {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.25;
  margin: 5px 20px 10px 0;
}
@media print {
  .govuk-summary-card__title {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-summary-card__title {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.3157894737;
  }
}
@media print {
  .govuk-summary-card__title {
    font-size: 14pt;
    line-height: 1.15;
  }
}
@media (min-width: 40.0625em) {
  .govuk-summary-card__title {
    margin-bottom: 5px;
  }
}

.govuk-summary-card__actions {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.25;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  row-gap: 10px;
  margin: 5px 0;
  padding: 0;
  list-style: none;
}
@media print {
  .govuk-summary-card__actions {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-summary-card__actions {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.3157894737;
  }
}
@media print {
  .govuk-summary-card__actions {
    font-size: 14pt;
    line-height: 1.15;
  }
}
@media (min-width: 40.0625em) {
  .govuk-summary-card__actions {
    -ms-flex-pack: right;
    justify-content: right;
    text-align: right;
  }
}

.govuk-summary-card__action {
  display: inline;
  margin: 0 10px 0 0;
  padding-right: 10px;
  border-right: 1px solid #b1b4b6;
}
@media (min-width: 40.0625em) {
  .govuk-summary-card__action {
    margin-right: 0;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .govuk-summary-card__action {
    margin-bottom: 5px;
  }
}

.govuk-summary-card__action:last-child {
  margin: 0;
  padding-right: 0;
  border-right: none;
}
@media (min-width: 40.0625em) {
  .govuk-summary-card__action:last-child {
    padding-left: 10px;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .govuk-summary-card__action:last-child {
    margin-bottom: 0;
  }
}

.govuk-summary-card__content {
  padding: 15px 15px 0;
}
@media (min-width: 40.0625em) {
  .govuk-summary-card__content {
    padding: 15px 20px;
  }
}
.govuk-summary-card__content .govuk-summary-list {
  margin-bottom: 0;
}
.govuk-summary-card__content .govuk-summary-list__row:last-of-type {
  margin-bottom: 0;
  border-bottom: none;
}


.govuk-table, .asc-cms-content table {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.25;
  color: #0b0c0c;
  width: 100%;
  margin-bottom: 20px;
  border-spacing: 0;
  border-collapse: collapse;
}
@media print {
  .govuk-table, .asc-cms-content table {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-table, .asc-cms-content table {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.3157894737;
  }
}
@media print {
  .govuk-table, .asc-cms-content table {
    font-size: 14pt;
    line-height: 1.15;
  }
}
@media print {
  .govuk-table, .asc-cms-content table {
    color: #000000;
  }
}
@media (min-width: 40.0625em) {
  .govuk-table, .asc-cms-content table {
    margin-bottom: 30px;
  }
}

.govuk-table__header, .asc-cms-content table th {
  font-weight: 700;
}

.govuk-table__header, .asc-cms-content table th,
.govuk-table__cell,
.asc-cms-content table td {
  padding: 10px 20px 10px 0;
  border-bottom: 1px solid #b1b4b6;
  text-align: left;
  vertical-align: top;
}

.govuk-table__cell--numeric {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-feature-settings: "tnum" 1;
  font-feature-settings: "tnum" 1;
  font-weight: 400;
}
@media print {
  .govuk-table__cell--numeric {
    font-family: sans-serif;
  }
}
@supports (font-variant-numeric: tabular-nums) {
  .govuk-table__cell--numeric {
    -webkit-font-feature-settings: normal;
    font-feature-settings: normal;
    font-variant-numeric: tabular-nums;
  }
}

.govuk-table__header--numeric,
.govuk-table__cell--numeric {
  text-align: right;
}

.govuk-table__header:last-child, .asc-cms-content table th:last-child,
.govuk-table__cell:last-child,
.asc-cms-content table td:last-child {
  padding-right: 0;
}

.govuk-table__caption {
  font-weight: 700;
  display: table-caption;
  text-align: left;
}

.govuk-table__caption--xl {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  font-size: 32px;
  font-size: 2rem;
  line-height: 1.09375;
  margin-bottom: 15px;
}
@media print {
  .govuk-table__caption--xl {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-table__caption--xl {
    font-size: 48px;
    font-size: 3rem;
    line-height: 1.0416666667;
  }
}
@media print {
  .govuk-table__caption--xl {
    font-size: 32pt;
    line-height: 1.15;
  }
}

.govuk-table__caption--l {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.0416666667;
  margin-bottom: 15px;
}
@media print {
  .govuk-table__caption--l {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-table__caption--l {
    font-size: 36px;
    font-size: 2.25rem;
    line-height: 1.1111111111;
  }
}
@media print {
  .govuk-table__caption--l {
    font-size: 24pt;
    line-height: 1.05;
  }
}

.govuk-table__caption--m {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.1111111111;
  margin-bottom: 15px;
}
@media print {
  .govuk-table__caption--m {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-table__caption--m {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.25;
  }
}
@media print {
  .govuk-table__caption--m {
    font-size: 18pt;
    line-height: 1.15;
  }
}

.govuk-table__caption--s {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.25;
}
@media print {
  .govuk-table__caption--s {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-table__caption--s {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.3157894737;
  }
}
@media print {
  .govuk-table__caption--s {
    font-size: 14pt;
    line-height: 1.15;
  }
}






.govuk-warning-text {
  position: relative;
  margin-bottom: 20px;
  padding: 10px 0;
}
@media (min-width: 40.0625em) {
  .govuk-warning-text {
    margin-bottom: 30px;
  }
}

.govuk-warning-text__assistive {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  border: 0 !important;
  white-space: nowrap !important;
}
.govuk-warning-text__assistive:before {
  content: " ";
}
.govuk-warning-text__assistive:after {
  content: " ";
}

.govuk-warning-text__icon {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  box-sizing: border-box;
  display: inline-block;
  position: absolute;
  left: 0;
  min-width: 35px;
  min-height: 35px;
  margin-top: -7px;
  border: 3px solid #0b0c0c;
  border-radius: 50%;
  color: #ffffff;
  background: #0b0c0c;
  font-size: 30px;
  line-height: 29px;
  text-align: center;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  forced-color-adjust: none;
}
@media print {
  .govuk-warning-text__icon {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-warning-text__icon {
    margin-top: -5px;
  }
}
@media screen and (forced-colors: active) {
  .govuk-warning-text__icon {
    border-color: windowText;
    color: windowText;
    background: transparent;
  }
}

.govuk-warning-text__text {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.25;
  color: #0b0c0c;
  display: block;
  padding-left: 45px;
}
@media print {
  .govuk-warning-text__text {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-warning-text__text {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.3157894737;
  }
}
@media print {
  .govuk-warning-text__text {
    font-size: 14pt;
    line-height: 1.15;
  }
}
@media print {
  .govuk-warning-text__text {
    color: #000000;
  }
}



.govuk-clearfix:after {
  content: "";
  display: block;
  clear: both;
}


.govuk-visually-hidden {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  border: 0 !important;
  white-space: nowrap !important;
}
.govuk-visually-hidden:before {
  content: " ";
}
.govuk-visually-hidden:after {
  content: " ";
}

.govuk-visually-hidden-focusable {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  margin: 0 !important;
  overflow: hidden !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
}
.govuk-visually-hidden-focusable:active, .govuk-visually-hidden-focusable:focus {
  position: static !important;
  width: auto !important;
  height: auto !important;
  margin: inherit !important;
  overflow: visible !important;
  clip: auto !important;
  -webkit-clip-path: none !important;
  clip-path: none !important;
  white-space: inherit !important;
}



.govuk-\!-display-inline {
  display: inline !important;
}

.govuk-\!-display-inline-block {
  display: inline-block !important;
}

.govuk-\!-display-block {
  display: block !important;
}

.govuk-\!-display-none {
  display: none !important;
}

@media print {
  .govuk-\!-display-none-print {
    display: none !important;
  }
}

.govuk-\!-margin-0 {
  margin: 0 !important;
}

.govuk-\!-margin-top-0 {
  margin-top: 0 !important;
}

.govuk-\!-margin-right-0 {
  margin-right: 0 !important;
}

.govuk-\!-margin-bottom-0 {
  margin-bottom: 0 !important;
}

.govuk-\!-margin-left-0 {
  margin-left: 0 !important;
}

.govuk-\!-margin-1 {
  margin: 5px !important;
}

.govuk-\!-margin-top-1 {
  margin-top: 5px !important;
}

.govuk-\!-margin-right-1 {
  margin-right: 5px !important;
}

.govuk-\!-margin-bottom-1 {
  margin-bottom: 5px !important;
}

.govuk-\!-margin-left-1 {
  margin-left: 5px !important;
}

.govuk-\!-margin-2 {
  margin: 10px !important;
}

.govuk-\!-margin-top-2 {
  margin-top: 10px !important;
}

.govuk-\!-margin-right-2 {
  margin-right: 10px !important;
}

.govuk-\!-margin-bottom-2 {
  margin-bottom: 10px !important;
}

.govuk-\!-margin-left-2 {
  margin-left: 10px !important;
}

.govuk-\!-margin-3 {
  margin: 15px !important;
}

.govuk-\!-margin-top-3 {
  margin-top: 15px !important;
}

.govuk-\!-margin-right-3 {
  margin-right: 15px !important;
}

.govuk-\!-margin-bottom-3 {
  margin-bottom: 15px !important;
}

.govuk-\!-margin-left-3 {
  margin-left: 15px !important;
}

.govuk-\!-margin-4 {
  margin: 15px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-margin-4 {
    margin: 20px !important;
  }
}

.govuk-\!-margin-top-4 {
  margin-top: 15px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-margin-top-4 {
    margin-top: 20px !important;
  }
}

.govuk-\!-margin-right-4 {
  margin-right: 15px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-margin-right-4 {
    margin-right: 20px !important;
  }
}

.govuk-\!-margin-bottom-4 {
  margin-bottom: 15px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-margin-bottom-4 {
    margin-bottom: 20px !important;
  }
}

.govuk-\!-margin-left-4 {
  margin-left: 15px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-margin-left-4 {
    margin-left: 20px !important;
  }
}

.govuk-\!-margin-5 {
  margin: 15px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-margin-5 {
    margin: 25px !important;
  }
}

.govuk-\!-margin-top-5 {
  margin-top: 15px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-margin-top-5 {
    margin-top: 25px !important;
  }
}

.govuk-\!-margin-right-5 {
  margin-right: 15px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-margin-right-5 {
    margin-right: 25px !important;
  }
}

.govuk-\!-margin-bottom-5 {
  margin-bottom: 15px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-margin-bottom-5 {
    margin-bottom: 25px !important;
  }
}

.govuk-\!-margin-left-5 {
  margin-left: 15px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-margin-left-5 {
    margin-left: 25px !important;
  }
}

.govuk-\!-margin-6 {
  margin: 20px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-margin-6 {
    margin: 30px !important;
  }
}

.govuk-\!-margin-top-6 {
  margin-top: 20px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-margin-top-6 {
    margin-top: 30px !important;
  }
}

.govuk-\!-margin-right-6 {
  margin-right: 20px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-margin-right-6 {
    margin-right: 30px !important;
  }
}

.govuk-\!-margin-bottom-6 {
  margin-bottom: 20px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-margin-bottom-6 {
    margin-bottom: 30px !important;
  }
}

.govuk-\!-margin-left-6 {
  margin-left: 20px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-margin-left-6 {
    margin-left: 30px !important;
  }
}

.govuk-\!-margin-7 {
  margin: 25px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-margin-7 {
    margin: 40px !important;
  }
}

.govuk-\!-margin-top-7 {
  margin-top: 25px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-margin-top-7 {
    margin-top: 40px !important;
  }
}

.govuk-\!-margin-right-7 {
  margin-right: 25px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-margin-right-7 {
    margin-right: 40px !important;
  }
}

.govuk-\!-margin-bottom-7 {
  margin-bottom: 25px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-margin-bottom-7 {
    margin-bottom: 40px !important;
  }
}

.govuk-\!-margin-left-7 {
  margin-left: 25px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-margin-left-7 {
    margin-left: 40px !important;
  }
}

.govuk-\!-margin-8 {
  margin: 30px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-margin-8 {
    margin: 50px !important;
  }
}

.govuk-\!-margin-top-8 {
  margin-top: 30px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-margin-top-8 {
    margin-top: 50px !important;
  }
}

.govuk-\!-margin-right-8 {
  margin-right: 30px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-margin-right-8 {
    margin-right: 50px !important;
  }
}

.govuk-\!-margin-bottom-8 {
  margin-bottom: 30px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-margin-bottom-8 {
    margin-bottom: 50px !important;
  }
}

.govuk-\!-margin-left-8 {
  margin-left: 30px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-margin-left-8 {
    margin-left: 50px !important;
  }
}

.govuk-\!-margin-9 {
  margin: 40px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-margin-9 {
    margin: 60px !important;
  }
}

.govuk-\!-margin-top-9 {
  margin-top: 40px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-margin-top-9 {
    margin-top: 60px !important;
  }
}

.govuk-\!-margin-right-9 {
  margin-right: 40px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-margin-right-9 {
    margin-right: 60px !important;
  }
}

.govuk-\!-margin-bottom-9 {
  margin-bottom: 40px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-margin-bottom-9 {
    margin-bottom: 60px !important;
  }
}

.govuk-\!-margin-left-9 {
  margin-left: 40px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-margin-left-9 {
    margin-left: 60px !important;
  }
}

.govuk-\!-padding-0 {
  padding: 0 !important;
}

.govuk-\!-padding-top-0 {
  padding-top: 0 !important;
}

.govuk-\!-padding-right-0 {
  padding-right: 0 !important;
}

.govuk-\!-padding-bottom-0 {
  padding-bottom: 0 !important;
}

.govuk-\!-padding-left-0 {
  padding-left: 0 !important;
}

.govuk-\!-padding-1 {
  padding: 5px !important;
}

.govuk-\!-padding-top-1 {
  padding-top: 5px !important;
}

.govuk-\!-padding-right-1 {
  padding-right: 5px !important;
}

.govuk-\!-padding-bottom-1 {
  padding-bottom: 5px !important;
}

.govuk-\!-padding-left-1 {
  padding-left: 5px !important;
}

.govuk-\!-padding-2 {
  padding: 10px !important;
}

.govuk-\!-padding-top-2 {
  padding-top: 10px !important;
}

.govuk-\!-padding-right-2 {
  padding-right: 10px !important;
}

.govuk-\!-padding-bottom-2 {
  padding-bottom: 10px !important;
}

.govuk-\!-padding-left-2 {
  padding-left: 10px !important;
}

.govuk-\!-padding-3 {
  padding: 15px !important;
}

.govuk-\!-padding-top-3 {
  padding-top: 15px !important;
}

.govuk-\!-padding-right-3 {
  padding-right: 15px !important;
}

.govuk-\!-padding-bottom-3 {
  padding-bottom: 15px !important;
}

.govuk-\!-padding-left-3 {
  padding-left: 15px !important;
}

.govuk-\!-padding-4 {
  padding: 15px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-padding-4 {
    padding: 20px !important;
  }
}

.govuk-\!-padding-top-4 {
  padding-top: 15px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-padding-top-4 {
    padding-top: 20px !important;
  }
}

.govuk-\!-padding-right-4 {
  padding-right: 15px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-padding-right-4 {
    padding-right: 20px !important;
  }
}

.govuk-\!-padding-bottom-4 {
  padding-bottom: 15px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-padding-bottom-4 {
    padding-bottom: 20px !important;
  }
}

.govuk-\!-padding-left-4 {
  padding-left: 15px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-padding-left-4 {
    padding-left: 20px !important;
  }
}

.govuk-\!-padding-5 {
  padding: 15px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-padding-5 {
    padding: 25px !important;
  }
}

.govuk-\!-padding-top-5 {
  padding-top: 15px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-padding-top-5 {
    padding-top: 25px !important;
  }
}

.govuk-\!-padding-right-5 {
  padding-right: 15px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-padding-right-5 {
    padding-right: 25px !important;
  }
}

.govuk-\!-padding-bottom-5 {
  padding-bottom: 15px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-padding-bottom-5 {
    padding-bottom: 25px !important;
  }
}

.govuk-\!-padding-left-5 {
  padding-left: 15px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-padding-left-5 {
    padding-left: 25px !important;
  }
}

.govuk-\!-padding-6 {
  padding: 20px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-padding-6 {
    padding: 30px !important;
  }
}

.govuk-\!-padding-top-6 {
  padding-top: 20px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-padding-top-6 {
    padding-top: 30px !important;
  }
}

.govuk-\!-padding-right-6 {
  padding-right: 20px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-padding-right-6 {
    padding-right: 30px !important;
  }
}

.govuk-\!-padding-bottom-6 {
  padding-bottom: 20px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-padding-bottom-6 {
    padding-bottom: 30px !important;
  }
}

.govuk-\!-padding-left-6 {
  padding-left: 20px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-padding-left-6 {
    padding-left: 30px !important;
  }
}

.govuk-\!-padding-7 {
  padding: 25px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-padding-7 {
    padding: 40px !important;
  }
}

.govuk-\!-padding-top-7 {
  padding-top: 25px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-padding-top-7 {
    padding-top: 40px !important;
  }
}

.govuk-\!-padding-right-7 {
  padding-right: 25px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-padding-right-7 {
    padding-right: 40px !important;
  }
}

.govuk-\!-padding-bottom-7 {
  padding-bottom: 25px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-padding-bottom-7 {
    padding-bottom: 40px !important;
  }
}

.govuk-\!-padding-left-7 {
  padding-left: 25px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-padding-left-7 {
    padding-left: 40px !important;
  }
}

.govuk-\!-padding-8 {
  padding: 30px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-padding-8 {
    padding: 50px !important;
  }
}

.govuk-\!-padding-top-8 {
  padding-top: 30px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-padding-top-8 {
    padding-top: 50px !important;
  }
}

.govuk-\!-padding-right-8 {
  padding-right: 30px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-padding-right-8 {
    padding-right: 50px !important;
  }
}

.govuk-\!-padding-bottom-8 {
  padding-bottom: 30px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-padding-bottom-8 {
    padding-bottom: 50px !important;
  }
}

.govuk-\!-padding-left-8 {
  padding-left: 30px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-padding-left-8 {
    padding-left: 50px !important;
  }
}

.govuk-\!-padding-9 {
  padding: 40px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-padding-9 {
    padding: 60px !important;
  }
}

.govuk-\!-padding-top-9 {
  padding-top: 40px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-padding-top-9 {
    padding-top: 60px !important;
  }
}

.govuk-\!-padding-right-9 {
  padding-right: 40px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-padding-right-9 {
    padding-right: 60px !important;
  }
}

.govuk-\!-padding-bottom-9 {
  padding-bottom: 40px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-padding-bottom-9 {
    padding-bottom: 60px !important;
  }
}

.govuk-\!-padding-left-9 {
  padding-left: 40px !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-padding-left-9 {
    padding-left: 60px !important;
  }
}

.govuk-\!-margin-static-0,
.govuk-\!-static-margin-0 {
  margin: 0 !important;
}

.govuk-\!-margin-top-static-0,
.govuk-\!-static-margin-top-0 {
  margin-top: 0 !important;
}

.govuk-\!-margin-right-static-0,
.govuk-\!-static-margin-right-0 {
  margin-right: 0 !important;
}

.govuk-\!-margin-bottom-static-0,
.govuk-\!-static-margin-bottom-0 {
  margin-bottom: 0 !important;
}

.govuk-\!-margin-left-static-0,
.govuk-\!-static-margin-left-0 {
  margin-left: 0 !important;
}

.govuk-\!-margin-static-1,
.govuk-\!-static-margin-1 {
  margin: 5px !important;
}

.govuk-\!-margin-top-static-1,
.govuk-\!-static-margin-top-1 {
  margin-top: 5px !important;
}

.govuk-\!-margin-right-static-1,
.govuk-\!-static-margin-right-1 {
  margin-right: 5px !important;
}

.govuk-\!-margin-bottom-static-1,
.govuk-\!-static-margin-bottom-1 {
  margin-bottom: 5px !important;
}

.govuk-\!-margin-left-static-1,
.govuk-\!-static-margin-left-1 {
  margin-left: 5px !important;
}

.govuk-\!-margin-static-2,
.govuk-\!-static-margin-2 {
  margin: 10px !important;
}

.govuk-\!-margin-top-static-2,
.govuk-\!-static-margin-top-2 {
  margin-top: 10px !important;
}

.govuk-\!-margin-right-static-2,
.govuk-\!-static-margin-right-2 {
  margin-right: 10px !important;
}

.govuk-\!-margin-bottom-static-2,
.govuk-\!-static-margin-bottom-2 {
  margin-bottom: 10px !important;
}

.govuk-\!-margin-left-static-2,
.govuk-\!-static-margin-left-2 {
  margin-left: 10px !important;
}

.govuk-\!-margin-static-3,
.govuk-\!-static-margin-3 {
  margin: 15px !important;
}

.govuk-\!-margin-top-static-3,
.govuk-\!-static-margin-top-3 {
  margin-top: 15px !important;
}

.govuk-\!-margin-right-static-3,
.govuk-\!-static-margin-right-3 {
  margin-right: 15px !important;
}

.govuk-\!-margin-bottom-static-3,
.govuk-\!-static-margin-bottom-3 {
  margin-bottom: 15px !important;
}

.govuk-\!-margin-left-static-3,
.govuk-\!-static-margin-left-3 {
  margin-left: 15px !important;
}

.govuk-\!-margin-static-4,
.govuk-\!-static-margin-4 {
  margin: 20px !important;
}

.govuk-\!-margin-top-static-4,
.govuk-\!-static-margin-top-4 {
  margin-top: 20px !important;
}

.govuk-\!-margin-right-static-4,
.govuk-\!-static-margin-right-4 {
  margin-right: 20px !important;
}

.govuk-\!-margin-bottom-static-4,
.govuk-\!-static-margin-bottom-4 {
  margin-bottom: 20px !important;
}

.govuk-\!-margin-left-static-4,
.govuk-\!-static-margin-left-4 {
  margin-left: 20px !important;
}

.govuk-\!-margin-static-5,
.govuk-\!-static-margin-5 {
  margin: 25px !important;
}

.govuk-\!-margin-top-static-5,
.govuk-\!-static-margin-top-5 {
  margin-top: 25px !important;
}

.govuk-\!-margin-right-static-5,
.govuk-\!-static-margin-right-5 {
  margin-right: 25px !important;
}

.govuk-\!-margin-bottom-static-5,
.govuk-\!-static-margin-bottom-5 {
  margin-bottom: 25px !important;
}

.govuk-\!-margin-left-static-5,
.govuk-\!-static-margin-left-5 {
  margin-left: 25px !important;
}

.govuk-\!-margin-static-6,
.govuk-\!-static-margin-6 {
  margin: 30px !important;
}

.govuk-\!-margin-top-static-6,
.govuk-\!-static-margin-top-6 {
  margin-top: 30px !important;
}

.govuk-\!-margin-right-static-6,
.govuk-\!-static-margin-right-6 {
  margin-right: 30px !important;
}

.govuk-\!-margin-bottom-static-6,
.govuk-\!-static-margin-bottom-6 {
  margin-bottom: 30px !important;
}

.govuk-\!-margin-left-static-6,
.govuk-\!-static-margin-left-6 {
  margin-left: 30px !important;
}

.govuk-\!-margin-static-7,
.govuk-\!-static-margin-7 {
  margin: 40px !important;
}

.govuk-\!-margin-top-static-7,
.govuk-\!-static-margin-top-7 {
  margin-top: 40px !important;
}

.govuk-\!-margin-right-static-7,
.govuk-\!-static-margin-right-7 {
  margin-right: 40px !important;
}

.govuk-\!-margin-bottom-static-7,
.govuk-\!-static-margin-bottom-7 {
  margin-bottom: 40px !important;
}

.govuk-\!-margin-left-static-7,
.govuk-\!-static-margin-left-7 {
  margin-left: 40px !important;
}

.govuk-\!-margin-static-8,
.govuk-\!-static-margin-8 {
  margin: 50px !important;
}

.govuk-\!-margin-top-static-8,
.govuk-\!-static-margin-top-8 {
  margin-top: 50px !important;
}

.govuk-\!-margin-right-static-8,
.govuk-\!-static-margin-right-8 {
  margin-right: 50px !important;
}

.govuk-\!-margin-bottom-static-8,
.govuk-\!-static-margin-bottom-8 {
  margin-bottom: 50px !important;
}

.govuk-\!-margin-left-static-8,
.govuk-\!-static-margin-left-8 {
  margin-left: 50px !important;
}

.govuk-\!-margin-static-9,
.govuk-\!-static-margin-9 {
  margin: 60px !important;
}

.govuk-\!-margin-top-static-9,
.govuk-\!-static-margin-top-9 {
  margin-top: 60px !important;
}

.govuk-\!-margin-right-static-9,
.govuk-\!-static-margin-right-9 {
  margin-right: 60px !important;
}

.govuk-\!-margin-bottom-static-9,
.govuk-\!-static-margin-bottom-9 {
  margin-bottom: 60px !important;
}

.govuk-\!-margin-left-static-9,
.govuk-\!-static-margin-left-9 {
  margin-left: 60px !important;
}

.govuk-\!-padding-static-0,
.govuk-\!-static-padding-0 {
  padding: 0 !important;
}

.govuk-\!-padding-top-static-0,
.govuk-\!-static-padding-top-0 {
  padding-top: 0 !important;
}

.govuk-\!-padding-right-static-0,
.govuk-\!-static-padding-right-0 {
  padding-right: 0 !important;
}

.govuk-\!-padding-bottom-static-0,
.govuk-\!-static-padding-bottom-0 {
  padding-bottom: 0 !important;
}

.govuk-\!-padding-left-static-0,
.govuk-\!-static-padding-left-0 {
  padding-left: 0 !important;
}

.govuk-\!-padding-static-1,
.govuk-\!-static-padding-1 {
  padding: 5px !important;
}

.govuk-\!-padding-top-static-1,
.govuk-\!-static-padding-top-1 {
  padding-top: 5px !important;
}

.govuk-\!-padding-right-static-1,
.govuk-\!-static-padding-right-1 {
  padding-right: 5px !important;
}

.govuk-\!-padding-bottom-static-1,
.govuk-\!-static-padding-bottom-1 {
  padding-bottom: 5px !important;
}

.govuk-\!-padding-left-static-1,
.govuk-\!-static-padding-left-1 {
  padding-left: 5px !important;
}

.govuk-\!-padding-static-2,
.govuk-\!-static-padding-2 {
  padding: 10px !important;
}

.govuk-\!-padding-top-static-2,
.govuk-\!-static-padding-top-2 {
  padding-top: 10px !important;
}

.govuk-\!-padding-right-static-2,
.govuk-\!-static-padding-right-2 {
  padding-right: 10px !important;
}

.govuk-\!-padding-bottom-static-2,
.govuk-\!-static-padding-bottom-2 {
  padding-bottom: 10px !important;
}

.govuk-\!-padding-left-static-2,
.govuk-\!-static-padding-left-2 {
  padding-left: 10px !important;
}

.govuk-\!-padding-static-3,
.govuk-\!-static-padding-3 {
  padding: 15px !important;
}

.govuk-\!-padding-top-static-3,
.govuk-\!-static-padding-top-3 {
  padding-top: 15px !important;
}

.govuk-\!-padding-right-static-3,
.govuk-\!-static-padding-right-3 {
  padding-right: 15px !important;
}

.govuk-\!-padding-bottom-static-3,
.govuk-\!-static-padding-bottom-3 {
  padding-bottom: 15px !important;
}

.govuk-\!-padding-left-static-3,
.govuk-\!-static-padding-left-3 {
  padding-left: 15px !important;
}

.govuk-\!-padding-static-4,
.govuk-\!-static-padding-4 {
  padding: 20px !important;
}

.govuk-\!-padding-top-static-4,
.govuk-\!-static-padding-top-4 {
  padding-top: 20px !important;
}

.govuk-\!-padding-right-static-4,
.govuk-\!-static-padding-right-4 {
  padding-right: 20px !important;
}

.govuk-\!-padding-bottom-static-4,
.govuk-\!-static-padding-bottom-4 {
  padding-bottom: 20px !important;
}

.govuk-\!-padding-left-static-4,
.govuk-\!-static-padding-left-4 {
  padding-left: 20px !important;
}

.govuk-\!-padding-static-5,
.govuk-\!-static-padding-5 {
  padding: 25px !important;
}

.govuk-\!-padding-top-static-5,
.govuk-\!-static-padding-top-5 {
  padding-top: 25px !important;
}

.govuk-\!-padding-right-static-5,
.govuk-\!-static-padding-right-5 {
  padding-right: 25px !important;
}

.govuk-\!-padding-bottom-static-5,
.govuk-\!-static-padding-bottom-5 {
  padding-bottom: 25px !important;
}

.govuk-\!-padding-left-static-5,
.govuk-\!-static-padding-left-5 {
  padding-left: 25px !important;
}

.govuk-\!-padding-static-6,
.govuk-\!-static-padding-6 {
  padding: 30px !important;
}

.govuk-\!-padding-top-static-6,
.govuk-\!-static-padding-top-6 {
  padding-top: 30px !important;
}

.govuk-\!-padding-right-static-6,
.govuk-\!-static-padding-right-6 {
  padding-right: 30px !important;
}

.govuk-\!-padding-bottom-static-6,
.govuk-\!-static-padding-bottom-6 {
  padding-bottom: 30px !important;
}

.govuk-\!-padding-left-static-6,
.govuk-\!-static-padding-left-6 {
  padding-left: 30px !important;
}

.govuk-\!-padding-static-7,
.govuk-\!-static-padding-7 {
  padding: 40px !important;
}

.govuk-\!-padding-top-static-7,
.govuk-\!-static-padding-top-7 {
  padding-top: 40px !important;
}

.govuk-\!-padding-right-static-7,
.govuk-\!-static-padding-right-7 {
  padding-right: 40px !important;
}

.govuk-\!-padding-bottom-static-7,
.govuk-\!-static-padding-bottom-7 {
  padding-bottom: 40px !important;
}

.govuk-\!-padding-left-static-7,
.govuk-\!-static-padding-left-7 {
  padding-left: 40px !important;
}

.govuk-\!-padding-static-8,
.govuk-\!-static-padding-8 {
  padding: 50px !important;
}

.govuk-\!-padding-top-static-8,
.govuk-\!-static-padding-top-8 {
  padding-top: 50px !important;
}

.govuk-\!-padding-right-static-8,
.govuk-\!-static-padding-right-8 {
  padding-right: 50px !important;
}

.govuk-\!-padding-bottom-static-8,
.govuk-\!-static-padding-bottom-8 {
  padding-bottom: 50px !important;
}

.govuk-\!-padding-left-static-8,
.govuk-\!-static-padding-left-8 {
  padding-left: 50px !important;
}

.govuk-\!-padding-static-9,
.govuk-\!-static-padding-9 {
  padding: 60px !important;
}

.govuk-\!-padding-top-static-9,
.govuk-\!-static-padding-top-9 {
  padding-top: 60px !important;
}

.govuk-\!-padding-right-static-9,
.govuk-\!-static-padding-right-9 {
  padding-right: 60px !important;
}

.govuk-\!-padding-bottom-static-9,
.govuk-\!-static-padding-bottom-9 {
  padding-bottom: 60px !important;
}

.govuk-\!-padding-left-static-9,
.govuk-\!-static-padding-left-9 {
  padding-left: 60px !important;
}


.govuk-\!-text-align-left {
  text-align: left !important;
}

.govuk-\!-text-align-centre {
  text-align: center !important;
}

.govuk-\!-text-align-right {
  text-align: right !important;
}


.govuk-\!-font-size-80 {
  font-size: 53px !important;
  font-size: 3.3125rem !important;
  line-height: 1.0377358491 !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-font-size-80 {
    font-size: 80px !important;
    font-size: 5rem !important;
    line-height: 1 !important;
  }
}
@media print {
  .govuk-\!-font-size-80 {
    font-size: 53pt !important;
    line-height: 1.1 !important;
  }
}

.govuk-\!-font-size-48 {
  font-size: 32px !important;
  font-size: 2rem !important;
  line-height: 1.09375 !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-font-size-48 {
    font-size: 48px !important;
    font-size: 3rem !important;
    line-height: 1.0416666667 !important;
  }
}
@media print {
  .govuk-\!-font-size-48 {
    font-size: 32pt !important;
    line-height: 1.15 !important;
  }
}

.govuk-\!-font-size-36 {
  font-size: 24px !important;
  font-size: 1.5rem !important;
  line-height: 1.0416666667 !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-font-size-36 {
    font-size: 36px !important;
    font-size: 2.25rem !important;
    line-height: 1.1111111111 !important;
  }
}
@media print {
  .govuk-\!-font-size-36 {
    font-size: 24pt !important;
    line-height: 1.05 !important;
  }
}

.govuk-\!-font-size-27 {
  font-size: 18px !important;
  font-size: 1.125rem !important;
  line-height: 1.1111111111 !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-font-size-27 {
    font-size: 27px !important;
    font-size: 1.6875rem !important;
    line-height: 1.1111111111 !important;
  }
}
@media print {
  .govuk-\!-font-size-27 {
    font-size: 18pt !important;
    line-height: 1.15 !important;
  }
}

.govuk-\!-font-size-24 {
  font-size: 18px !important;
  font-size: 1.125rem !important;
  line-height: 1.1111111111 !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-font-size-24 {
    font-size: 24px !important;
    font-size: 1.5rem !important;
    line-height: 1.25 !important;
  }
}
@media print {
  .govuk-\!-font-size-24 {
    font-size: 18pt !important;
    line-height: 1.15 !important;
  }
}

.govuk-\!-font-size-19 {
  font-size: 16px !important;
  font-size: 1rem !important;
  line-height: 1.25 !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-font-size-19 {
    font-size: 19px !important;
    font-size: 1.1875rem !important;
    line-height: 1.3157894737 !important;
  }
}
@media print {
  .govuk-\!-font-size-19 {
    font-size: 14pt !important;
    line-height: 1.15 !important;
  }
}

.govuk-\!-font-size-16 {
  font-size: 14px !important;
  font-size: 0.875rem !important;
  line-height: 1.1428571429 !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-font-size-16 {
    font-size: 16px !important;
    font-size: 1rem !important;
    line-height: 1.25 !important;
  }
}
@media print {
  .govuk-\!-font-size-16 {
    font-size: 14pt !important;
    line-height: 1.2 !important;
  }
}

.govuk-\!-font-size-14 {
  font-size: 12px !important;
  font-size: 0.75rem !important;
  line-height: 1.25 !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-font-size-14 {
    font-size: 14px !important;
    font-size: 0.875rem !important;
    line-height: 1.4285714286 !important;
  }
}
@media print {
  .govuk-\!-font-size-14 {
    font-size: 12pt !important;
    line-height: 1.2 !important;
  }
}

.govuk-\!-font-weight-regular {
  font-weight: 400 !important;
}

.govuk-\!-font-weight-bold {
  font-weight: 700 !important;
}


.govuk-\!-width-full {
  width: 100% !important;
}

.govuk-\!-width-three-quarters {
  width: 100% !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-width-three-quarters {
    width: 75% !important;
  }
}

.govuk-\!-width-two-thirds {
  width: 100% !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-width-two-thirds {
    width: 66.66% !important;
  }
}

.govuk-\!-width-one-half {
  width: 100% !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-width-one-half {
    width: 50% !important;
  }
}

.govuk-\!-width-one-third {
  width: 100% !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-width-one-third {
    width: 33.33% !important;
  }
}

.govuk-\!-width-one-quarter {
  width: 100% !important;
}
@media (min-width: 40.0625em) {
  .govuk-\!-width-one-quarter {
    width: 25% !important;
  }
}




body {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}

html {
  overflow-x: scroll;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

.cdk-overlay-pane {
  background: #ffffff;
  padding-top: 20px;
  padding-left: 25px;
  padding-bottom: 10px;
  padding-right: 25px;
  max-width: calc(100vh - 30px);
  max-height: calc(100vh - 30px);
  overflow-y: auto;
}
@media (min-width: 40.0625em) {
  .cdk-overlay-pane {
    padding-top: 30px;
  }
}
@media (min-width: 40.0625em) {
  .cdk-overlay-pane {
    padding-left: 40px;
  }
}
@media (min-width: 40.0625em) {
  .cdk-overlay-pane {
    padding-right: 40px;
  }
}
@media (min-width: 40.0625em) {
  .cdk-overlay-pane {
    max-width: 640px;
    max-height: calc(100vh - 30px);
    overflow-y: auto;
  }
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
}

app-root > div {
  height: 100%;
  display: flex;
  flex-direction: column;
}
app-root > div > * {
  width: 100%;
}
app-root > div #content {
  flex: 1 0 auto;
}
app-root > div header,
app-root > div footer {
  flex-shrink: 0;
}

.govuk-util__float-right {
  float: right;
}

.govuk-util__align-right {
  text-align: right;
}

.govuk-util__float-left {
  float: left;
}

.govuk-util__align-left {
  text-align: left;
}

.govuk-util__align-center {
  text-align: center;
}

.govuk-util__align-justify {
  text-align: justify;
}

.govuk-util__light-bg {
  background: #f3f2f1;
}

.govuk-util__relative-top-1 {
  position: relative;
  top: 5px;
}

.govuk-util__bold {
  font-weight: bold;
}
.govuk-util__bold .govuk-list, .govuk-util__bold .asc-cms-content ol, .asc-cms-content .govuk-util__bold ol, .govuk-util__bold .asc-cms-content ul, .asc-cms-content .govuk-util__bold ul {
  font-weight: bold;
}

.govuk-util__normal {
  font-weight: normal;
}

.govuk-util__font-style-normal {
  font-style: normal;
}

.govuk-util__inline-block {
  display: inline-block;
}

.govuk-util__block {
  display: block;
}

.govuk-util__list-record {
  border-bottom: solid 1px #b1b4b6;
  padding: 20px 0;
}
.govuk-util__list-record:first-child {
  border-top: solid 1px #b1b4b6;
}

.govuk-util__no-focus {
  outline: 0;
}

.govuk__flex {
  display: flex;
}

.govuk__flex-stack {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 641px) {
  .govuk__flex-stack {
    flex-direction: row;
  }
}

.govuk__align-items-unset {
  align-items: unset;
}

.govuk__align-items-flex-start {
  align-items: flex-start;
}

.govuk__align-items-center {
  align-items: center;
}

.govuk__align-items-flex-end {
  align-items: flex-end;
}

.govuk__justify-content-space-between {
  justify-content: space-between;
}

.govuk__justify-content-end {
  justify-content: flex-end;
}

.govuk__nowrap {
  white-space: nowrap;
}

@media screen and (min-width: 641px) {
  .govuk__nowrap-responsive {
    white-space: nowrap;
  }
}

.govuk-util__vertical-align-top {
  vertical-align: top;
}

.govuk-util__vertical-align-middle {
  vertical-align: middle;
}

.govuk-util__vertical-align-bottom {
  vertical-align: bottom;
}

.govuk-line-height__normal {
  line-height: normal;
}

.govuk-max-width {
  max-width: calc(100vh - 274px);
}

.govuk__width-fit-content {
  width: fit-content;
}

.asc-font-19 {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.25;
}
@media print {
  .asc-font-19 {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .asc-font-19 {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.3157894737;
  }
}
@media print {
  .asc-font-19 {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.asc-icon-align {
  position: relative;
  top: 4px;
}

.asc__preline {
  white-space: pre-line;
}

.asc__negative-margin-right-4 {
  margin-right: -20px;
}

.govuk-width-container, .asc-width-container-admin-section {
  max-width: 960px;
  outline: 0 !important;
}

@media (max-width: 48.0525em) {
  .has-divider {
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.16) !important;
    padding-bottom: 20px;
  }
  html body .govuk-button {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .govuk-header {
    padding-top: 0;
  }
  .govuk-header .govuk-header__navigation {
    height: 0;
    display: block;
    overflow: hidden;
    position: absolute;
    top: 112px;
    left: 0;
    right: 0;
    z-index: 300;
    background: #16558c;
    color: #fff;
    -webkit-transition: height 0.5s linear;
    -moz-transition: height 0.5s linear;
    -ms-transition: height 0.5s linear;
    -o-transition: height 0.5s linear;
    transition: height 0.5s linear;
    box-shadow: 0 10px 10px 0px rgba(0, 0, 0, 0.3);
  }
  .govuk-header .govuk-header__navigation li.govuk-header__navigation-item {
    display: block;
    border: 0;
    padding: 0 !important;
    margin: 0 !important;
    font-size: 0;
  }
  .govuk-header .govuk-header__navigation li.govuk-header__navigation-item a.govuk-header__link {
    display: block;
    text-align: left;
    border: 0;
    color: #fff !important;
    padding: 20px 20px;
    outline: 0 !important;
    font-size: 17px;
  }
  .govuk-header .govuk-header__navigation li.govuk-header__navigation-item a.govuk-header__link:hover, .govuk-header .govuk-header__navigation li.govuk-header__navigation-item a.govuk-header__link:active {
    background: #1d70b8;
  }
  .govuk-header.open-dropdown .govuk-header__navigation {
    height: auto;
  }
  .govuk-header.open-dropdown .govuk-header__menu-button::after {
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .govuk-header__menu-button {
    color: #1d70b8 !important;
    margin-right: 20px;
    font-size: 17px;
  }
  .govuk-width-container, .asc-width-container-admin-section {
    margin: 0;
    width: 100%;
    position: relative !important;
  }
  .govuk-width-container .govuk-header__strapline, .asc-width-container-admin-section .govuk-header__strapline {
    margin: 0 !important;
    padding: 5px 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -5px;
    text-align: center;
    display: block;
  }
  .govuk-width-container .govuk-main-wrapper, .asc-width-container-admin-section .govuk-main-wrapper {
    padding: 20px;
  }
  .govuk-width-container .govuk-main-wrapper select, .asc-width-container-admin-section .govuk-main-wrapper select {
    width: 100% !important;
  }
  .govuk-width-container .govuk-footer__meta-item--grow, .asc-width-container-admin-section .govuk-footer__meta-item--grow {
    flex-basis: inherit;
  }
  footer .govuk-footer__meta {
    padding: 0;
    margin: 0;
  }
  .govuk-back-link {
    margin-left: 20px;
  }
  .govuk-phase-banner {
    padding: 10px 20px;
  }
  .govuk-header .govuk-header__container {
    padding: 10px 0 !important;
  }
  .govuk-header .govuk-header__container img {
    margin: 0 0 25px 20px;
    max-width: 130px;
  }
}
.govuk-list--inline > li {
  display: inline-block;
  margin-right: 20px;
}
.govuk-list--inline > li:last-child {
  margin-right: 0;
}

.govuk-list--tab {
  display: flex;
  background-color: #f3f2f1;
  margin: 0;
  margin-bottom: 15px;
  padding: 15px;
}
@media (min-width: 40.0625em) {
  .govuk-list--tab {
    margin-bottom: 20px;
  }
}
.govuk-list--tab__column {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 5px;
  padding-right: 15px;
}
.govuk-list--tab__column dt {
  margin: 0;
  display: flex;
  align-items: center;
  margin-right: 5px;
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.25;
}
@media print {
  .govuk-list--tab__column dt {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-list--tab__column dt {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.3157894737;
  }
}
@media print {
  .govuk-list--tab__column dt {
    font-size: 14pt;
    line-height: 1.15;
  }
}
.govuk-list--tab__column dd {
  margin: 0;
  display: flex;
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.25;
}
@media print {
  .govuk-list--tab__column dd {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-list--tab__column dd {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.3157894737;
  }
}
@media print {
  .govuk-list--tab__column dd {
    font-size: 14pt;
    line-height: 1.15;
  }
}
.govuk-list--tab__column dd:nth-of-type(2) {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
@media (min-width: 40.0625em) {
  .govuk-list--tab div:nth-of-type(1) {
    border-top: 0;
  }
}
.govuk-list--tab div:nth-of-type(2) {
  border-top: 1px solid #b1b4b6;
}
@media (min-width: 40.0625em) {
  .govuk-list--tab div:nth-of-type(2) {
    border-top: 0;
    border-left: 1px solid #b1b4b6;
    padding-left: 15px;
  }
}
.govuk-list--tab div:nth-of-type(3) {
  border-top: 1px solid #b1b4b6;
}
@media (min-width: 40.0625em) {
  .govuk-list--tab div:nth-of-type(3) {
    border-top: 0;
    border-left: 1px solid #b1b4b6;
    padding-left: 15px;
  }
}

.govuk-list--definition {
  display: grid;
  margin: 0;
  margin-bottom: 15px;
  grid-template-columns: 1fr;
}
@media (min-width: 40.0625em) {
  .govuk-list--definition {
    margin-bottom: 20px;
  }
}
@media (min-width: 40.0625em) {
  .govuk-list--definition {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.govuk-list--definition dt {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  margin: 0;
  position: relative;
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.25;
  background-color: #f3f2f1;
}
@media print {
  .govuk-list--definition dt {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-list--definition dt {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.3157894737;
  }
}
@media print {
  .govuk-list--definition dt {
    font-size: 14pt;
    line-height: 1.15;
  }
}
.govuk-list--definition dd {
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0;
  position: relative;
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.1111111111;
  background-color: #f3f2f1;
}
@media print {
  .govuk-list--definition dd {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-list--definition dd {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.25;
  }
}
@media print {
  .govuk-list--definition dd {
    font-size: 18pt;
    line-height: 1.15;
  }
}
.govuk-list--definition.govuk-list--definition-xl dt {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.1111111111;
}
@media print {
  .govuk-list--definition.govuk-list--definition-xl dt {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-list--definition.govuk-list--definition-xl dt {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.25;
  }
}
@media print {
  .govuk-list--definition.govuk-list--definition-xl dt {
    font-size: 18pt;
    line-height: 1.15;
  }
}
.govuk-list--definition.govuk-list--definition-xl dd {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  font-size: 53px;
  font-size: 3.3125rem;
  line-height: 1.0377358491;
}
@media print {
  .govuk-list--definition.govuk-list--definition-xl dd {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-list--definition.govuk-list--definition-xl dd {
    font-size: 80px;
    font-size: 5rem;
    line-height: 1;
  }
}
@media print {
  .govuk-list--definition.govuk-list--definition-xl dd {
    font-size: 53pt;
    line-height: 1.1;
  }
}
.govuk-list--definition dt:nth-of-type(1) {
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 1;
  grid-column-end: 2;
}
@media (min-width: 40.0625em) {
  .govuk-list--definition dt:nth-of-type(1) {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 2;
  }
}
.govuk-list--definition dd:nth-of-type(1) {
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 1;
  grid-column-end: 2;
}
@media (min-width: 40.0625em) {
  .govuk-list--definition dd:nth-of-type(1) {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 2;
    border-top: 0;
  }
}
.govuk-list--definition dt:nth-of-type(2) {
  grid-row-start: 4;
  grid-row-end: 5;
  grid-column-start: 1;
  grid-column-end: 2;
}
@media (min-width: 40.0625em) {
  .govuk-list--definition dt:nth-of-type(2) {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 2;
    grid-column-end: 3;
  }
  .govuk-list--definition dt:nth-of-type(2):before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 15px;
    border-left: 1px solid #505a5f;
  }
}
.govuk-list--definition dd:nth-of-type(2) {
  grid-row-start: 3;
  grid-row-end: 4;
  grid-column-start: 1;
  grid-column-end: 2;
  border-top: 1px solid #505a5f;
}
@media (min-width: 40.0625em) {
  .govuk-list--definition dd:nth-of-type(2) {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 2;
    grid-column-end: 3;
    border-top: 0;
  }
  .govuk-list--definition dd:nth-of-type(2):before {
    content: "";
    display: block;
    position: absolute;
    top: 15px;
    left: 0;
    bottom: 0;
    border-left: 1px solid #505a5f;
  }
}
.govuk-list--definition dt:nth-of-type(3) {
  grid-row-start: 6;
  grid-row-end: 7;
  grid-column-start: 1;
  grid-column-end: 2;
}
@media (min-width: 40.0625em) {
  .govuk-list--definition dt:nth-of-type(3) {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 3;
    grid-column-end: 4;
  }
  .govuk-list--definition dt:nth-of-type(3):before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 15px;
    border-left: 1px solid #505a5f;
  }
}
.govuk-list--definition dd:nth-of-type(3) {
  grid-row-start: 5;
  grid-row-end: 6;
  grid-column-start: 1;
  grid-column-end: 2;
  border-top: 1px solid #505a5f;
}
@media (min-width: 40.0625em) {
  .govuk-list--definition dd:nth-of-type(3) {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 3;
    grid-column-end: 4;
    border-top: 0;
  }
  .govuk-list--definition dd:nth-of-type(3):before {
    content: "";
    display: block;
    position: absolute;
    top: 15px;
    left: 0;
    bottom: 0;
    border-left: 1px solid #505a5f;
  }
}

@media (min-width: 40.0625em) {
  .govuk-list--definition-heading-grid {
    display: table;
    width: 100%;
    margin: 0;
    margin-bottom: 15px;
  }
}
@media (min-width: 40.0625em) and (min-width: 40.0625em) {
  .govuk-list--definition-heading-grid {
    margin-bottom: 20px;
  }
}
@media (min-width: 40.0625em) {
  .govuk-list--definition-heading-grid .govuk-list--definition-heading-grid__row {
    display: table-row;
  }
}
@media (min-width: 40.0625em) {
  .govuk-list--definition-heading-grid .govuk-list--definition-heading-grid__col {
    display: table-cell;
  }
}
.govuk-list--definition-heading-grid dt {
  padding: 15px;
  margin: 0;
  position: relative;
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.25;
  background-color: #f3f2f1;
}
@media print {
  .govuk-list--definition-heading-grid dt {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-list--definition-heading-grid dt {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.3157894737;
  }
}
@media print {
  .govuk-list--definition-heading-grid dt {
    font-size: 14pt;
    line-height: 1.15;
  }
}
.govuk-list--definition-heading-grid dd {
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0;
  position: relative;
  background-color: #f3f2f1;
}

.govuk-list--download > li {
  min-height: 26px;
  padding-left: 35px;
  margin-bottom: 15px;
  background: url("/assets/images/icon-download.svg") no-repeat 0 0;
}

.govuk-fieldset__legend {
  overflow: visible;
}
.govuk-fieldset__legend.govuk-fieldset__legend--xl {
  margin-bottom: 30px;
}
@media (min-width: 40.0625em) {
  .govuk-fieldset__legend.govuk-fieldset__legend--xl {
    margin-bottom: 50px;
  }
}
.govuk-fieldset__legend.govuk-fieldset__legend--l {
  margin-bottom: 20px;
}
@media (min-width: 40.0625em) {
  .govuk-fieldset__legend.govuk-fieldset__legend--l {
    margin-bottom: 30px;
  }
}

.asc-link-download > div {
  min-height: 16px;
  padding-left: 35px;
  padding-bottom: 10px;
  background: url("/assets/images/icon-download.svg") no-repeat 0 0;
}

/* Override the default GOV.UK inline radio styling for right alignment */
.asc-inline-radio-buttons {
  display: flex;
  justify-content: flex-end; /* Align the whole group to the right */
}

.asc-inline-radio-buttons .govuk-radios__item {
  display: flex;
  align-items: center;
}

.asc-inline-radio-buttons .asc-radios-with-spacing {
  margin-right: 140px;
}

.asc-inline-radio-buttons .govuk-radios__item:last-child {
  margin-right: 0;
}

.asc-inline-radio-buttons .govuk-radios__label {
  vertical-align: middle;
  padding: 5px 0px 5px 10px;
}

.govuk-radios__conditional {
  border-left: 5px solid #b1b4b6;
}

.asc-small-accordion-heading {
  font-size: 19px;
}

.govuk-auto-suggest {
  position: relative;
}
.govuk-auto-suggest ul {
  border: 2px solid #0b0c0c;
  margin: 0;
  border-top: 0;
  padding: 0;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  background: #ffffff;
  max-height: 232px;
  overflow-y: auto;
}
.govuk-auto-suggest ul li {
  list-style-type: none;
  padding: 8px;
  border-bottom: 1px solid #0b0c0c;
  cursor: pointer;
}
.govuk-auto-suggest ul li:last-child {
  border: 0;
}

.govuk-auto-suggest > ul::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  background-color: #f3f2f1;
}

.govuk-auto-suggest > ul::-webkit-scrollbar {
  width: 9px;
  background-color: #b1b4b6;
}

.govuk-auto-suggest > ul::-webkit-scrollbar-thumb {
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #505a5f;
}

.govuk-breadcrumbs {
  font-size: 16px;
}

.govuk-breadcrumbs__list {
  display: inline-block;
  vertical-align: top;
}

.govuk-breadcrumbs__list-item {
  margin-left: 9px;
}
.govuk-breadcrumbs__list-item::before {
  border: 0;
  background: url("/assets/images/chevron.svg") no-repeat;
  background-size: contain;
  width: 8px;
  height: 14px;
  transform: none;
  top: 4px;
  bottom: auto;
  left: -1px;
}

.govuk-breadcrumbs__link {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: underline;
}
@media print {
  .govuk-breadcrumbs__link {
    font-family: sans-serif;
  }
}
.govuk-breadcrumbs__link:focus {
  outline: 3px solid transparent;
  color: #0b0c0c;
  background-color: #ffdd00;
  box-shadow: 0 -2px #ffdd00, 0 4px #0b0c0c;
  text-decoration: none;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}
.govuk-breadcrumbs__link:link {
  color: #1d70b8;
}
.govuk-breadcrumbs__link:visited {
  color: #4c2c92;
}
.govuk-breadcrumbs__link:hover {
  color: #003078;
}
.govuk-breadcrumbs__link:active {
  color: #0b0c0c;
}
.govuk-breadcrumbs__link:focus {
  color: #0b0c0c;
}
@media print {
  .govuk-breadcrumbs__link[href^="/"]:after, .govuk-breadcrumbs__link[href^="http://"]:after, .govuk-breadcrumbs__link[href^="https://"]:after {
    content: " (" attr(href) ")";
    font-size: 90%;
    word-wrap: break-word;
  }
}
.govuk-breadcrumbs__link:visited:link {
  color: #1d70b8;
}
.govuk-breadcrumbs__link:visited:visited {
  color: #1d70b8;
}
.govuk-breadcrumbs__link:visited:hover {
  color: #003078;
}
.govuk-breadcrumbs__link:visited:active {
  color: #0b0c0c;
}
.govuk-breadcrumbs__link:visited:focus {
  color: #0b0c0c;
}

.govuk-button--tertiary {
  background-color: #1d70b8;
  box-shadow: 0 2px 0 #11436e;
}
.govuk-button--tertiary, .govuk-button--tertiary:link, .govuk-button--tertiary:visited, .govuk-button--tertiary:active, .govuk-button--tertiary:hover {
  color: #ffffff;
}
.govuk-button--tertiary:hover, .govuk-button--tertiary:focus {
  background-color: #1a65a6;
}

.govuk-button-group, .govuk-button-group--gap-between {
  margin-top: 30px;
}

.govuk-button-heading-group {
  display: flex;
}
@media (min-width: 48.0625em) {
  .govuk-button-heading-group {
    margin-top: 30px;
    justify-content: flex-end;
  }
}
.govuk-button-heading-group .govuk-button--link {
  display: flex;
  align-items: center;
}
.govuk-button-heading-group .govuk-button--link:not(:first-of-type) {
  margin-left: 25px;
}

.govuk-button-group--gap-between {
  column-gap: 100px;
}

.govuk-button.govuk-button--link {
  background: none;
  box-shadow: none;
  color: #1d70b8;
  text-decoration: underline;
}

.asc-chevron-before {
  display: inline-block;
  position: relative;
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.25;
  padding-left: 14px;
  text-decoration: underline;
}
@media print {
  .asc-chevron-before {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .asc-chevron-before {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.3157894737;
  }
}
@media print {
  .asc-chevron-before {
    font-size: 14pt;
    line-height: 1.15;
  }
}
.asc-chevron-before:focus {
  text-decoration: none;
}
.asc-chevron-before:focus:before {
  border-color: #0b0c0c;
}
.asc-chevron-before:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 9px;
  height: 9px;
  margin: auto 0;
  -webkit-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  transform: rotate(225deg);
  border: solid;
  border-width: 2px 2px 0 0;
  border-color: #1d70b8;
}

.asc-chevron-before:visited:before {
  border-color: #4c2c92;
}

.asc-chevron-before:after {
  content: "";
  position: absolute;
  top: -14px;
  right: 0;
  bottom: -14px;
  left: 0;
}

.asc-chevron-after {
  display: inline-block;
  position: relative;
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.25;
  padding-right: 14px;
  text-decoration: underline;
}
@media print {
  .asc-chevron-after {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .asc-chevron-after {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.3157894737;
  }
}
@media print {
  .asc-chevron-after {
    font-size: 14pt;
    line-height: 1.15;
  }
}
.asc-chevron-after:focus {
  text-decoration: none;
}
.asc-chevron-after:focus:after {
  border-color: #0b0c0c;
}
.asc-chevron-after:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 9px;
  height: 9px;
  margin: auto 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  border: solid;
  border-width: 2px 2px 0 0;
  border-color: #1d70b8;
}

.asc-chevron-after:visited:after {
  border-color: #4c2c92;
}

.asc-no-underline {
  text-decoration: none;
}

.govuk-checkboxes__item {
  margin-bottom: 15px;
}
@media (min-width: 40.0625em) {
  .govuk-checkboxes__item {
    margin-bottom: 20px;
  }
}

.department-of-health-logo {
  display: inline-block;
  font-weight: 400;
  font-size: 0.8125rem;
  line-height: 0.9375rem;
  letter-spacing: normal;
}
@media (min-width: 641px) {
  .department-of-health-logo {
    font-size: 1.125rem;
    line-height: 1.25rem;
  }
}
.department-of-health-logo .department-of-health-logo__crest {
  text-transform: none;
  text-decoration: none;
  display: block;
  color: #0b0c0c;
  height: auto;
  width: auto;
  direction: ltr;
  background: url("/assets/images/govuk-crest.png") no-repeat 0.375rem 0;
  background-size: auto 1.625rem;
  background-image: url("/assets/images/govuk-crest-2x.png");
  border-left: 2px solid #0b0c0c;
  padding-top: 1.25rem;
  padding-left: 0.375rem;
  border-color: #00ad93;
}
@media (min-width: 641px) {
  .department-of-health-logo .department-of-health-logo__crest {
    padding-top: 1.5625rem;
    padding-left: 0.4375rem;
  }
}
.department-of-health-logo .department-of-health-logo__crest .department-of-health-logo__name {
  position: relative;
  top: 0.1875rem;
  font-family: "HelveticaNeue", "Helvetica Neue", "Arial", "Helvetica", sans-serif;
}

.govuk-summary-list__row > .govuk-fieldset {
  min-width: min-content;
}

footer {
  background: #ffffff;
  border-top: 3px solid #1d70b8;
  margin-top: 6.25rem;
}
footer a.govuk-footer__link:link, footer a.govuk-footer__link:visited {
  color: #1d70b8;
}
footer a.govuk-footer__link:hover, footer a.govuk-footer__link:active {
  color: #1d70b8;
}
footer a.govuk-footer__link:focus {
  outline: 3px solid transparent;
  color: #0b0c0c;
  background-color: #ffdd00;
  box-shadow: 0 -2px #ffdd00, 0 4px #0b0c0c;
  text-decoration: none;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}
footer .govuk-width-container, footer .asc-width-container-admin-section {
  position: relative;
  padding-top: 30px;
}
footer .footer-tagline {
  background: #1d70b8;
  color: #ffffff;
  position: absolute;
  top: -1.0625rem;
  padding: 0.375rem;
  line-height: 1em;
}
footer .govuk-footer__meta {
  align-items: center;
}
@media (min-width: 40.0625em) {
  footer .govuk-list, footer .asc-cms-content ol, .asc-cms-content footer ol, footer .asc-cms-content ul, .asc-cms-content footer ul {
    text-align: right;
    margin-bottom: 0;
  }
  footer .govuk-list > li, footer .asc-cms-content ol > li, .asc-cms-content footer ol > li, footer .asc-cms-content ul > li, .asc-cms-content footer ul > li {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 40px;
  }
}

.govuk-form-group--error.govuk__grid {
  text-indent: 15px;
  padding-left: 0;
}

@media (min-width: 40.0625em) {
  .govuk__grid {
    display: grid;
    column-gap: 15px;
  }
  .govuk__grid-container {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto;
    border-bottom: solid 1px #b1b4b6;
    margin-bottom: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    align-items: center;
  }
  .asc__references-grid-container {
    grid-template-columns: 1fr 1fr 0.2fr;
    grid-template-rows: auto auto;
    border-bottom: solid 1px #b1b4b6;
    margin-bottom: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    align-items: center;
  }
  .govuk__grid-row-start-1 {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 4;
  }
  .govuk__align-items-center {
    align-items: center;
  }
  .govuk__align-items-end {
    align-items: end;
  }
  .govuk__justify-self-end {
    justify-self: end;
  }
  .govuk__grid-column-1,
  .govuk__grid-column-2,
  .govuk__grid-column-3 {
    grid-row-start: 1;
    grid-row-end: 2;
  }
  .govuk__grid-column-1 {
    grid-column-start: 1;
    grid-column-end: 2;
  }
  .govuk__grid-column-2 {
    grid-column-start: 2;
    grid-column-end: 3;
  }
  .govuk__grid-column-3 {
    grid-column-start: 3;
    grid-column-end: 4;
  }
  .govuk-form-group--error .govuk__grid-column-1 {
    grid-row-start: 2;
    grid-row-end: 3;
  }
  .govuk-form-group--error .govuk__grid-column-2 {
    grid-row-start: 2;
    grid-row-end: 3;
  }
  .govuk-form-group--error .govuk__grid-column-3 {
    grid-row-start: 2;
    grid-row-end: 3;
  }
}
.asc-width-container-admin-section {
  max-width: 1500px;
  padding: 0 15px;
}

.govuk-header {
  border-bottom: 5px solid #1d70b8;
  color: #0b0c0c;
  background: #ffffff;
}
.govuk-header .govuk-header__container {
  position: static;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 0;
  border-bottom: 0;
  display: flex;
  align-items: center;
}
@media (min-width: 40.0625em) {
  .govuk-header .govuk-header__container {
    padding-top: 30px;
  }
}
@media (min-width: 40.0625em) {
  .govuk-header .govuk-header__container {
    padding-bottom: 30px;
  }
}
.govuk-header .govuk-header__link {
  text-decoration: none;
  font-weight: 400;
  text-decoration: underline;
}
.govuk-header .govuk-header__link:link, .govuk-header .govuk-header__link:visited {
  color: #0b0c0c;
}
.govuk-header .govuk-header__link:focus {
  outline: 3px solid transparent;
  color: #0b0c0c;
  background-color: #ffdd00;
  box-shadow: 0 -2px #ffdd00, 0 4px #0b0c0c;
  text-decoration: none;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}
.govuk-header nav {
  flex: 1;
}
.govuk-header .govuk-header__strapline {
  display: inline-block;
  text-decoration: none;
  background: #1d70b8;
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.25;
  color: #ffffff;
  margin-bottom: 10px;
  margin-left: 20px;
  padding: 5px 10px;
}
@media print {
  .govuk-header .govuk-header__strapline {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-header .govuk-header__strapline {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.3157894737;
  }
}
@media print {
  .govuk-header .govuk-header__strapline {
    font-size: 14pt;
    line-height: 1.15;
  }
}
@media (min-width: 40.0625em) {
  .govuk-header .govuk-header__strapline {
    margin-left: 30px;
  }
}
.govuk-header .govuk-header__navigation {
  text-align: right;
}
.govuk-header .govuk-header__navigation .govuk-header__navigation-item {
  padding: 10px 0;
  margin-right: 15px;
  border-bottom: 0;
}
.govuk-header .govuk-header__navigation .govuk-header__navigation-item::after {
  content: "|";
  margin-left: 15px;
}
.govuk-header .govuk-header__navigation .govuk-header__navigation-item:last-child {
  margin-right: 0;
}
.govuk-header .govuk-header__navigation .govuk-header__navigation-item:last-child::after {
  display: none;
}
@media (min-width: 48.0625em) {
  .govuk-header .govuk-header__navigation .govuk-header__navigation-item {
    display: inline-block;
    padding: 5px 0;
    border: 0;
  }
}
.govuk-header .govuk-header__navigation .govuk-header__navigation-item a {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.1428571429;
  white-space: nowrap;
}
@media print {
  .govuk-header .govuk-header__navigation .govuk-header__navigation-item a {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-header .govuk-header__navigation .govuk-header__navigation-item a {
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.25;
  }
}
@media print {
  .govuk-header .govuk-header__navigation .govuk-header__navigation-item a {
    font-size: 14pt;
    line-height: 1.2;
  }
}

.govuk-input__currency::before {
  content: "£";
  display: inline-block;
  box-sizing: border-box;
  outline: none;
  vertical-align: top;
  height: 40px;
  margin-top: 0;
  padding: 5px 10px;
  border: 2px solid #0b0c0c;
  border-right: 0;
  border-radius: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.625;
}
@media print {
  .govuk-input__currency::before {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-input__currency::before {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.3684210526;
  }
}
@media print {
  .govuk-input__currency::before {
    font-size: 14pt;
    line-height: 26px;
  }
}

.govuk-inset-text.todo {
  border-color: #f47738;
  background-color: rgba(244, 119, 56, 0.12);
}
.govuk-inset-text.success {
  border-color: #00703c;
  background-color: rgba(0, 112, 60, 0.25);
}
.govuk-inset-text.warning {
  border-color: #f47738;
  background-color: rgba(244, 119, 56, 0.12);
}
.govuk-inset-text.pending {
  border-color: #1d70b8;
  background-color: rgba(29, 112, 184, 0.15);
}
.govuk-inset-text.thin-border {
  border-width: 5px;
  padding-left: 20px;
}

.govuk-panel--interruption {
  background-color: #1d70b8;
  text-align: left;
}
.govuk-panel--interruption * {
  color: #ffffff;
}
.govuk-panel--interruption a,
.govuk-panel--interruption a:link,
.govuk-panel--interruption a:visited {
  color: #ffffff;
}
.govuk-panel--interruption a:hover {
  color: #f3f2f1;
}
.govuk-panel--interruption .govuk-list--definition dt,
.govuk-panel--interruption .govuk-list--definition dd {
  color: #1d70b8;
}
.govuk-panel--interruption .govuk-button:not(.govuk-button--link) {
  background-color: #ffffff;
  box-shadow: 0 2px 0 #0b0c0c;
}
.govuk-panel--interruption .govuk-button:not(.govuk-button--link):link, .govuk-panel--interruption .govuk-button:not(.govuk-button--link):visited {
  color: #1d70b8;
}
.govuk-panel--interruption .govuk-button:not(.govuk-button--link):hover, .govuk-panel--interruption .govuk-button:not(.govuk-button--link):focus {
  background-color: #f3f2f1;
}

.govuk-panel--light-blue {
  background-color: rgba(86, 148, 202, 0.2);
}

.govuk-panel--warning-prompt {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.1111111111;
  padding: 15px;
  background-color: #d4351c;
  color: #fff;
}
@media print {
  .govuk-panel--warning-prompt {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .govuk-panel--warning-prompt {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.25;
  }
}
@media print {
  .govuk-panel--warning-prompt {
    font-size: 18pt;
    line-height: 1.15;
  }
}

.govuk-panel--gray {
  background-color: #f3f2f1;
}

.govuk-panel--with-icon {
  background-color: #f3f2f1;
  text-align: left;
  display: flex;
  align-items: baseline;
  padding-left: 18px;
  column-gap: 5px;
  margin-bottom: 30px;
}
.govuk-panel--with-icon > img {
  height: 20px;
  width: 20px;
}
@media only screen and (min-width: 641px) {
  .govuk-panel--with-icon > img {
    height: 28px;
    width: 28px;
  }
}
.govuk-panel--with-icon > h1 {
  margin-bottom: 0;
}

.govuk-progress[value] {
  appearance: none;
  border: none;
  height: 30px;
  /* IE10 */
  color: #00703c;
}
.govuk-progress[value]::-webkit-progress-bar {
  background: transparent;
  border: 2px solid #505a5f;
}
.govuk-progress[value]::-webkit-progress-value, .govuk-progress[value]::-moz-progress-bar {
  background: #00703c;
}

.govuk-select__conditional--hidden {
  display: none;
}

.asc-summarylist-border-top {
  border-top: 1px solid #b1b4b6;
}

.asc-summarylist-border-bottom {
  border-bottom: 1px solid #b1b4b6;
}

.govuk-summary-list--wrap-border {
  border: 1px solid #b1b4b6;
  border-width: 1px 0;
}

.govuk-summary-list--bottom-border .govuk-summary-list__key,
.govuk-summary-list--bottom-border .govuk-summary-list__value,
.govuk-summary-list--bottom-border .govuk-summary-list__actions,
.govuk-summary-list--bottom-border .govuk-summary-list__row {
  border: 1px solid #b1b4b6;
  border-width: 0 0 1px 0;
}

.govuk-summary-list--top-border .govuk-summary-list__key,
.govuk-summary-list--top-border .govuk-summary-list__value,
.govuk-summary-list--top-border .govuk-summary-list__actions {
  vertical-align: top;
}
.govuk-summary-list--top-border .govuk-summary-list__row:first-child .govuk-summary-list__key,
.govuk-summary-list--top-border .govuk-summary-list__row:first-child .govuk-summary-list__value,
.govuk-summary-list--top-border .govuk-summary-list__row:first-child .govuk-summary-list__actions,
.govuk-summary-list--top-border .govuk-summary-list__row:first-child .govuk-summary-list__row {
  border-top: 1px solid #b1b4b6;
}

.govuk-summary-list--no-top-border .govuk-summary-list__key,
.govuk-summary-list--no-top-border .govuk-summary-list__value,
.govuk-summary-list--no-top-border .govuk-summary-list__actions {
  vertical-align: top;
}

.govuk-summary-list__row.govuk-summary-list__row--no-bottom-border {
  border-bottom: none;
}
.govuk-summary-list__row.govuk-summary-list__row--no-bottom-border .govuk-summary-list__key,
.govuk-summary-list__row.govuk-summary-list__row--no-bottom-border .govuk-summary-list__value,
.govuk-summary-list__row.govuk-summary-list__row--no-bottom-border .govuk-summary-list__actions {
  border-bottom: none;
}

.govuk-summary-list__row--no-bottom-padding .govuk-summary-list__key,
.govuk-summary-list__row--no-bottom-padding .govuk-summary-list__value,
.govuk-summary-list__row--no-bottom-padding .govuk-summary-list__actions {
  padding-bottom: 0;
}

.govuk-summary-list__row--error-warning-message .govuk-summary-list__key,
.govuk-summary-list__row--error-warning-message .govuk-summary-list__value,
.govuk-summary-list__row--error-warning-message .govuk-summary-list__actions {
  padding-top: 5px;
}

.govuk-summary-list__value--confirm {
  font-weight: bold;
}

.govuk-summary-list--medium .govuk-summary-list__key {
  width: 45%;
}
.govuk-summary-list--medium .govuk-summary-list__value {
  width: 55%;
}

.govuk-summary-list--wide .govuk-summary-list__key {
  width: 70%;
}
.govuk-summary-list--wide .govuk-summary-list__value {
  width: 30%;
}

.asc-summary-list--wide-value .govuk-summary-list__key {
  width: 35%;
}
.asc-summary-list--wide-value .govuk-summary-list__value {
  width: 55%;
  padding-left: 15px;
}
.asc-summary-list--wide-value .govuk-summary-list__actions {
  width: 10%;
}

.asc-password-wrap {
  display: inline-block;
  max-width: 40%;
}

.govuk-summary-list__warning {
  padding-left: 15px;
  border-left: 10px solid #f47738;
}
.govuk-summary-list__warning-message {
  color: #f47738;
}

.govuk-summary-list__error {
  padding-left: 15px;
  border-left: 10px solid #d4351c;
}

.govuk-summary-list--contains-warnings .govuk-summary-list__key {
  width: 35%;
  vertical-align: top;
}
.govuk-summary-list--contains-warnings .govuk-summary-list__value {
  width: 40%;
  vertical-align: top;
}
.govuk-summary-list--contains-warnings .govuk-summary-list__actions {
  width: 25%;
  vertical-align: top;
}
.govuk-summary-list--contains-warnings .govuk-summary-list__row--warning dt,
.govuk-summary-list--contains-warnings .govuk-summary-list__row--warning dd {
  background: rgba(244, 119, 56, 0.12);
}
@media (min-width: 48.0625em) {
  .govuk-summary-list--contains-warnings .govuk-summary-list__row--warning .govuk-summary-list__value {
    white-space: nowrap;
  }
  .govuk-summary-list--contains-warnings .govuk-summary-list__row--warning .govuk-summary-list__value p {
    left: -75%;
    width: 100%;
    position: relative;
  }
}
.govuk-summary-list--contains-warnings .govuk-summary-list__row--warning .govuk-summary-list__actions {
  padding-right: 10px;
}

.govuk-summary-list--wide-key .govuk-summary-list__key {
  width: 45%;
}
.govuk-summary-list--wide-key .govuk-summary-list__value {
  width: 45%;
  padding-left: 15px;
}
.govuk-summary-list--wide-key .govuk-summary-list__actions {
  width: 10%;
}

.asc-no-border {
  border: none;
}

.asc-warning-banner {
  background-color: rgba(244, 119, 56, 0.12);
  /* &::before, */
}
@media (min-width: 48.0625em) {
  .asc-warning-banner .govuk-summary-list__value {
    white-space: nowrap;
  }
  .asc-warning-banner .govuk-summary-list__value p {
    left: -85%;
    width: 100%;
    position: relative;
  }
}
.asc-warning-banner .govuk-summary-list__actions {
  padding-right: 10px;
}
.asc-warning-banner::after {
  content: " ";
  line-height: 3em;
}
.asc-warning-banner .govuk-summary-list__key {
  border-left: 10px solid #f47738;
}
.asc-warning-banner .govuk-summary-list__key,
.asc-warning-banner .govuk-summary-list__value,
.asc-warning-banner .govuk-summary-list__actions {
  padding-top: 15px;
  padding-bottom: 15px;
  vertical-align: middle;
  background: none;
  border-bottom: none;
}

.spacer {
  height: 15px;
}
.spacer div {
  border-bottom: 1px solid #b1b4b6;
  height: 100%;
  display: table-cell;
}

.govuk-table__row .govuk-table__cell:first-of-type, .govuk-table__row .asc-cms-content table td:first-of-type, .asc-cms-content table .govuk-table__row td:first-of-type, .asc-cms-content table tr .govuk-table__cell:first-of-type, .asc-cms-content table tr td:first-of-type {
  padding-left: 0;
}
.govuk-table__row .govuk-table__cell:last-of-type, .govuk-table__row .asc-cms-content table td:last-of-type, .asc-cms-content table .govuk-table__row td:last-of-type, .asc-cms-content table tr .govuk-table__cell:last-of-type, .asc-cms-content table tr td:last-of-type {
  padding-right: 7.5px;
}
.govuk-table__row .asc-table__cell-no-border__top-row, .asc-cms-content table tr .asc-table__cell-no-border__top-row {
  border: none;
  padding-bottom: 0;
}
.govuk-table__row .asc-table__cell-no-border__middle-row, .asc-cms-content table tr .asc-table__cell-no-border__middle-row {
  border: none;
  padding: 0;
}
.govuk-table__row .asc-table__cell-no-border__bottom-row, .asc-cms-content table tr .asc-table__cell-no-border__bottom-row {
  padding-top: 0;
}

.govuk-table__with-action .govuk-table__row td:last-child, .govuk-table__with-action .asc-cms-content table tr td:last-child, .asc-cms-content table .govuk-table__with-action tr td:last-child,
.govuk-table__with-action .govuk-table__row th:last-child,
.govuk-table__with-action .asc-cms-content table tr th:last-child,
.asc-cms-content table .govuk-table__with-action tr th:last-child {
  width: 1%;
  white-space: nowrap;
}

.govuk-error-table__row {
  border: solid #d4351c;
}
.govuk-error-table__row--no-border {
  border: none;
}

.govuk-error-table__row--highlight {
  background: rgba(86, 148, 202, 0.2);
}

.govuk-error-table__cell {
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
}
.govuk-error-table__cell:first-of-type:before, .govuk-error-table__cell:first-of-type:after, .govuk-error-table__cell:last-of-type:before {
  content: "";
  position: absolute;
  height: 100%;
  padding-bottom: 8px;
}
.govuk-error-table__cell:first-of-type:before {
  width: 8px;
  background: #fff;
  left: 0;
}
.govuk-error-table__cell:first-of-type:after {
  background: #d4351c;
  left: 8px;
}

.govuk-error-table__message-cell {
  padding-top: 22.5px;
  padding-bottom: 0;
}

.govuk-error-table__message {
  margin-bottom: 0;
  padding-bottom: 15px;
  padding-left: 8px;
  position: relative;
}
.govuk-error-table__message:before {
  content: "";
  position: absolute;
  background: #d4351c;
  height: 100%;
}

.govuk-table-custom-width {
  width: 20% !important;
}

.asc-training-and-quals-table td.govuk-table__cell:has(button), .asc-training-and-quals-table .asc-cms-content table td:has(button), .asc-cms-content table .asc-training-and-quals-table td:has(button) {
  padding-top: 6px;
  padding-bottom: 6px;
}

.js-enabled .govuk-tabs__panel:not(.has-border) {
  padding-right: 0;
  padding-left: 0;
  border: 0;
}

.govuk-tabs__list-item .govuk-tabs__tab--generic-alert, .govuk-tabs__list-item .govuk-tabs__tab--green-tick, .govuk-tabs__list-item .govuk-tabs__tab--red-cross, .govuk-tabs__list-item .govuk-tabs__tab--red-alert, .govuk-tabs__list-item .govuk-tabs__tab--alert, .govuk-subsidiary-tabs__list-item .govuk-tabs__tab--generic-alert, .govuk-subsidiary-tabs__list-item .govuk-tabs__tab--green-tick, .govuk-subsidiary-tabs__list-item .govuk-tabs__tab--red-cross, .govuk-subsidiary-tabs__list-item .govuk-tabs__tab--red-alert, .govuk-subsidiary-tabs__list-item .govuk-tabs__tab--alert, .govuk-standalone-tabs__list-item .govuk-tabs__tab--generic-alert, .govuk-standalone-tabs__list-item .govuk-tabs__tab--green-tick, .govuk-standalone-tabs__list-item .govuk-tabs__tab--red-cross, .govuk-standalone-tabs__list-item .govuk-tabs__tab--red-alert, .govuk-standalone-tabs__list-item .govuk-tabs__tab--alert {
  background-repeat: no-repeat;
  background-position: 100%;
  background-size: 22px;
  padding-left: 30px;
  padding-top: 10px;
}

.govuk-standalone-tabs__list-item {
  padding: 12px 31px !important;
}

.govuk-subsidiary-tabs__list-item {
  padding: 12px 13px !important;
}

.govuk-tabs__tab--alert {
  background-image: url("/assets/images/flag-orange.svg");
}

.govuk-tabs__tab--red-alert {
  background-image: url("/assets/images/flag-red.svg");
}

.govuk-tabs__tab--red-cross {
  background-image: url("/assets/images/cross-icon.svg");
}

.govuk-tabs__tab--green-tick {
  background-image: url("/assets/images/tick-icon.svg");
}

.asc-tag {
  text-transform: initial;
}

.notification-alert {
  background: rgba(29, 112, 184, 0.15);
  padding: 14px;
  display: block;
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.1428571429;
  text-decoration: none;
}
@media print {
  .notification-alert {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .notification-alert {
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.25;
  }
}
@media print {
  .notification-alert {
    font-size: 14pt;
    line-height: 1.2;
  }
}
.notification-alert:visited {
  color: #0b0c0c;
}
.notification-alert:active {
  background: rgba(29, 112, 184, 0.15);
}
.notification-alert__count {
  color: #ffffff;
  background: #d4351c;
  border-radius: 50%;
  padding: 3px;
  display: inline-block;
  min-width: 20px;
  text-align: center;
  margin-left: 8px;
  margin-right: 6px;
}

.notification-list-row {
  background: none;
}
.notification-list-row--unread {
  background: #f3f2f1;
  font-weight: bold;
}

.notification-list-item {
  display: flex;
  align-items: center;
}
.notification-list-item__icon {
  margin-top: 12px;
  width: 80%;
  height: 80%;
  display: inline-block;
}

.notification-list-item__link {
  color: #1d70b8;
  margin-top: 6px;
}
.notification-list-item__link:visited {
  margin-top: 5px;
  color: #1d70b8;
}

.notification-tag {
  font-weight: normal;
  padding: 5px 10px;
  display: inline-block;
  min-width: 100px;
  text-align: center;
}
.notification-tag--view-only {
  background: #28a197;
}
.notification-tag--edit {
  background: #ffdd00;
}

.govuk-drag-and-drop {
  border: none !important;
  border-radius: 0px;
  outline: 3px dashed #0b0c0c;
  padding: 0px 40px;
  transition: outline-offset 0.1s ease-in-out, background-color 0.1s linear;
}

.ngx-dz-hovered {
  background-color: #b1b4b6;
  outline-color: #6f777b;
}

.govuk-drag-and-drop span {
  margin-bottom: 0px !important;
}

.govuk-drag-and-drop button {
  margin-bottom: 0px !important;
}